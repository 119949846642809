<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { IconColors, UserLevel } from '@/types';
import {
	CONGRATS,
	EARN_COINS_TO_REACH_LEVEL,
	GOTCHA,
	WE_CREDITED_YOU_COINS,
	YOU_REACHED_LEVEL,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeButton,
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import success_mock from '@/assets/success_mock.svg';

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const props = defineProps<{
	options: UserLevel;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};

const actual = ref(props?.options?.actual);
const next = ref(props?.options?.next);
const coins_earned = ref(props?.options?.coins_earned);
const coins_target = ref(props?.options?.coins_target);
const reward_earned = ref(props?.options?.reward_earned);

const nextLevelPointsTarget = computed(() => {
	return localizePoints(
		Number(coins_target.value) - Number(coins_earned.value)
	);
});

const { bonusDayBonus, rewardsDiscount } = storeToRefs(useUserStore());
</script>

<template>
	<ModalCommonContent id="congrats-level-modal">
		<template #header>{{ $t(CONGRATS) }}</template>
		<template #default>
			<div class="modal-content-container">
				<img :src="success_mock" alt="" />
				<div class="congrats-level">
					<PrimeText tag="div" size="lg" color="inherit" weight="500">
						<span v-html="`${$t(YOU_REACHED_LEVEL, { value: actual })}`" />
					</PrimeText>
					<PrimeText
						tag="div"
						class="congrats-level-description"
						size="sm"
						color="grey-700"
						weight="400"
					>
						{{
							$t(WE_CREDITED_YOU_COINS, {
								amount: localizePoints(reward_earned),
							})
						}}
					</PrimeText>
					<PrimeDivider top-gap="0.875rem" bottom-gap="0.875rem" />
					<PrimeText
						tag="div"
						class="congrats-level-description"
						size="sm"
						color="grey-700"
						weight="400"
					>
						{{
							$t(EARN_COINS_TO_REACH_LEVEL, {
								amount: nextLevelPointsTarget,
								value: next,
							})
						}}
					</PrimeText>
				</div>
			</div>
		</template>
		<template #actions>
			<PrimeButton :label="$t(GOTCHA)" @click="handleCloseModal" />
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
.modal-content-container {
	width: 100%;
	text-align: left;
	display: flex;
	flex-direction: column;

	img {
		width: 12.5rem;
		height: 12.5rem;
		margin: 0 auto;
	}

	.congrats-level {
		margin: 2.375rem 0;

		&-description {
			margin-top: 0.875rem;
		}
	}
}
</style>
