<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import type { RouteLocationRaw } from 'vue-router';

withDefaults(
	defineProps<{
		title: string;
		backButtonVisible?: boolean;
		backRoute?: RouteLocationRaw;
	}>(),
	{
		backButtonVisible: true,
		backRoute: 'app-login',
	}
);
</script>

<template>
	<div class="content-wrapper">
		<LayoutSubpage
			:back-button-visible="backButtonVisible"
			:title="title"
			:back-route="backRoute"
			full-width
		>
			<div class="auth-page-content" :class="[$attrs.class]">
				<slot />
			</div>
		</LayoutSubpage>
	</div>
</template>

<style lang="scss" scoped>
.content-wrapper {
	padding-bottom: env(safe-area-inset-bottom);
	display: flex;
	width: 100%;
}

.auth-page-content {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	width: 100%;
	max-width: 26.875rem;
	margin: 0 auto;
	text-align: center;
}
</style>
