import { useAuthStore } from '@/stores/auth';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { getRootDomain } from '@/utils/helpers';
import { UTILITY_ROUTE_NAME } from '@/constants/routes';
import { useAppStore } from '@/stores/app';

export const utilityRoutes = [
	{
		path: '/logout',
		name: UTILITY_ROUTE_NAME.LOGOUT,
		redirect: () => {
			const authStore = useAuthStore();
			authStore
				.removeToken()
				.then(() => {
					const appStore = useAppStore();
					return appStore.resetTracking();
				})
				.then(() => {
					authStore.setEmail('');
					//! TODO Should user be redirected to homepage or new app-login?
					const { isMobileApp } = useMobileApp();
					const path = isMobileApp ? '/' : getRootDomain(true);
					window.location.assign(path);
				});
			return '';
		},
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: () => 'surveys',
	},
];
