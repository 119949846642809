<script setup lang="ts">
import { FULL_NAME, REVOLUT_CLAIM_DATA, TAG } from '@/locales/constants';
import { PrimeInput, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';
import { ref, watch } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import ConfirmWrapper from '@/views/confirm-claim/components/ConfirmWrapper.vue';
import { ClaimErrorStatuses } from '@/enums';
import { PiWarningCircle } from '@primeinsightsgroupllc/prime-icons';

defineProps<{
	isClaimAvailable: boolean;
}>();

export type CheckRevolut = { revolut_full_name: string; revolut_tag: string };

const userStore = useUserStore();
const { revolutFullName, revolutTag } = storeToRefs(userStore);

const rewardsStore = useRewardsStore();

const isLoading = ref(false);
const serverValidationError = ref('');

const form = ref<CheckRevolut>({
	revolut_full_name: revolutFullName.value,
	revolut_tag: revolutTag.value,
} as CheckRevolut);

const { fullName, tag } = useValidationRules();
const validation = useVuelidate(
	{
		revolut_full_name: fullName,
		revolut_tag: tag,
	},
	form,
	{
		$autoDirty: false,
	}
);

const {
	validate,
	isValidationError,
	getValidationFieldStatus,
	getValidationFieldText,
	reset,
} = useValidationUtils<CheckRevolut>(validation);

const handleConfirmClaim = async () => {
	reset();
	await validate();
	if (isValidationError.value) return;

	try {
		isLoading.value = true;

		const { message, status } = await rewardsStore.claim(form.value);

		if (status === ClaimErrorStatuses.NOT_VALID_DATA_USED) {
			serverValidationError.value = message;
		}
	} finally {
		isLoading.value = false;
	}
};

watch(
	form,
	() => {
		reset();
		serverValidationError.value = '';
	},
	{ deep: true }
);
</script>

<template>
	<ConfirmWrapper
		:disabled="
			!form.revolut_tag.length ||
			!form.revolut_full_name.length ||
			isValidationError
		"
		:loading="isLoading"
		@confirm="handleConfirmClaim"
	>
		<template #claim-data>
			<PrimeText
				class="revolut-claim-data"
				weight="500"
				size="sm"
				v-html="$t(REVOLUT_CLAIM_DATA)"
			/>
			<PrimeInput
				v-model="form.revolut_full_name"
				:placeholder="`${$t(FULL_NAME)}...`"
				:invalid="!!getValidationFieldStatus('revolut_full_name')"
				:error-text="getValidationFieldText('revolut_full_name')?.toString()"
				type="text"
			/>

			<PrimeInput
				v-model="form.revolut_tag"
				:placeholder="`${$t(TAG)}...`"
				:invalid="!!getValidationFieldStatus('revolut_tag')"
				:error-text="getValidationFieldText('revolut_tag')?.toString()"
				type="text"
			/>
			<div v-if="serverValidationError" class="revolut-claim-error">
				<PiWarningCircle size="1.25rem" color="states-critical" />
				<PrimeText color="critical" size="xs" weight="500" tag="span">
					{{ serverValidationError }}
				</PrimeText>
			</div>
		</template>
	</ConfirmWrapper>
</template>

<style lang="scss">
.revolut-claim-data {
	& span.bold {
		font-weight: 700;
	}
}

.revolut-claim-error {
	display: flex;
	align-items: center;
	margin-top: 0.25rem;

	& svg {
		margin-right: 0.25rem;
	}
}
</style>
