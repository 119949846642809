<script setup lang="ts">
import {transformCurrency } from '@/utils/helpers';
import RewardImage from '@/components/common/RewardImage.vue';
import {PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

const { userSelectedReward } = storeToRefs(useUserStore());
</script>

<template>
	<div class="reward">
		<RewardImage
			:src="userSelectedReward?.image_url || ''"
			:alt="userSelectedReward?.name || 'logo'"
		/>
		<PrimeText tag="div" weight="500" size="2xl" class="money-value" color="inherit">
			{{
				transformCurrency(
					userSelectedReward?.money_value,
					userSelectedReward?.currency
				)
			}}
		</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.reward {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-bottom: 0.75rem;
	color: white;

	.reward-icon {
		width: 4.75rem;
		height: 3rem;
	}

	.coins-value {
		margin-left: auto;
	}

	.money-value {
		margin-top: 0.5rem;
	}
}
</style>
