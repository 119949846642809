<script setup lang="ts">
import {PrimeProgressbar, PrimeText} from "@primeinsightsgroupllc-ui/prime-ui-kit";
import {ref} from "vue";
import OverlayPanel from "primevue/overlaypanel";
import {LEVEL, LVL, POINTS, REWARD} from "@/locales/constants";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/stores/user";
import color_icon_mock from "@/assets/color_icon_mock.svg";
import {localizePoints} from "@/utils/helpers";
const { progress } = defineProps<{
	progress: number;
}>();

const showOverlay = ref(false);
const userLevelInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);
const { levelNext, levelActual, coinsEarned, coinsTarget } = storeToRefs(useUserStore());

const showUserLevelInfo = (event) => {
	userLevelInfo.value?.show(event);
};

const hideUserLevelInfo = () => {
	userLevelInfo.value?.hide();
};
</script>

<template>
	<div class="app-header-progress" @mouseover="showUserLevelInfo" @mouseleave="hideUserLevelInfo">
		<div class="app-header-stats">
			<div class="app-header-level">
				<img :src="color_icon_mock" alt="">
					<PrimeText size="base" weight="400" color="white">
						{{ levelActual }}
					</PrimeText>
					<PrimeText size="sm" weight="400">
						&nbsp;{{ $t(LEVEL) }}
					</PrimeText>
			</div>
			<PrimeText size="sm" weight="400">
				{{ localizePoints(coinsEarned) }}/{{ localizePoints(coinsTarget) }} {{ $t(POINTS) }}
			</PrimeText>
		</div>
		<div class="progress-bar-container">
			<PrimeProgressbar :value="progress"/>

			<OverlayPanel
				id="user-level-info"
				ref="userLevelInfo"
				append-to=".progress-bar-container"
				@show="showOverlay = true"
				@hide="showOverlay = false"
			>
				<PrimeText weight="400" size="xs" transform="capitalize">{{ $t(LVL) }} {{ levelNext }} {{ $t(REWARD) }}</PrimeText>
				<slot></slot>
			</OverlayPanel>
		</div>
	</div>

</template>

<style lang="scss">
.app-header {
	&-stats {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.625rem;
		cursor: pointer;
	}

	&-level {
		display: flex;
		align-items: center;
		img {
			margin-right: 0.625rem;
		}
	}

	&-progress {
		width: 18.75rem;

		.p-progress {
			border-radius: 3.125rem;
			background: var(--p-grey-300);
		}

		.p-progress-bar {
			border-radius: 3.125rem;
			background: linear-gradient(270deg, #FF6B34 0%, rgba(255, 107, 52, 0.00) 100%) !important;
		}
	}
}

.progress-bar-container {
	position: relative;
	cursor: pointer;
	width: 18.75rem;

	.p-progress {
		border-radius: 3.125rem;
		background: var(--p-grey-300) !important;
	}

	.p-progress-bar {
		border-radius: 3.125rem;
		background: linear-gradient(270deg, #FF6B34 0%, rgba(255, 107, 52, 0.00) 100%) !important;
	}
}

#user-level-info {
	color: white;
	left: 50% !important;
	top: 0.25rem !important;
	transform: translateX(-50%) !important;
	//--overlayArrowLeft: 4.6875rem !important;
	.p-overlaypanel-content {
		width: 11.875rem;
		padding: 0.75rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 0.875rem;
	}
}
</style>
