<script setup lang="ts">
import {
	OKAY,
	WELL_DONE_V2,
	OFFERWALLS_WELL_DONE_MODAL_TITLE,
	OFFERWALLS_WELL_DONE_MODAL_TEASER,
} from '@/locales/constants';
import {PrimeButton, PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import offers_well_done from '@/assets/offers_well_done.svg';
import { localizePoints } from '@/utils/helpers';
import type { OfferwallTransactionDelayed } from '@/types';

defineProps<{
	data: OfferwallTransactionDelayed;
}>();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<div id="offers-well-done">
		<ModalCommonContent>
			<template #header>{{ $t(WELL_DONE_V2) }}</template>
			<template #icon>
				<img :src="offers_well_done" alt="congratulations" />
			</template>
			<template #title>
				<PrimeText size="lg" color="inherit">
					{{
						$t(OFFERWALLS_WELL_DONE_MODAL_TITLE, {
							name: data.offer_name,
							points: localizePoints(data.coin_value),
						})
					}}
				</PrimeText>
			</template>
			<template #message>
				<PrimeText size="sm" color="grey-700">
					{{
						$t(OFFERWALLS_WELL_DONE_MODAL_TEASER, { days: data.credit_delay })
					}}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					type="fill"
					full-width
					rounded="rounded"
					:label="$t(OKAY)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#offers-well-done {
	:deep(.modal-common-content) {
		.content-icon {
			display: flex;
			width: 100%;
			height:auto;
			margin-bottom: 0.75rem;
			justify-content: center;

			img {
				width: 160px;
				height: 160px;
			}
		}

		.content-title {
			text-align: left;
			max-width: unset;
			width: 100%;
			color: white;
		}

		.content-message {
			max-width: unset;
			width: 100%;
			text-align: left;
			border-bottom: none;
		}
	}
}
</style>
