<script lang="ts" setup>
import { type Component, ref } from 'vue';
import type { IconColors, Modal } from '@/types';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineEmits<{
	(e: 'click'): void;
}>();

const props = withDefaults(
	defineProps<{
		startIconColor?: IconColors;
		endIconColor?: IconColors;
		textColor?: string;
		startIcon?: Component | null;
		endIcon?: Component | null;
		title: string;
		modal?: Modal;
		clickHandler?: (() => void) | null;
	}>(),
	{
		textColor: 'text-default',
		startIconColor: 'base-primary',
		endIconColor: 'text-default',
		modal: undefined,
		clickHandler: null,
		startIcon: null,
		endIcon: null,
	}
);

const textColorVar = ref(`var(--${props.textColor})`);
const { pushModal } = useModalStorage();

const showModal = () => {
	if (props?.modal) {
		pushModal(props.modal);
	}
};

const onClick = props.clickHandler || showModal;
</script>

<template>
	<div class="settings-item-wrapper" @click.stop="onClick">
		<component :is="startIcon" v-if="startIcon" color="inherit"/>
		<PrimeText tag="p" size="sm" weight="500" class="item-title">
			{{ title }}
		</PrimeText>
		<component :is="endIcon" v-if="endIcon" size="1.5rem" color="inherit"/>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.settings-item-wrapper {
	cursor: pointer;
	display: flex;
	padding: 0.625rem 0.5rem;
	margin-bottom: 0.875rem;
	background-color: var(--white);
	box-shadow: var(--card-shadow);
	border-radius: 0.5rem;
	align-items: center;
	transition: all 0.1s;
	color: var(--p-text-default);
	border: 1px solid var(--p-grey-300);

	&:hover {
		background-color: var(--base-primary-03);
	}
}

.item-title {
	color: white !important;
	margin-left: 0.5rem;
	flex: 1 1 auto;
}
</style>
