<script setup lang="ts">
import { useRoute } from 'vue-router';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import { onBeforeMount, ref } from 'vue';
import type { OfferwallPartnerContent } from '@/types';
import { getOfferWallById } from '@/api';

const route = useRoute();

const partner = ref<OfferwallPartnerContent | null>(null);
onBeforeMount(async () => {
	partner.value = await getOfferWallById(route.params.partnerId as string);
});
</script>

<template>
	<main class="offerwall-partner__content">
		<LayoutSubpage :title="partner?.name" back-route="offerwalls-partners">
			<div class="iframe-wrapper">
				<iframe :src="partner?.iframe" :title="`${partner?.name} iframe`" />
			</div>
		</LayoutSubpage>
	</main>
</template>

<style scoped lang="scss">
.offerwall-partner__content {
	display: flex;
	flex: 1;

	& .subpage {
		display: flex;
		flex-direction: column;
	}
}

.iframe-wrapper {
	flex: 1;
	display: flex;
	height: 100%;
	overflow: scroll;

	& iframe {
		width: 100%;
	}
}
</style>
