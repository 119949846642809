import API from '@/api';
import type { BonusItem, MessageResponse } from '@/types';

export const getWelcomeBonuses = async (): Promise<BonusItem[]> => {
	const { data, status } = await API.get<BonusItem[]>('/api/bonus');
	return status === 200 ? data : [];
};

export const selectWelcomeBonus = async (
	bonusValue: number
): Promise<MessageResponse> => {
	const { data } = await API.post<MessageResponse>('/api/bonus', {
		coin_value: bonusValue,
	});
	return data;
};
