<script setup lang="ts">
import {
	REF_HOW_IT_WORKS,
	REF_HIW_STEP_1_TITLE,
	REF_HIW_STEP_2_TITLE,
	REF_HIW_STEP_3_TITLE,
	REF_HIW_STEP_2,
	REF_HIW_STEP_1,
	REF_HIW_STEP_3,
} from '@/locales/constants';
import { PiInfo } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const steps = [
	{
		title: REF_HIW_STEP_1_TITLE,
		description: REF_HIW_STEP_1,
		className: 'referrals-hiw-step--count--green'
	},
	{
		title: REF_HIW_STEP_2_TITLE,
		description: REF_HIW_STEP_2,
		className: 'referrals-hiw-step--count--yellow'
	},
	{
		title: REF_HIW_STEP_3_TITLE,
		description: REF_HIW_STEP_3,
		className: 'referrals-hiw-step--count--blue'
	},
];

const { isMobile } = useScreenSize();
</script>

<template>
	<section class="referrals-hiw">
		<PrimeText size="lg" weight="500" color="inherit">{{ $t(REF_HOW_IT_WORKS) }}</PrimeText>
		<div class="referrals-hiw--steps">
			<div v-for="(step, index) in steps" :key="index" class="referrals-hiw-step--wrapper">
				<div class="referrals-hiw-step">
					<PrimeText
						size="lg"
						color="inherit"
						class="referrals-hiw-step--count"
						:class="step.className"
					>
						{{ index + 1 }}
					</PrimeText>
				</div>
				<div class="referrals-hiw-step--content">
					<PrimeText
						tag="p"
						weight="500"
						size="sm"
						color="inherit"
						class="referrals-hiw-step--title"
					>
						{{ $t(step.title) }}
					</PrimeText>
					<PrimeText tag="p" :size="isMobile ? 'xs' : 'sm'" color="grey-700">
						{{ $t(step.description) }}
					</PrimeText>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals-hiw {
	color: white;
	display: flex;
	flex-direction: column;
	gap: 1.125rem;

	&--steps {
		display: flex;
		flex-direction: column;
		gap: 1.375rem;
	}

	&-step {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #000;

		&:last-child {
			margin-bottom: 0;
		}

		&--wrapper{
			display: flex;
			gap: 0.75rem;

			&:last-child {
				& .referrals-hiw-steps--connection{
					display: none;
				}
			}
		}

		&--count {
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 2.75rem;
			min-width: 2.75rem;
			height: 2.75rem;
			width: 2.75rem;
			border-radius: 50%;

			&--green {
				background-color: #B9FF66;
			}

			&--yellow {
				background-color: #FFE236;
			}

			&--blue {
				background-color: #806DFF;
			}
		}

		&--content {
			color: white;
		}

		&--title.p-text {
			line-height: 1;
			margin-bottom: 0.25rem;
		}
	}
}
</style>
