<script setup lang="ts">
import RewardImage from '@/components/common/RewardImage.vue';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiPercent } from '@primeinsightsgroupllc/prime-icons';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { MIN_COINS_VALUE } from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import type { RewardsItem } from '@/types';

const {
	item,
	name = true,
	discount = true,
} = defineProps<{
	item: RewardsItem;
	name?: boolean;
	discount?: boolean;
}>();

const i18n = useI18n();

const minCoinsText = computed(() => {
	return i18n.t(MIN_COINS_VALUE, {
		value: localizePoints(item.min_coin_value),
	});
});
</script>

<template>
	<div class="rewards-item__header">
		<RewardImage :src="item.image_url" :alt="item.name" />
		<PrimeText v-if="name" size="xs" class="rewards-item__name" weight="500">
			{{ item.name }}
		</PrimeText>
		<div
			v-if="item.has_discounted_options && discount"
			class="rewards-item__discount"
		>
			<PiPercent color="base-secondary" size="1.25rem" />
		</div>
		<PrimeText size="xs" color="grey-700" class="rewards-item__min-coins">
			{{ minCoinsText }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.rewards-item__header {
	display: flex;
	align-items: center;
	flex: 1 1 100%;

	& .rewards-item__name {
		max-width: 5.625rem;
		margin-left: 0.375rem;

		@include breakpoint(mobileonly) {
			max-width: none;
			margin-left: 0.5rem;
		}
	}
}

.rewards-item__discount {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.125rem 0.25rem;
	background-color: var(--base-secondary-04);
	border-radius: 3.125rem;
	margin: 0 0.5rem;
}

.rewards-item__min-coins {
	flex: 0 1 auto;
	margin: 0 0 0 auto;
	text-align: end;

	@include breakpoint(mobileonly) {
		flex: 0 0 auto;
	}
}
</style>
