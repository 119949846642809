import type { Component, ComputedRef } from 'vue';
import { computed } from 'vue';
import { useClaimBaseInfo } from '@/views/confirm-claim/composables/useClaimBaseInfo';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { InternalRewards } from '@/enums';
import ConfirmClaimDefault from '@/views/confirm-claim/components/ConfirmClaimDefault.vue';
import ConfirmClaimDataPayPal from '@/views/confirm-claim/components/ConfirmClaimDataPayPal.vue';
import ConfirmClaimDataRevolut from '@/views/confirm-claim/components/ConfirmClaimDataRevolut.vue';
import ConfirmClaimDataAch from '@/views/confirm-claim/components/ConfirmClaimDataAch.vue';

const confirmClaimComponents: Record<string, Component> = {
	[InternalRewards.PAYPAL]: ConfirmClaimDataPayPal,
	[InternalRewards.REVOLUT]: ConfirmClaimDataRevolut,
	[InternalRewards.ACH]: ConfirmClaimDataAch,
	default: ConfirmClaimDefault,
};

export const useConfirmClaimView = (): {
	restOfTheBalanceText: ComputedRef<string>;
	isClaimAvailable: ComputedRef<boolean>;
	component: ComputedRef<Component>;
} => {
	const { isClaimAvailable, restOfTheBalanceText } = useClaimBaseInfo();

	const { userSelectedReward } = storeToRefs(useUserStore());

	const component = computed(() => {
		if (userSelectedReward.value) {
			return (
				confirmClaimComponents[userSelectedReward.value.reward_external_id] ||
				confirmClaimComponents['default']
			);
		} else {
			return confirmClaimComponents['default'];
		}
	});

	return {
		restOfTheBalanceText,
		isClaimAvailable,
		component,
	};
};
