<script setup lang="ts">
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import {
	ACCOUNT_EXISTS_TITLE,
	NOT_YOUR,
	SOCIAL_ACCOUNT_MESSAGE,
	USE_ANOTHER_ACCOUNT,
} from '@/locales/constants';
import SocialButton from '@/components/auth/SocialButton.vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useSocialsView } from '@/views/socials/composables';
import { PiEnvelopeDuotone } from '@primeinsightsgroupllc/prime-icons';
import {PrimeButton, PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleLoadingSocials,
	handleAnotherAccount,
	socialAuth,
	usersSocials,
	isLoading,
	formattedEmail,
} = useSocialsView();
</script>

<template>
	<AuthPageContent :title="$t(ACCOUNT_EXISTS_TITLE)">
		<div class="social-account-wrapper">
			<PrimeText
				tag="div"
				weight="400"
				size="sm"
				class="social-account-message"
				v-html="$t(SOCIAL_ACCOUNT_MESSAGE)"
			/>
			<div class="social-account-email">
				<PiEnvelopeDuotone size="2rem" color="inherit"/>
				<PrimeText weight="400" size="base" color="inherit">{{ formattedEmail }}</PrimeText>
			</div>
			<SocialButton
				:provider="usersSocials[0]"
				@loading="handleLoadingSocials($event)"
				@auth="socialAuth($event)"
			/>
		</div>

		<div class="social-account-change">
			<PrimeText size="xs">{{ $t(NOT_YOUR) }}</PrimeText>
			<PrimeButton
				type="link"
				variant="grey"
				size="small"
				:label="$t(USE_ANOTHER_ACCOUNT)"
				@click="handleAnotherAccount"
			/>
		</div>
		<PrimeLoader v-if="isLoading" full-page />
	</AuthPageContent>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.social-account-wrapper {
	padding: 1.25rem 0.625rem;
	border-radius: 1.5rem;
	background: var(--white);
	margin-bottom: 1rem;
	border: 1px solid rgba(255, 255, 255, 0.07);
	background-color: var(--base-primary-03);
}

.social-account-message {
	margin-bottom: 0.75rem;
}

.social-account-email {
	padding: 0.75rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.25rem;
	color: white;
}

.social-account-change {
	margin-top: 0.75rem;

	& button {
		cursor: pointer;
		border: none;
		background: transparent;

		@include breakpoint(mobile) {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				color: var(--element-black);
			}
		}
	}
}
</style>
