<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import { usePrimeSurveysIntegration } from '@/utils/composables/useIntegrationScriptRender';
import { ACTIVITIES, HELP } from '@/locales/constants';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { IntegrationModules } from '@/types';

const route = useRoute();
const i18n = useI18n();
const { renderPrimeSurveys, integrationScriptLoading } =
	usePrimeSurveysIntegration();

const pageTitle = computed(() => {
	const titles: Record<string, string> = {
		'recent-activity': ACTIVITIES,
		'recent-activity-offers': ACTIVITIES,
		support: HELP,
	};
	return titles[String(route.name)] || '';
});

onMounted(() =>
	renderPrimeSurveys(
		'#prime-integrated-page',
		route.name as IntegrationModules,
		i18n.t(pageTitle.value)
	)
);
</script>

<template>
	<main class="integrated-page">
		<LayoutSubpage>
			<PrimeLoaderSpinner v-if="integrationScriptLoading" />
			<div id="prime-integrated-page" class="integrated-container" />
		</LayoutSubpage>
	</main>
</template>

<style scoped lang="scss">
.integrated-page {
	width: 100%;

	:deep(.p-loader-spinner) {
		height: 100%;
	}

	:deep(.subpage) {
		overflow: hidden;
		padding: 0;
	}
}

.integrated-container {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 4.5rem;
}
</style>
