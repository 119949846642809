<script setup lang="ts">
import { localizePoints } from '@/utils/helpers';
import type { LeaderboardUser } from '@/types';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const { user } = defineProps<{
	user: LeaderboardUser;
}>();

const { isMobile } = useScreenSize();
</script>

<template>
	<div class="leaderboard-table__user-row">
		<div class="username-score">
			<template v-if="user.username">
				<div class="username">
					<PrimeText size="base" weight="500">{{ user.rank }}. </PrimeText>
					<PrimeText size="base" weight="500" color="inherit">{{ user.username }}</PrimeText>
				</div>
			</template>

			<div v-else class="username">
				<PrimeText size="base" weight="500">{{ user.rank }}. </PrimeText>
				<PrimeText size="base" weight="500" color="inherit">&mdash;</PrimeText>
			</div>
		</div>
		<div class="prize">
			<PrimeText tag="div" size="base" color="inherit" weight="400" class="score">
				{{ localizePoints(user.earned) }}
			</PrimeText>
		</div>
		<div class="prize">
			<PrimeText tag="span" color="inherit" weight="400" :size="isMobile ? 'sm' : 'base'">
				{{ localizePoints(user.prize) }}
			</PrimeText>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard-table__user-row {
	border-radius: 3.125rem;
	display: grid;
	grid-template-columns: 4fr 2fr 2fr;
	padding: 8px;
	&:nth-child(even) {
		border: 1px solid var(--p-grey-300);
		background: var(--p-grey-500);
	}

	@include breakpoint(tablet) {
		grid-template-columns: 4fr 2fr 2fr 2fr;
	}

	.prize {
		text-align: center;
	}
}
</style>
