<script setup lang="ts">
import {
	REWARD_SENT,
	REWARD_RECEIVE_TIME,
	REWARD_IS_ON_WAY, GOTCHA,
} from '@/locales/constants';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import money_box_logo from '@/assets/money_box_mock.svg'

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<ModalCommonContent id="reward-sent-modal">
		<template #header>{{ $t(REWARD_IS_ON_WAY) }}</template>
		<template #icon>
			<img :src="money_box_logo" alt="">
		</template>
		<template #title>{{ $t(REWARD_SENT) }}</template>
		<template #message>{{ $t(REWARD_RECEIVE_TIME) }}</template>
		<template #actions>
			<PrimeButton
				full-width
				:label="$t(GOTCHA)"
				@click="handleCloseModal"
			/>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
#reward-sent-modal {
	text-align: left;
	align-items: flex-start;

	.content-icon {
		width: 180px;
		height: 180px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1.125rem;
	}

	.content-actions {
		margin-top: 3.5rem;

		.content-actions-button {
			margin-top: 1rem;
		}
	}
}
</style>
