export enum ClaimStatuses {
	SUCCESS = 'success',
	FAILED = 'error',
	PROCESSING = 'processing',
	REFUND = 'refunded',
	USER_CONFIRMATION = 'user_confirmation',
}

export enum RewardCategories {
	TRANSFERS = 'transfers',
	GIFT_CARDS = 'gift_cards',
	PREPAID_CARDS = 'prepaid_cards',
	CHARITY = 'charity',
	PROMOTED = 'promoted',
}

export enum BonusLabels {
	FAST_PAYOUT = 'fast_payout',
	MOST_POPULAR = 'most_popular',
	BIGGEST_BONUS = 'biggest_bonus',
}

export enum LeaderboardTypes {
	UNLOCKED = 'unlocked',
	SCORED = 'scored',
	LOCKED = 'locked',
}

export enum Cookies {
	LOCALIZATION = 'localization',
	AUTH_TOKEN = 'auth-token',
	M_ID = 'm_id',
	AUTH_PROVIDER = 'auth-provider',
}

export enum SocialProviders {
	// FACEBOOK = 'facebook',
	GOOGLE = 'google',
	APPLE = 'apple',
}

export enum SignatureActions {
	DEFAULT = 'default',
	VERIFY_EMAIL = 'verify_email',
	ONE_TIME_TOKEN = 'one_time_token',
	ONE_CLICK_SURVEY = 'one_click_survey',
	CLAIM_USER_CONFIRMATION = 'claim_user_confirmation',
	EMAIL_TRANSFER = 'email_transfer',
	WEEKLY_SURVEY_INVITATION = 'weekly_survey_invitation',
}

export enum TierBenefits {
	LIVE_CHAT = 'live_chat',
	EARLY_ACCESS = 'early_access',
	BONUS_DAY = 'bonus_day',
	REWARD_DISCOUNT = 'reward_discount',
}

export enum TierKeys {
	BLUE = 'blue',
	BRONZE = 'bronze',
	SILVER = 'silver',
	GOLD = 'gold',
	DIAMOND = 'diamond',
}

export enum Platforms {
	WEB = 'web',
	WEB_MOBILE = 'web-mobile',
	IOS = 'ios',
	ANDROID = 'android',
}

export enum CapgoChannels {
	STAGING = 'staging',
	PRODUCTION = 'production',
}

export enum PostMessages {
	SURVEY_STARTED = 'ps-survey-started',
	SURVEY_COMPLETED = 'ps-survey-completed',
	SCROLL_BLOCK = 'ps-lock-scroll',
}

export enum TrackingEvents {
	SIGN_UP = 'sign_up',
	SURVEY_STARTED = 'survey_started',
	SURVEY_COMPLETED = 'survey_completed',
	WELCOME_BONUS_SELECTED = 'welcome_bonus_selected',
	SIGN_UP_CONFIRMED = 'sign_up_confirmed',
	ONE_CLICK_SURVEY_USED = 'one_click_survey_used',
	CLAIM_REQUESTED = 'claim_requested',
	APP_INIT = 'app_init',
}

export enum ClaimErrorStatuses {
	NOT_VALID_DATA_USED = 404,
	NEW_CLAIM_DATA_USED = 417,
	CONFIRM_CLAIM_VIA_EMAIL = 428,
}

export enum ProfileNavigationTypes {
	DEFAULT = 'default',
	OUTLINED = 'outlined',
}

export enum StreakPrizes {
	COINS = 0,
	BONUS = 1,
}

export enum InternalRewards {
	PAYPAL = 'PAYPAL_INTERNAL',
	REVOLUT = 'REVOLUT_INTERNAL',
	ACH = 'ACH_INTERNAL',
}

export enum PromotedRewards {
	REVOLUT = InternalRewards.REVOLUT,
}

export enum MessageChannels {
	NOTIFICATION = 'notification',
	ANNOUNCEMENT = 'announcement',
}

// All and Popular are static, other values should be equal to the subcategory id from DB
export enum RewardsSubCategories {
	ALL = '-1',
	POPULAR = '0',
	FOOD = '1',
	GAMING = '2',
	SHOPPING = '3',
}

export enum AnnouncementTypes {
	STATIC = 1,
	DYNAMIC = 2,
}

export enum FeatureFlags {
	OFFER_WALLS = 'offer_walls',
}

export enum OfferwallsStatuses {
	DISABLED = 0,
	ENABLED = 1,
	LOCKED = 2,
}

export enum Nav {
	DESKTOP = 'desktop',
	MOBILE = 'mobile',
}
