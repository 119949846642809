<script setup lang="ts">
import { ref } from 'vue';
import LeaderboardHeader from './components/LeaderboardHeader.vue';
import LeaderboardTable from './components/LeaderboardTable.vue';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PageCard from "@/components/PageCard.vue";

const loading = ref(false);
</script>

<template>
	<PrimeLoaderSpinner v-if="loading" />
		<main v-show="!loading" class="leaderboard">
			<PageCard>
				<LeaderboardHeader @loading="loading = $event" />
				<LeaderboardTable />
			</PageCard>
		</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard {
	width: 100%;
}
</style>
