import { USER_ROUTE_NAME } from '@/constants/routes';
import TransactionsView from '@/views/history/views/transactions/TransactionsView.vue';
import RewardsView from '@/views/history/views/rewards/RewardsView.vue';
import SurveysView from '@/views/surveys/SurveysView.vue';
import ProfileView from '@/views/profile/ProfileView.vue';
import SettingsView from '@/views/settings/SettingsView.vue';
import ConfirmClaimView from '@/views/confirm-claim/ConfirmClaimView.vue';
import LeaderboardView from '@/views/leaderboard/LeaderboardView.vue';
import PrizeDrawView from '@/views/prize-draw/PrizeDrawView.vue';
import VerifyEmailView from '@/views/verify-email/VerifyEmailView.vue';
import ReferralsView from '@/views/referrals/ReferralsView.vue';
import IntegrationSinglePageView from '@/views/IntegrationSinglePageView.vue';
import EmailSubscriptionsView from '@/views/email-subscriptions/EmailSubscriptionsView.vue';
import MoreView from '@/views/more/MoreView.vue';
import OfferwallsPartnersListView from '@/views/offerwalls/views/partners/OfferwallsPartnersListView.vue';
import OfferwallsPartnerView from '@/views/offerwalls/views/partner/OfferwallsPartnerView.vue';
import { checkContextIdHook, checkInitializedClaim } from '@/router/hooks';

export const userRoutes = [
	{
		path: '/surveys',
		name: USER_ROUTE_NAME.SURVEYS,
		component: SurveysView,
		beforeEnter: checkContextIdHook,
	},
	{
		path: '/profile',
		name: USER_ROUTE_NAME.PROFILE,
		component: ProfileView,
	},
	{
		path: '/settings',
		name: USER_ROUTE_NAME.SETTINGS,
		component: SettingsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/accept-email-transfer',
		name: USER_ROUTE_NAME.ACCEPT_EMAIL_TRANSFER,
		component: SettingsView,
		meta: {
			isPublic: true,
			withoutHeader: true,
			ignoreAuth: true,
		},
	},
	{
		path: '/reward-history/rewards',
		name: USER_ROUTE_NAME.REWARD_HISTORY,
		component: RewardsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/reward-history/transactions',
		name: USER_ROUTE_NAME.TRANSACTIONS_HISTORY,
		component: TransactionsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/leaderboard',
		name: USER_ROUTE_NAME.LEADERBOARD,
		component: LeaderboardView,
	},
	{
		path: '/confirm-claim',
		name: USER_ROUTE_NAME.CONFIRM_CLAIM,
		component: ConfirmClaimView,
		beforeEnter: checkInitializedClaim,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/prize-draw',
		name: USER_ROUTE_NAME.PRIZE_DRAW,
		component: PrizeDrawView,
	},
	{
		path: '/referrals',
		name: USER_ROUTE_NAME.REFERRALS,
		component: ReferralsView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/recent-activity',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY,
		component: IntegrationSinglePageView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/support',
		name: USER_ROUTE_NAME.SUPPORT,
		component: IntegrationSinglePageView,
		meta: {
			hideMobileBalance: true,
		},
	},
	{
		path: '/verify-email',
		name: USER_ROUTE_NAME.VERIFY_EMAIL,
		component: VerifyEmailView,
	},
	{
		path: '/more',
		name: USER_ROUTE_NAME.MORE,
		component: MoreView,
	},
	{
		path: '/email-subscriptions',
		name: USER_ROUTE_NAME.EMAIL_SUBSCRIPTIONS,
		component: EmailSubscriptionsView,
		meta: {
			ignoreAuth: true,
			hideMobileBalance: true,
		},
	},
	{
		path: '/offerwalls',
		name: USER_ROUTE_NAME.OFFERWALLS,
		children: [
			{
				path: 'partners',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
				component: OfferwallsPartnersListView,
			},
			{
				path: 'partner/:partnerId',
				name: USER_ROUTE_NAME.OFFERWALLS_PARTNER,
				component: OfferwallsPartnerView,
				meta: {
					hideMobileBalance: true,
				},
			},
		],
	},
	{
		path: '/recent-activity-offers',
		name: USER_ROUTE_NAME.RECENT_ACTIVITY_OFFERS,
		component: IntegrationSinglePageView,
		meta: {
			hideMobileBalance: true,
		},
	},
];
