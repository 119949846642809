<script setup lang="ts">
import { POINTS, DATE, BALANCE } from '@/locales/constants';
import { getTransactionContent } from '@/views/history/views/transactions/components/transactions';
import { localizePoints, toDate } from '@/utils/helpers';
import type { Transaction } from '@/types';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { transaction } = defineProps<{
	transaction: Transaction;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';

const { term, icon, color } = getTransactionContent(transaction.type);
</script>

<template>
	<div class="transaction-item">
		<div class="transaction-item-row">
			<div class="transaction-coins">
				<PrimeText
					color="grey-700"
					tag="div"
					weight="400"
					size="xs"
					class="transaction-coins--header"
				>
					{{ $t(POINTS) }}
				</PrimeText>
				<PrimeText
					tag="div"
					size="sm"
					weight="600"
					class="transaction-coins--value"
					:color="transaction.coins < 0 ? 'secondary' : 'primary'"
				>
					{{ localizePoints(transaction.coins) }}
				</PrimeText>
			</div>
			<div class="transaction-type" :style="`color: ${color}`">
				<PrimeText size="xs" weight="500" color="inherit">{{ $t(term) }}</PrimeText>
				<component :is="icon" size="1.25rem" color="inherit" />
			</div>
		</div>


		<div class="transaction-item-row">
			<div class="transaction-balance">
				<PrimeText
					tag="div"
					size="xs"
					weight="400"
					color="grey-700"
					class="transaction-balance--header"
				>
					{{ $t(BALANCE) }}
				</PrimeText>
				<PrimeText
					tag="div"
					size="sm"
					weight="400"
					color="inherit"
					class="transaction-balance--value"
				>
					{{ localizePoints(transaction.balance) }}
				</PrimeText>
			</div>
			<div class="transaction-date">
				<PrimeText
					tag="div"
					size="xs"
					weight="400"
					color="grey-700"
					class="transaction-date--header"
				>
					{{ $t(DATE) }}
				</PrimeText>
				<PrimeText
					tag="div"
					size="sm"
					weight="400"
					color="inherit"
					class="transaction-date--value"
				>
					{{ toDate(transaction.date, localization) }}
				</PrimeText>
		</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.transaction-item {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
	padding: 0.75rem;
	border: 1px solid var(--p-grey-300);
	margin-bottom: 0.75rem;
	border-radius: 0.75rem;
	background: var(--p-grey-500);
	box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);

	&-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: white;
	}

	.transaction-coins,
	.transaction-balance,
	.transaction-date,
	.transaction-type {
		display: flex;
		align-items: center;
		gap: 0.25rem;
	}

	.transaction-balance,
	.transaction-date {
		margin-top: 0.625rem;
	}

	.transaction-date {
		justify-content: flex-end;
	}

	.transaction-type {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		text-align: end;
		border-radius: 50px;
		background: var(--grey-neutral-100-labels-chips, #3D3E40);
		padding: 0.25rem 0.5rem;
		gap: 0.25rem;
	}
}
</style>
