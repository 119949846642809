import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import API from '@/api';
import { deviceStorage } from '@/utils/local-storage';

export const sendErrorInfo = (
	error: any,
	componentName: string = '',
	otherData: any = null
): void => {
	if (import.meta.env.VITE_SEND_ERRORS !== 'true') return;

	const authStore = useAuthStore();

	let isAllowSending = true;
	const { isAuthorized } = storeToRefs(authStore);
	const productionMode = import.meta.env.MODE === 'production';
	let info = error?.stack || '';

	if (error?.name === 'AxiosError') {
		const ignoreErrorCodes: number[] = [401, 403, 409, 417, 422, 429, 500];
		isAllowSending = !ignoreErrorCodes.includes(error?.request?.status);

		info = `AxiosError. Endpoint: ${error?.config?.url}.`;

		if (componentName) {
			info += ` ::: Component ::: ${componentName}.`;
		}
	}

	if (isAllowSending && isAuthorized.value && productionMode) {
		try {
			API.post(
				'errors/js',
				{
					message: `PATH ::: ${window.location.href} ::: MESSAGE ::: ${
						error.message
					} ::: COMPONENT ::: ${componentName} ::: ADDITIONAL INFO ::: ${JSON.stringify(
						info
					)} ::: DEVICE ::: ${
						deviceStorage.value
					} ::: OTHER DATA ::: ${JSON.stringify(otherData)}`,
				},
				{ authRequired: false }
			).then();
		} catch (error: any) {
			console.error('Error when send error message: ', error.message);
		}
	}
};
