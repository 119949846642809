import { AUTH_ROUTE_NAME } from '@/constants/routes';
import SigninView from '@/views/signin/SigninView.vue';
import SignupView from '@/views/signup/SignupView.vue';
import ForgotPasswordView from '@/views/forgot-password/ForgotPasswordView.vue';
import SignupCompleteView from '@/views/signup-complete/SignupCompleteView.vue';
import ResetPasswordView from '@/views/reset-password/ResetPasswordView.vue';
import SocialExistView from '@/views/socials/SocialsView.vue';
import AuthSocialView from '@/views/auth-social/AuthSocialView.vue';
import SignupSocialView from '@/views/signup-social/SignupSocialView.vue';
import AppLoginView from '@/views/app-login/AppLoginView.vue';
import SignatureLoginView from '@/views/signature-login/SignatureLoginView.vue';
import {
	forgotPasswordHook,
	checkUserEmailHook,
	checkSignUpCompleteHook,
	checkExistingSocialsHook,
	checkSocialAuthHook,
	checkResetPasswordHook,
} from '@/router/hooks';

export const authRoutes = [
	{
		path: '/',
		name: AUTH_ROUTE_NAME.HOME,
		component: AppLoginView,
		meta: {
			isPublic: true,
		},
		children: [
			{
				path: '/app-login',
				name: AUTH_ROUTE_NAME.APP_LOGIN,
				component: AppLoginView,
				meta: {
					isPublic: true,
					hideMobileNav: true,
				},
			},
		],
	},
	{
		path: '/signin',
		name: AUTH_ROUTE_NAME.SIGN_IN,
		component: SigninView,
		beforeEnter: checkUserEmailHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		path: '/forgot-password',
		name: AUTH_ROUTE_NAME.FORGOT_PASSWORD,
		component: ForgotPasswordView,
		beforeEnter: forgotPasswordHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		path: '/signup',
		name: AUTH_ROUTE_NAME.SIGN_UP,
		component: SignupView,
		beforeEnter: checkUserEmailHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		path: '/signup-complete',
		name: AUTH_ROUTE_NAME.SIGN_UP_COMPLETE,
		component: SignupCompleteView,
		beforeEnter: checkSignUpCompleteHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		path: '/socials',
		name: AUTH_ROUTE_NAME.SOCIALS,
		component: SocialExistView,
		beforeEnter: checkExistingSocialsHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		// Route the same as we have on homepage app
		path: '/auth/social',
		name: AUTH_ROUTE_NAME.AUTH_SOCIAL,
		component: AuthSocialView,
		meta: {
			isPublic: true,
			withoutHeader: true,
			ignoreAuth: true,
		},
	},
	{
		path: '/signup-social',
		name: AUTH_ROUTE_NAME.SIGN_UP_SOCIAL,
		component: SignupSocialView,
		beforeEnter: checkSocialAuthHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
		},
	},
	{
		path: '/reset-password',
		name: AUTH_ROUTE_NAME.RESET_PASSWORD,
		component: ResetPasswordView,
		beforeEnter: checkResetPasswordHook,
		meta: {
			isPublic: true,
			withoutHeader: true,
			ignoreAuth: true,
			hideMobileNav: true,
		},
	},
	{
		path: '/signature-login',
		name: AUTH_ROUTE_NAME.SIGNATURE_LOGIN,
		component: SignatureLoginView,
		meta: {
			ignoreAuth: true,
		},
	},
];
