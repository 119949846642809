<script lang="ts" setup>
import {
	EMAIL,
	CONTINUE,
	START_SHARING,
	REGISTER_OR_SIGN_IN,
	OR_LABEL,
} from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import SocialButton from '@/components/auth/SocialButton.vue';
import { useAppLoginView } from '@/views/app-login/composables';
import {
	PrimeText,
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import logo_mock from '@/assets/logo_mock.svg';
import LanguageSwitcher from '@/views/app-login/components/LanguageSwitcher.vue';
import RewardsCarousel from '@/components/auth/RewardsCarousel.vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';

const {
	form,
	handleSubmitForm,
	getValidationFieldStatus,
	getValidationFieldText,
	isLoading,
	socialAuthButtons,
	handleSocialAuth,
	handleLoading,
} = useAppLoginView();

const { isMobile } = useScreenSize();
</script>

<template>
	<div class="login-page-wrapper">
		<div class="login-page">
			<div class="header-wrapper">
				<LanguageSwitcher />
				<img class="login-page-logo" :src="logo_mock" alt="" />
				<PrimeText tag="h1" size="4xl" weight="400" class="page-header">
					{{ $t(START_SHARING) }}
				</PrimeText>
			</div>
			<RewardsCarousel />
			<div class="page-content">
				<PrimeText
					tag="span"
					:size="isMobile ? 'sm' : 'base'"
					weight="400"
					color="default"
					class="page-subtitle"
				>
					{{ $t(REGISTER_OR_SIGN_IN) }}
				</PrimeText>
				<form class="login-page-form" @submit.prevent="handleSubmitForm">
					<PrimeInput
						v-model="form.email"
						:placeholder="$t(EMAIL)"
						:invalid="!!getValidationFieldStatus('email')"
						:error-text="getValidationFieldText('email')?.toString()"
						autocomplete="username"
						type="email"
					/>

					<PrimeButton
						full-width
						type="gradient"
						role="submit"
						class="submit-button"
						:loading="isLoading"
						:disabled="isLoading"
						:label="$t(CONTINUE)"
					/>
				</form>

				<div class="social-login__title">
					<div class="title-divider" />
					<PrimeText tag="p" weight="400" class="title-text">
						{{ $t(OR_LABEL) }}
					</PrimeText>
					<div class="title-divider" />
				</div>
				<div class="providers-container">
					<SocialButton
						v-for="provider in socialAuthButtons"
						:key="provider"
						:provider="provider"
						non-prefix
						@loading="handleLoading($event)"
						@auth="handleSocialAuth($event)"
					/>
				</div>
			</div>
			<PrimeLoader v-if="isLoading" full-page />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';
.login-page-wrapper {
	overflow: hidden;
	background: transparent !important;
	display: flex;
	flex-grow: 1;
}

.login-page {
	flex: 1;
	justify-content: center;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding: env(safe-area-inset-top) 0.8rem 0;
	text-align: center;

	&-form {
		margin-top: 1rem;
	}
}

.login-page-logo {
	width: 190px;
	height: 3.75rem;
	align-self: center;
	margin: 12px 0;

	@include breakpoint(tablet) {
		margin: 24px 0;
	}
}

.page-header {
	color: #fff !important;
	display: inline-block;
	letter-spacing: -0.5px;
	margin: 0;

	@include breakpoint(tablet) {
		margin-bottom: 0.5rem;
		margin-top: 1.5rem;
	}
}

.page-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	width: 100%;
	max-width: 34rem;
	padding-top: env(safe-area-inset-top);
	padding-bottom: env(safe-area-inset-bottom);
}

.header-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-self: center;
	text-align: center;
	max-width: 34rem;
	margin: 1.75rem 0 2.125rem;
	margin-bottom: 0.75rem;

	@include breakpoint(tablet) {
		margin-bottom: 1.475rem;
	}
}

.form-title {
	text-align: center;
	margin-bottom: 0.75rem;
}

.password-wrapper {
	position: relative;
}

.submit-button {
	font-weight: 500;
	font-size: var(--p-font-14);
	color: white !important;
	margin-top: 1rem;
}

.social-login {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.social-login__title {
	display: flex;
	align-items: center;
	margin: 1.25rem 0;

	& .title-divider {
		height: 1px;
		flex: 1 1 auto;
		background-color: var(--grey-200);
	}

	& .title-text {
		font-size: var(--p-font-14);
		margin: 0 0.675rem;
	}
}

.providers-container {
	display: flex;
	gap: 0.75rem;
	margin-bottom: 2.375rem;

	:deep(.provider-button) {
		flex: 1 0 30%;
	}
}

.slider-container {
	margin-bottom: 1.75rem;
}
</style>
