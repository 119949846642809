<script setup lang="ts">
import {onMounted, ref, computed} from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import ClaimedRewardItem from '@/views/history/views/rewards/components/ClaimedRewardItem.vue';
import {PrimeSkeleton, PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ClaimedRewardsFallback from '@/views/history/views/rewards/components/ClaimedRewardsFallback.vue';
import TotalClaims from '@/views/history/views/rewards/components/TotalClaims.vue';
import {POINTS, REWARDS, TOTAL_CLAIMS} from "@/locales/constants";
import LayoutSubpage from "@/components/LayoutSubpage.vue";
import {fetchTotalRewardsStats} from "@/api";
import {localizePoints, transformCurrency} from "@/utils/helpers";
import {useI18n} from "vue-i18n";

const rewardsStore = useRewardsStore();

onMounted(async () => {
	await rewardsStore.fetchRewardsHistory();
	totalClaims.value = await fetchTotalRewardsStats();

});

const totalClaims = ref<TotalClaims | null>(null);
const isLoading = ref(true);
const amountOfClaims = computed(() => {
	return transformCurrency(
		+totalClaims.value?.money_value! || 0,
		totalClaims.value?.currency || 'USD',
		2
	);
});

const { t } = useI18n();

const totalClaimsValueText = computed(() => {
	return `${localizePoints(totalClaims.value?.coin_value || 0)} ${t(
		POINTS
	)} (≈ ${amountOfClaims.value})`;
});
</script>

<template>
	<main class="rewards-wrapper">
		<LayoutSubpage :title="$t(REWARDS)">
			<div class="rewards">
				<template v-if="rewardsStore.loading">
					<PrimeSkeleton
						v-for="i in 3"
						:key="i"
						height="7.4375rem"
						class="rewards-skeleton"
					/>
				</template>
				<template v-else-if="rewardsStore.claimedRewards?.length">
					<div class="rewards-lifetime">
						<PrimeText weight="400" size="sm">{{ $t(TOTAL_CLAIMS) }}</PrimeText>
						<PrimeText weight="400" size="base" color="white">
							{{ totalClaimsValueText }}
						</PrimeText>
					</div>
					<ClaimedRewardItem
						v-for="item in rewardsStore.claimedRewards"
						:key="item.date"
						:item="item"
					/>
				</template>
				<ClaimedRewardsFallback v-else />
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
.rewards {
	padding: 1.5rem 0;
	height: calc(100% - 9rem);
	display: flex;
	flex-direction: column;
	&-wrapper {
		width: 100%;
	}

	&-lifetime {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0.75rem;
		border: 1px solid var(--p-grey-300);
		margin-bottom: 1.5rem;
		border-radius:  0.75rem;
		background: var(--p-grey-500);
		box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);
	}
}
.rewards-skeleton {
	margin-bottom: 0.75rem;
}
</style>
