<script setup lang="ts">
import { useRouter } from 'vue-router';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { OfferwallPartner } from '@/types';

const { item } = defineProps<{
	item: OfferwallPartner;
}>();

const router = useRouter();

const openPartner = () => {
	router.push({
		name: 'offerwalls-partner',
		params: { partnerId: item.id },
	});
};
</script>

<template>
	<div class="offerwalls-partner-item" @click="openPartner">
		<img :src="item.image_url" :alt="item.name" />
		<PrimeText weight="400" color="inherit">{{ item.name }}</PrimeText>
	</div>
</template>

<style scoped lang="scss">
.offerwalls-partner-item {
	cursor: pointer;
	border-radius: 0.75rem;
	border: 1px solid var(--p-grey-100);
	background: var(--p-grey-500);
	box-shadow:
		0px 1px 3px 0px rgba(63, 63, 68, 0.15),
		0px 0px 0px 1px rgba(63, 63, 68, 0.05);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	padding: 0.75rem;
	height: 110px;
	color: white;

	& img {
		max-height: 2.25rem;
		max-width: 100%;
		flex: 1 0 auto;
		object-fit: contain;
	}

	&:hover {
		border-color: var(--base-primary);
	}
}
</style>
