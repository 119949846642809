<script setup lang="ts">
// @ts-ignore
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Autoplay } from 'swiper/modules';
// @ts-ignore
import type { SwiperContainer } from 'swiper/swiper-element';
import type { SwiperModule } from 'swiper/types';
import 'swiper/css';
import 'swiper/css/autoplay';
import type { GiftCardItem } from '@/types';
import RewardGiftTile from '@/components/auth/RewardGiftTile.vue';
import paypal from '@/assets/gift-cards/paypal.svg';
import walmart from '@/assets/gift-cards/walmart.svg';
import venomo from '@/assets/gift-cards/venomo.svg';
import amazon from '@/assets/gift-cards/amazon.png';
import apple from '@/assets/gift-cards/apple.png';
import visa from '@/assets/gift-cards/visa.png';
import bankTransfer from '@/assets/gift-cards/bank-transfer.svg';
import revolut from '@/assets/gift-cards/revolut.svg';
import spotify from '@/assets/gift-cards/spotify.png';
import netflix from '@/assets/gift-cards/netflix.svg';

const GIFT_CARDS: GiftCardItem[] = [
  {
    src: paypal,
    label: 'PayPal',
    type: 'Cash',
  },
  {
    src: walmart,
    label: 'Walmart',
    type: 'Gift Card',
  },
  {
    src: venomo,
    label: 'Venmo',
    type: 'Cash',
  },
  {
    src: amazon,
    label: 'Amazon',
    type: 'Gift Card',
  },
  {
    src: apple,
    label: 'Apple',
    type: 'Gift Card',
  },
  {
    src: visa,
    label: 'Visa',
    type: 'Credit Card',
  },
  {
    src: bankTransfer,
    label: 'Bank Transfer',
    type: 'Cash',
  },
  {
    src: revolut,
    label: 'Revolut',
    type: 'Cash',
  },
  {
    src: spotify,
    label: 'Spotify',
    type: 'Gift Cards',
  },
  {
    src: netflix,
    label: 'Netflix',
    type: 'Gift Cards',
  },
];
const MODULES: SwiperModule[] = [Autoplay];
const SPEED: number = 3000;

const onSwiper = (swiper: SwiperContainer) => {
  // run autoplay on mobile
  const timeout = setTimeout(() => {
    swiper.autoplay.start();
    clearTimeout(timeout);
  }, 500);
};
</script>

<template>
  <section class="rewards">
    <div class="rewards-list">
      <Swiper
        class="rewards-swiper"
        :modules="MODULES"
        slides-per-view="auto"
        :speed="SPEED"
        :autoplay="{
          delay: 1,
        }"
        :space-between="0"
        :centered-slides="true"
        :loop="true"
        :allow-touch-move="false"
        :disable-on-interaction="true"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="card in GIFT_CARDS" :key="card.label">
          <RewardGiftTile :src="card.src" :label="card.label" :type="card.type" />
        </SwiperSlide>
        <SwiperSlide v-for="card in GIFT_CARDS" :key="card.label">
          <RewardGiftTile :src="card.src" :label="card.label" :type="card.type" />
        </SwiperSlide>
      </Swiper>
    </div>
  </section>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.rewards {
  padding: 1.5rem 0;

	@include breakpoint(tablet) {
		padding: 2.7rem 0;
	}
}

.rewards-header {
  margin-bottom: 2.25rem;
}

.rewards-title {
  margin-bottom: 1.125rem;
}

.rewards-list {
  overflow: hidden;
}

.rewards-swiper {
  :deep(.swiper-wrapper) {
    transition-timing-function: linear;
  }

  :deep(.swiper-slide) {
    padding-left: 0.5625rem;
    padding-right: 0.5625rem;
    width: auto;
  }
}
</style>
