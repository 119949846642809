<script lang="ts" setup>
import { ref } from 'vue';
import { useRewardsStore } from '@/stores/rewards';
import ConfirmWrapper from '@/views/confirm-claim/components/ConfirmWrapper.vue';

defineProps<{
	isClaimAvailable: boolean;
}>();

const isLoading = ref(false);
const rewardsStore = useRewardsStore();

const handleConfirmClaim = async () => {
	try {
		isLoading.value = true;
		await rewardsStore.claim();
	} finally {
		isLoading.value = false;
	}
};
</script>

<template>
	<ConfirmWrapper
		:disabled="!isClaimAvailable"
		:loading="isLoading"
		@confirm="handleConfirmClaim"
	/>
</template>
