<script setup lang="ts">
import {
	RESEND,
	RESET_PASSWORD,
	RESET_PASSWORD_SENT_DETAILS,
	RESET_PASSWORD_SENT_MESSAGE,
	RESET_PASSWORD_SENT_SPAM,
} from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import CooldownButton from '@/components/common/CooldownButton.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import { PiEnvelopeDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { useForgotPasswordView } from '@/views/forgot-password/composables';
import {
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const COOL_DOWN_SECONDS = 15;

const { email, resendResetPassword, isLoading, cooldownButtonEl } =
	useForgotPasswordView();
</script>

<template>
	<AuthPageContent :title="$t(RESET_PASSWORD)" back-route="signin">
		<template #default>
			<div class="password-sent-container">

				<div class="password-sent-info">
					<div class="password-sent-icon">
						<PiEnvelopeDuotoneThin color="inherit" size="100%" />
					</div>
					<PrimeText tag="div" weight="400" color="default" class="password-sent-email">
						<span v-html="$t(RESET_PASSWORD_SENT_MESSAGE, { email })" />
					</PrimeText>
					<PrimeText
						tag="div"
						weight="400"
						size="sm"
						class="password-sent"
					>
						{{ $t(RESET_PASSWORD_SENT_DETAILS) }}
					</PrimeText>
				</div>

				<PrimeText
					tag="div"
					size="xs"
					class="password-sent spam-info"
				>
					{{ $t(RESET_PASSWORD_SENT_SPAM) }}
				</PrimeText>
				<CooldownButton
					ref="cooldownButtonEl"
					:text="$t(RESEND)"
					:timer="COOL_DOWN_SECONDS"
					@click="resendResetPassword"
				/>
				<PrimeLoader v-if="isLoading" />
			</div>
		</template>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.forgot-password-container {
	display: flex;
	flex-direction: column;
	position: relative;
	flex: 1;
	width: 100%;
	max-width: 26.875rem;
	margin: 0 auto;
	text-align: center;
}

.password-sent-container {
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.password-sent-info {
	display: flex;
	width: 100%;
	padding: 0.85rem 0.75rem;
	flex-direction: column;
	align-items: center;
	border-radius: 1rem;
	border: 1px solid var(--grey-cool-300-borders, #363840);
	background-color: var(--base-primary-03);
}

.password-sent-email {
	margin-bottom: 0.25rem;

	:deep(.user-email) {
		word-break: break-word;
		color: white;
	}
}

.password-sent {
	margin-top: 1.5rem;

	&.spam-info {
		margin-bottom: 1.5rem;
	}
}

.password-sent-icon {
	color: white;
	margin: 0 auto;
	width: 3.375rem;
	height: 3.375rem;

	@include breakpoint(mobile) {
		width: 5.875rem;
		height: 5.875rem;
	}
}
</style>
