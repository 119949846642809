<script setup lang="ts">
import {
	TRANSACTIONS_EMPTY_TITLE,
	TRANSACTIONS_EMPTY_DESCRIPTION,
	COLLECT_POINTS
} from '@/locales/constants';
import { useRouter } from 'vue-router';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import no_order_wallet from "@/assets/no-order-wallet.png";

const router = useRouter();

const handleClick = () => router.push({ name: 'surveys' });
</script>

<template>
	<div class="transactions-empty">
		<img :src="no_order_wallet" alt="">
		<div>
			<PrimeText tag="h2" size="lg" weight="500" color="inherit" class="transactions-empty-title">
				{{ $t(TRANSACTIONS_EMPTY_TITLE) }}
			</PrimeText>
			<PrimeText tag="p" size="sm" color="grey-700" class="transactions-empty-message">
				{{ $t(TRANSACTIONS_EMPTY_DESCRIPTION) }}
			</PrimeText>
		</div>
		<RouterLink to="/surveys" class="transactions-empty-link">
			<PrimeButton :label="$t(COLLECT_POINTS)" />
		</RouterLink>
	</div>
</template>

<style lang="scss" scoped>
.transactions-empty {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;
	text-align: center;
	color: white;
}

.transactions-empty-title {
	margin-bottom: 0.875rem;
}

.transactions-empty-link {
	text-align: center;
	width: 100%;
}
</style>
