<script lang="ts" setup>
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { updateProfile } from '@/api';
import type { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types';
import useVuelidate from '@vuelidate/core';
import { useUserStore } from '@/stores/user';
import { ENTER_USERNAME, SAVE } from '@/locales/constants';
import {
	PrimeInput,
	PrimeButton,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';

type ChangeUsernameForm = {
	username: string;
};

const props = defineProps<{
	handler?: Function;
}>();

const componentName = useComponentName();

const userStore = useUserStore();
const { username } = storeToRefs(userStore);

const form = ref<ChangeUsernameForm>({
	username: username.value || '',
});

const loading = ref(false);
const serverValidationResults = ref();

const { username: usernameValidation } = useValidationRules();
const validation = useVuelidate({ username: usernameValidation }, form, {
	$autoDirty: false,
	$externalResults: serverValidationResults,
});

const {
	validate,
	getValidationFieldText,
	isValidationError,
	clear,
	getValidationFieldStatus,
} = useValidationUtils<ChangeUsernameForm>(validation);

const handleSubmit = async () => {
	clear();
	await validate();
	if (isValidationError.value) return;
	try {
		loading.value = true;
		await updateProfile(form.value);
		await userStore.setUsername(form.value.username);
		userStore.setLeaderboardStatus(true);
		if (props.handler) props.handler();
	} catch (error) {
		const errorData = (error as AxiosError).response?.data as ErrorResponse;
		serverValidationResults.value = { username: errorData.message };
		sendErrorInfo(error, componentName);
	} finally {
		loading.value = false;
	}
};

watch(
	() => form.value.username,
	async () => {
		clear();
	}
);
</script>

<template>
	<PrimeLoader v-if="loading" />
	<form class="change-username-form" @submit.prevent="handleSubmit">
		<PrimeInput
			v-model="form.username"
			type="text"
			:placeholder="$t(ENTER_USERNAME)"
			:invalid="!!getValidationFieldStatus('username')"
			:error-text="getValidationFieldText('username')?.toString()"
		/>

		<PrimeButton
			role="submit"
			:variant="!!getValidationFieldStatus('username') ? 'grey' : 'primary'"
			:disabled="!!getValidationFieldStatus('username')"
			:label="$t(SAVE)"
			full-width
		/>
	</form>
</template>

<style lang="scss" scoped>
.change-username-form {
	.p-btn {
		width: 100%;
		margin-top: 2.125rem;
	}
}
</style>
