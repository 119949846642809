<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useBonusStore } from '@/stores/bonus';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import {
	CLAIM_REWARD,
	WELCOME_BONUS,
	WELCOME_BONUS_SUBTITLE,
	WELCOME_BONUS_TEXT,
	WELCOME_BONUS_TITLE
} from '@/locales/constants';
import type { BonusItem } from '@/types';
import { notify } from '@/components/common/notifications';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { useAppStore } from '@/stores/app';
import { BonusLabels, TrackingEvents } from '@/enums';
import {
	PrimeText,
	PrimeButton,
	PrimeDivider,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import money_box_logo from '../../assets/money_box_mock.svg'

const selectedBonus = ref<BonusItem | null>({
	coin_value: 1000,
	money_value: '1000',
	min_claim_balance: 0,
	currency: 'point',
	label: BonusLabels.FAST_PAYOUT,
});
const bonusStore = useBonusStore();
const appStore = useAppStore();

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

onMounted(async () => {
	await bonusStore.fetchBonusList();

	selectedBonus.value = bonusStore.data[0]
});

const setWelcomeBonus = async () => {
	if (selectedBonus.value) {
		const { message } = await bonusStore.selectBonus(
			selectedBonus.value.coin_value
		);

		await appStore.trackEvent(TrackingEvents.WELCOME_BONUS_SELECTED, {
			selected_bonus: selectedBonus.value.coin_value.toString(),
		});
		notify({
			body: message,
		});
		emit('close-modal');
	}
};
</script>

<template>
	<div class="modal-content-container">
		<img :src="money_box_logo" alt="">
		<div class="welcome-bonus">
			<PrimeText tag="div" size="lg" color="inherit" weight="500">
					{{ $t(WELCOME_BONUS) }}
			</PrimeText>
			<PrimeText tag="div" class="welcome-bonus-description" size="sm" color="grey-700" weight="400">
					{{ $t(WELCOME_BONUS_TITLE) }}
			</PrimeText>
			<PrimeText tag="div" class="welcome-bonus-description" size="sm" color="grey-700" weight="400">
					{{ $t(WELCOME_BONUS_SUBTITLE) }}
			</PrimeText>
			<PrimeDivider
				top-gap="0.875rem"
				bottom-gap="0.875rem"
			/>
			<PrimeText tag="div" size="sm" color="grey-700" weight="400">
				{{
					$t(WELCOME_BONUS_TEXT, {
						coin_value: localizePoints(selectedBonus?.coin_value),
						money_value: transformCurrency(
							+selectedBonus?.money_value,
							selectedBonus?.currency,
							2
						),
						min_claim_balance: localizePoints(selectedBonus?.min_claim_balance),
					})
				}}
			</PrimeText>
		</div>

		<PrimeButton
			:label="$t(CLAIM_REWARD)"
			@click="setWelcomeBonus"
		/>
		<PrimeLoader v-if="selectedBonus && bonusStore.loading" />
	</div>
</template>

<style lang="scss" scoped>
.modal-content-container {
	display: flex;
	flex-direction: column;

	img {
		width: 12.5rem;
		height: 12.5rem;
		margin: 0 auto;
	}

	.welcome-bonus {
		margin: 2.375rem 0;

		&-description {
			margin-top: 0.875rem;
		}
	}
}
</style>
