import { defineStore } from 'pinia';
import { getUser } from '@/api';
import type {
	ErrorResponse,
	FeatureFlagsData,
	Leaderboard,
	LevelTier,
	Locale,
	OfferwallMeta,
	SelectedReward,
	SelectedRewardOption,
	UserAccount,
	UserLevel,
	UserStreak,
	OfferwallPendingEvents,
} from '@/types';
import type { AxiosError } from 'axios';
import { getRootDomain } from '@/utils/helpers';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useRouter } from 'vue-router';
import { handleUserLocale } from '@/i18n';
import * as Sentry from '@sentry/capacitor';
import * as SentryVue from '@sentry/vue';
import { Capacitor } from '@capacitor/core';
import { fetchUserFeatures } from "@/api/features";
import { FeatureFlags } from "@/enums";
import { getOfferWallPending } from '@/api';

interface UserState {
	data: UserAccount | null;
	message: string;
	loading: boolean;
	error: ErrorResponse | null;
	initialDataLoading: boolean;
	features: FeatureFlagsData | null;
	offerwallsPending: OfferwallPendingEvents | null;
}

export const useUserStore = defineStore('user', {
	state: (): UserState => ({
		data: null,
		message: '',
		loading: false,
		error: null,
		initialDataLoading: false,
		features: null,
		offerwallsPending: null,
	}),
	getters: {
		isStoreLoaded: (state) => state.data && !state.error,
		userId: (state) => state.data?.id,
		userHash: (state) => state.data?.hash,
		username: (state) => state.data?.username || '',
		email: (state) => state.data?.email,
		timezone: (state) => state.data?.timezone || '',
		countryCode: (state) => state.data?.country?.code.toLowerCase() || '',
		countryName: (state) => state.data?.country?.name || '',
		createdAt: (state) => state.data?.created_at || '',
		maxPrize: (state) => state.data?.streak?.max_prize_value || 0,
		streakDayStart: (state) => state.data?.streak?.start_day || 0,
		weekLenght: (state) => state.data?.streak?.cycle_time || 7,
		prizes: (state) => state.data?.streak?.prizes || 0,
		streaks: (state) => state.data?.streak?.length || 0,
		completedDays: (state) => state.data?.streak?.days_in_current || 0,
		isTodayGoalCompleted: (state) => state.data?.streak?.daily_goal_complete,
		leaderboard: (state): Leaderboard =>
			state.data?.leaderboard || {
				active: false,
				has_enough_points: false,
				successful_earned_point_toward_leaderboard: 0,
				total_points_required_to_unlock: 0,
				earned: 0,
				rank: '100',
				unlocked: false,
				record: '+99',
			},
		levelActual: (state) => state.data?.level?.actual || 0,
		levelNext: (state) => state.data?.level?.next || 1,
		levelReward: (state) => state.data?.level?.reward || 0,
		levelRewardEarned: (state) => state.data?.level?.reward_earned || 0,
		coinsEarned: (state) => state.data?.level?.coins_earned || 0,
		coinsTarget: (state) => state.data?.level?.coins_target || 100,
		dailyStreakGoal: (state) => state.data?.daily_goal_progress?.goal || 0,
		dailyStreakProgress: (state) => state.data?.daily_goal_progress?.progress || 0,

		isWelcomeBonusSelected: (state) => !!state.data?.bonus,
		isWelcomeBonusClaimed: (state) => !!state.data?.bonus?.claimed_at,
		minWelcomeBonusClaimBalance: (state) =>
			state.data?.bonus?.min_claim_balance || 0,
		userData: (state) => state.data,

		collectedCoins: (state) => state.data?.balance || 0,

		refLink: (state) => `${getRootDomain(true)}/register?ref=${state.data?.id}`,
		userSelectedReward: (state): SelectedReward | null =>
			state.data?.selected_reward || null,
		selectedRewardOption: (state): SelectedRewardOption | null => {
			if (state.data?.selected_reward) {
				return {
					reward_external_id: state.data?.selected_reward.reward_external_id,
					money_value: state.data?.selected_reward.money_value,
				};
			}

			return null;
		},
		welcomeBonusAmount: (state) => state.data?.bonus?.coin_value || 0,
		currency: (state) => state.data?.country.currency || '',
		// isLeaderboardActive: (state) => state.data?.leaderboard.active,
		isLeaderboardActive: () => true,
		source: (state) => state.data?.s || '',
		userLocales: (state): Locale[] => state.data?.country.locales!,
		isPasswordSet: (state) =>
			state.data && 'is_password_set' in state.data
				? state.data?.is_password_set
				: true,
		currentTier: (state): LevelTier => state.data?.level!.tier!,
		currencyBalance: (state) => state.data?.currency_balance,
		lastClaimEmail: (state) => state.data?.last_claim_email || '',
		userLocale: (state) => state.data?.locale || 'en-us',
		isBonusDayUnlocked: (state) => state.data?.bonus_day.is_available,
		bonusDayBonus: (state) =>
			state.data ? state.data?.bonus_day.percentage + '%' : '',
		// TODO Hardcoded for now
		rewardsDiscount: () => '5%',
		revolutFullName: (state) =>
			state.data?.last_revolut_counterparty.full_name || '',
		revolutTag: (state) => state.data?.last_revolut_counterparty.tag || '',
		lastUsedAchFullName: (state) =>
			state.data?.last_claim_counterparty_name || '',
		isInitialDataLoading: (state) => state.initialDataLoading,
		isChatAvailableForUser: (state) => state.data?.is_chat_available || false,
		isOfferwallsAvailable: (state) =>
			state.features
				? Object.keys(state.features).includes(FeatureFlags.OFFER_WALLS)
				: false,
		offerwallsMeta: (state) =>
			state.features && state.features.offer_walls
				? (state.features.offer_walls as OfferwallMeta)
				: null,
		offerwallsPendings: (state) =>
			state.offerwallsPending?.pending_events || [],
		offerwallsPoints: (state) => state.offerwallsPending?.pending_points || 0,
	},
	actions: {
		async fetchUserData() {
			try {
				this.loading = true;
				this.data = await getUser();
				await handleUserLocale(this.data.locale);
				SentryVue.setTag('user_id', this.data.id);
				if (Capacitor.isNativePlatform()) {
					Sentry.setTag('user_id', this.data.id);
				}
			} catch (error) {
				const axiosErrorResponse = (error as AxiosError).response;
				if (axiosErrorResponse?.status === 401) {
					const router = useRouter();
					await router.replace('login');
				} else {
					this.error = axiosErrorResponse?.data as ErrorResponse;
				}
				sendErrorInfo(error);
			} finally {
				this.loading = false;
			}
		},
		setCollectedCoins(value: number) {
			if (this.data) {
				this.data.balance = value;
			}
		},
		setLevel(level: UserLevel) {
			if (this.data?.level) {
				this.data.level = level;
			}
		},
		increaseOfferwallsMetaProgress() {
			if (this.isOfferwallsAvailable && this.features?.offer_walls) {
				this.features = {
					...this.features,
					offer_walls: {
						...this.features.offer_walls,
						progress: this.features?.offer_walls.progress + 1,
					},
				};
			}
		},
		setStreak(streak: UserStreak) {
			if (this.data?.streak) {
				this.data.streak = streak;
			}
		},
		setUsername(value: string) {
			if (this.data?.username !== undefined) {
				this.data.username = value;
			}
		},
		setTimezone(value: string) {
			if (this.data?.timezone && this.data.timezone !== value) {
				this.data.timezone = value;
			}
		},
		setInitialDataLoading(value: boolean) {
			this.initialDataLoading = value;
		},
		setLeaderboardStatus(status: boolean) {
			if (!this.data?.leaderboard) return;

			this.data.leaderboard = {
				...this.data.leaderboard,
				unlocked: status,
			};
		},
		async fetchFeatures() {
			this.features = await fetchUserFeatures();
		},
		async fetchOfferwallPending() {
			this.offerwallsPending = await getOfferWallPending();
		},
	},
});
