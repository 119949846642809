<script setup lang="ts">
// @ts-ignore
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { ref, watch } from 'vue';
import imageFallback from '@/assets/gift-cards/gift-duotone-thin.svg';
import type { GiftCardItem } from '@/types';
import {useScreenSize} from "@/utils/composables/useScreenSize";

type GiftCardProps = GiftCardItem & {
	secondary?: boolean;
	outline?: boolean;
	external?: boolean;
};

const {
	src,
	label,
	type = '',
	secondary = false,
	outline = false,
	external = false,
} = defineProps<GiftCardProps>();

const imageSrc = ref<string>('');
const { isMobile } = useScreenSize();

const isValidImageUrl = (url: string) => {
	const regexp = /^https?:\/\//;
	return regexp.test(url);
};

const handleImageError = () => {
	imageSrc.value = imageFallback;
};

watch(
	() => src,
	(newSrc) => {
		if (!isValidImageUrl(newSrc) && external) {
			handleImageError();
		} else {
			imageSrc.value = newSrc;
		}
	},
	{ immediate: true },
);
</script>

<template>
	<div
		class="gift-card"
		:class="{
      'gift-card--secondary': secondary,
      'gift-card--outline': outline,
    }"
	>
		<img :src="imageSrc" :alt="label" @error="handleImageError" />
		<div class="gift-card-content">
			<PrimeText :size="isMobile ? 'sm' : 'lg'" color="inherit" weight="500">
				{{ label }}
			</PrimeText>
			<div class="gift-card-type">
				<PrimeText v-if="type" class="" :size="isMobile ? 'xs' : 'sm'" color="inherit">
					{{ type }}
				</PrimeText>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.gift-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	border: 1px solid var(--p-grey-700);
	border-radius: var(--p-radius-2xl);
	background-color: var(--p-dark-400);
	color: white;
	padding: 0.5rem 1rem;
	gap: 0.5rem;
	width: 12.625rem;

	@include breakpoint(tablet) {
		gap: 1.125rem;
		padding: 0.875rem 1rem;
		width: 15.625rem;
	}

	&--secondary {
		gap: 2.25rem;
		padding: 2rem 2.625rem;
		width: 20.5rem;

		img {
			align-self: flex-start;
			height: 1.125rem;

			@include breakpoint(tablet) {
				height: 3.125rem;
			}
		}
	}

	&--outline {
		background: linear-gradient(
				111deg,
				rgba(255, 255, 255, 0.4) -8.95%,
				rgba(255, 255, 255, 0.01) 114%
		);
		border: 2px solid var(--p-grey-300);
	}

	img {
		width: auto;
		height: 2.25rem;
		object-fit: contain;

		@include breakpoint(tablet) {
			height: 3.125rem;
		}
	}
}

.gift-card-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	gap: 0.5rem;
}

.gift-card-type {
	color: #808080;
	border: 1px solid #272525;
	padding: 0.25rem 0.425rem;
	border-radius: var(--p-radius-rounded);
	letter-spacing: -0.05em;
	line-height: 1.2;

	@include breakpoint(tablet) {
		padding: 0.375rem 0.75rem;
	}
}
</style>

