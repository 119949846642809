import API, {getPaginatedData} from '@/api';
import type { OfferwallPartner, OfferwallPartnerContent, OfferwallPendingEvents } from '@/types';

export const getOfferWallsList = async (
	offset = 10,
	page = 1
): Promise<OfferwallPartner[]> => {
	const { data } = await getPaginatedData<OfferwallPartner>(
		'api/offerwalls/partners',
		offset,
		page
	);

	return data;
};

export const getOfferWallById = async (
	id: string
): Promise<OfferwallPartnerContent> => {
	const { data } = await API.get<OfferwallPartnerContent>(
		`api/offerwalls/partners/${id}`
	);

	return data;
};

export const getOfferWallPending = async (): Promise<OfferwallPendingEvents> => {
	const { data } = await API.get<OfferwallPendingEvents>(
		'/api/offerwall-events/pending'
	);

	return data;
};
