import { ref } from 'vue';
import { Timer } from '@/utils/timer';

export interface Notification {
	id: number;
	body: string;
	timer: Timer;
	onClose: () => void;
}

export const notifications = ref<Notification[]>([]);

let notificationId = 0;

export const notify = ({
	body,
	duration = 7000,
}: {
	body: string;
	duration?: number;
}) => {
	const id = notificationId;

	const removeNotification = () => {
		const index = notifications.value.findIndex((item) => item.id === id);
		if (index !== -1) {
			notifications.value.splice(index, 1);
		}
	};

	notifications.value.push({
		id,
		body: body,
		timer: new Timer(duration, removeNotification),
		onClose: removeNotification,
	});

	notificationId++;
};
