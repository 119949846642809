<script setup lang="ts">
import PrimeNavigation from '@/views/profile/components/PrimeNavigation.vue';
import {
	HELP,
	REF_PAGE_TITLE,
	INVITE_FRIEND,
	REDEEM_CODE,
} from '@/locales/constants';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { BONUS_CODE_MODAL } from '@/constants/modals';
import {
	PiGiftDuotone,
	PiLifebuoyDuotone,
	PiUserPlusDuotone,
} from '@primeinsightsgroupllc/prime-icons';

const modalStorage = useModalStorage();

const openBonusCodeModal = () => {
	modalStorage.pushModal({
		name: BONUS_CODE_MODAL,
	});
};
</script>

<template>
	<main class="more-page">
		<PrimeNavigation
			:icon="PiLifebuoyDuotone"
			:title="$t(HELP)"
			:route="{ name: 'support' }"
		/>
		<PrimeNavigation
			:icon="PiUserPlusDuotone"
			:title="$t(REF_PAGE_TITLE)"
			:route="{ name: 'referrals' }"
			:details="$t(INVITE_FRIEND)"
		/>
		<PrimeNavigation
			:icon="PiGiftDuotone"
			:title="$t(REDEEM_CODE)"
			@click="openBonusCodeModal"
		/>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.more-page {
	padding: 1rem;
	background-color: var(--white);
	font-weight: 500;
	width: 100%;

	@include breakpoint(mobile) {
		margin: 2rem 0 0;
		padding: 1.5rem;
		box-shadow: var(--card-shadow);
		border-radius: 0.75rem 0.75rem 0 0;
		max-width: 40.625rem;
	}
}
</style>
