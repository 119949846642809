<script setup lang="ts">
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import ReferralsHowItWorks from './components/ReferralsHowItWorks.vue';
import ReferralsShare from './components/ReferralsShare.vue';
import ReferralsStatistics from './components/ReferralsStatistics.vue';
import {
	REF_PAGE_TITLE,
	EARN_BY_REFERRING,
	EARN_BY_REFERRING_DESCRIPTION, REF_SHARE_ATTENTION,
} from '@/locales/constants';
import {
	PrimeText,
	PrimeDivider,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { PiHcWarning } from "@primeinsightsgroupllc/prime-icons";

const { isMobile } = useScreenSize();
</script>

<template>
	<main class="referrals">
		<LayoutSubpage :title="$t(REF_PAGE_TITLE)">
			<div class="referrals-container">
				<div class="referrals-header">
					<PrimeText tag="p" color="grey-700" size="sm">
						{{ $t(EARN_BY_REFERRING_DESCRIPTION) }}
					</PrimeText>
					<PrimeDivider
						color="grey-800"
						top-gap="0.875rem"
						bottom-gap="0.875rem"
					/>
					<ReferralsShare />
					<PrimeDivider
						color="grey-800"
						top-gap="0.875rem"
						bottom-gap="0.875rem"
					/>
					<PrimeText tag="p" color="grey-700" size="sm">
						<span class="referrals-warning">
							<PiHcWarning size="1.25rem" color="inherit" />
							{{ $t(REF_SHARE_ATTENTION) }}
						</span>
					</PrimeText>

				</div>
				<ReferralsHowItWorks />
				<ReferralsStatistics />
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals {
	width: 100%;

	&-warning {
		display: flex;
		align-items: center;
		gap: 0.5rem;

		svg {
			flex-shrink: 0;
		}
	}

	&-container {
		display: flex;
		flex-direction: column;
		gap: 36px;
		padding-bottom: 5rem;
	}
}

.referrals-header {
	max-width: 350px;
	width: 100%;
	margin: 0 auto;
	border-radius: 0.75rem;
	border: 1px solid #303133;
	background: #28292B;
	gap: 0.875rem;
	padding: 1rem 0.625rem;
	box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);
}
</style>
