<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import logo_mock from '@/assets/logo_mock.svg';
import DesktopNav from '@/components/common/header/DesktopNav.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';

const route = useRoute();
const { isAuthorized } = storeToRefs(useAuthStore());

const authStepsPages = computed(() => {
	return route.name && route.meta.withoutHeader;
});
</script>

<template>
	<div v-if="!authStepsPages" class="app-sidebar">
		<div class="app-sidebar-logo">
			<router-link :to="{ name: 'home' }">
				<img :src="logo_mock" alt="Logo" />
			</router-link>
		</div>
		<div>
			<DesktopNav
				v-if="isAuthorized"
				class="app-header__col app-header__col--desktop-menu"
			/>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-sidebar {
	width: 16.875rem;
	height: 100vh;
	border-right: 1px solid #252729;
	position: fixed;
	z-index: 12;
	left: 0;
	top: 0;
	background: #1c1d1f;
	flex-direction: column;
	padding: 1.3125rem 2.5rem;
	display: none;

	@include breakpoint(tablet) {
		display: flex;
	}

	.app-sidebar-logo {
		margin-bottom: 3.1875rem;

		img {
			width: 130px;
			height: 2.375rem;
		}
	}
}
</style>
