<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import {
	PrimeButton,
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	PiHcCheck_circle,
	PiHcWarning,
} from '@primeinsightsgroupllc/prime-icons';
import {
	AppUpdate,
	AppUpdateAvailability,
	type FlexibleUpdateState,
} from '@capawesome/capacitor-app-update';
import {
	NEW_VERSION_AVAILABLE_BTN,
	NEW_VERSION_AVAILABLE_BTN_FAILED,
	NEW_VERSION_AVAILABLE_BTN_INSTALLED,
	NEW_VERSION_AVAILABLE_MSG,
	NEW_VERSION_AVAILABLE_MSG_DOWNLOADING,
	NEW_VERSION_AVAILABLE_MSG_FAILED,
	NEW_VERSION_AVAILABLE_MSG_INSTALLED,
	NEW_VERSION_AVAILABLE_MSG_INSTALLING,
	NEW_VERSION_AVAILABLE_SUCCESS,
	NEW_VERSION_AVAILABLE_TITLE,
	NEW_VERSION_AVAILABLE_TITLE_DOWNLOADING,
	NEW_VERSION_AVAILABLE_TITLE_FAILED,
	NEW_VERSION_AVAILABLE_TITLE_INSTALLED,
	NEW_VERSION_AVAILABLE_TITLE_INSTALLING,
} from '@/locales/constants';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { Platforms } from '@/enums';
import { computed, onBeforeMount, ref, watch } from "vue";
import { notify } from '@/components/common/notifications';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores/app';
import { FlexibleUpdateInstallStatus } from '@capawesome/capacitor-app-update/dist/esm/definitions';
import update_available from '@/assets/update_available.svg';
import { useDev } from "@/utils/composables/useDev";
import { storeToRefs } from "pinia";

const emits = defineEmits<{
	(e: 'close-modal'): void;
}>();

const { platform } = useMobileApp();
const { t } = useI18n();
const progress = ref<number>(0);
const flexibleUpdate = ref<boolean>(false);
const updateStatus = ref<FlexibleUpdateInstallStatus>(
	FlexibleUpdateInstallStatus.UNKNOWN
);

const appStore = useAppStore();
const { packageVersion } = useDev();
const { isNativeUpdateAvailable } = storeToRefs(appStore);

onBeforeMount(() => {
	if (!isNativeUpdateAvailable.value) {
		appStore.addLog(
			`onBeforeMount isNativeUpdateAvailable >>>> ${isNativeUpdateAvailable.value}`
		);
		emits('close-modal');
	}
});

const completeFlexibleUpdate = async () => {
	appStore.addLog(`completeFlexibleUpdate >>>> `);
	AppUpdate.completeFlexibleUpdate()
		.then(() => {
			appStore.addLog(
				`completeFlexibleUpdate THEN >>>> ${packageVersion.value} `
			);
			emits('close-modal');
		})
		.catch((e) => {
			appStore.addLog(`completeFlexibleUpdate ERROR >>>> ${JSON.stringify(e)}`);
		})
		.finally(() => {
			emits('close-modal');
			appStore.addLog(
				`completeFlexibleUpdate FINALLY >>>> ${packageVersion.value} `
			);
		});
};

const initProgressListener = () => {
	AppUpdate.addListener(
		'onFlexibleUpdateStateChange',
		(state: FlexibleUpdateState) => {
			updateStatus.value = state.installStatus;

			appStore.addLog(`onUpdateStateChange >>>> ${JSON.stringify(state)}`);

			progress.value = Math.floor(
				(state.bytesDownloaded / state.totalBytesToDownload) * 100
			);

			if (state.installStatus === FlexibleUpdateInstallStatus.INSTALLED) {
				notify({
					body: t(NEW_VERSION_AVAILABLE_SUCCESS),
				});
			}

			if (state.installStatus === FlexibleUpdateInstallStatus.DOWNLOADED) {
				appStore.addLog(
					`onUpdateStateChange DOWNLOADED >>>> ${JSON.stringify(state)}`
				);
			}
		}
	);
};

const appStoreUpdate = async () => {
	await AppUpdate.openAppStore();
	emits('close-modal');
};

const startFlexibleUpdate = async () => {
	const result = await AppUpdate.getAppUpdateInfo();

	appStore.addLog(`startFlexibleUpdate result >>>> ${JSON.stringify(result)}`);

	if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
		throw new Error(`Update is not available::: ${JSON.stringify(result)}`);
	}

	if (result.flexibleUpdateAllowed) {
		flexibleUpdate.value = true;
		initProgressListener();
		appStore.addLog(`startFlexibleUpdate >>>> `);

		const updateResults = await AppUpdate.startFlexibleUpdate();

		appStore.addLog(
			`startFlexibleUpdate updateResults >>>> ${JSON.stringify(updateResults)}`
		);
	}
};

const handleClick = async () => {
	if (platform.value === Platforms.ANDROID) {
		try {
			appStore.addLog(`handleClick >>>>`);
			await startFlexibleUpdate();
		} catch (e) {
			appStore.addLog(`handleClick ERROR UPDATE >>>> ${JSON.stringify(e)}`);
			// Fallback to default app store update on error
			await appStoreUpdate();
		}
	} else {
		await appStoreUpdate();
	}
};

const platformStore = computed(() =>
	platform.value === Platforms.ANDROID ? 'Google Play' : 'App Store'
);

const titleText = computed(() => {
	switch (updateStatus.value) {
		case FlexibleUpdateInstallStatus.DOWNLOADING:
			return t(NEW_VERSION_AVAILABLE_TITLE_DOWNLOADING);
		case FlexibleUpdateInstallStatus.INSTALLING:
			return t(NEW_VERSION_AVAILABLE_TITLE_INSTALLING);
		case FlexibleUpdateInstallStatus.INSTALLED:
			return t(NEW_VERSION_AVAILABLE_TITLE_INSTALLED);
		case FlexibleUpdateInstallStatus.FAILED:
			return t(NEW_VERSION_AVAILABLE_TITLE_FAILED);
		default:
			return t(NEW_VERSION_AVAILABLE_TITLE);
	}
});

const descriptionText = computed(() => {
	switch (updateStatus.value) {
		case FlexibleUpdateInstallStatus.DOWNLOADING:
			return t(NEW_VERSION_AVAILABLE_MSG_DOWNLOADING);
		case FlexibleUpdateInstallStatus.INSTALLING:
			return t(NEW_VERSION_AVAILABLE_MSG_INSTALLING);
		case FlexibleUpdateInstallStatus.INSTALLED:
		case FlexibleUpdateInstallStatus.DOWNLOADED:
			return t(NEW_VERSION_AVAILABLE_MSG_INSTALLED);
		case FlexibleUpdateInstallStatus.FAILED:
			return t(NEW_VERSION_AVAILABLE_MSG_FAILED, {
				store: platformStore.value,
			});
		default:
			return t(NEW_VERSION_AVAILABLE_MSG, {
				store: platformStore.value,
			});
	}
});

const buttonText = computed(() => {
	switch (updateStatus.value) {
		case FlexibleUpdateInstallStatus.UNKNOWN:
			return t(NEW_VERSION_AVAILABLE_BTN);
		case FlexibleUpdateInstallStatus.INSTALLED:
		case FlexibleUpdateInstallStatus.DOWNLOADED:
			return t(NEW_VERSION_AVAILABLE_BTN_INSTALLED);
		case FlexibleUpdateInstallStatus.FAILED:
			return t(NEW_VERSION_AVAILABLE_BTN_FAILED, {
				store: platformStore.value,
			});
		default:
			return null;
	}
});

const buttonAction = computed(() => {
	switch (updateStatus.value) {
		case FlexibleUpdateInstallStatus.UNKNOWN:
			return handleClick;
		case FlexibleUpdateInstallStatus.DOWNLOADED:
		case FlexibleUpdateInstallStatus.INSTALLED:
			return completeFlexibleUpdate;
		case FlexibleUpdateInstallStatus.FAILED:
			return appStoreUpdate;
		default:
			return null;
	}
});

watch(
	isNativeUpdateAvailable,
	(value) => {
		if (!value) {
			appStore.addLog(`watch isNativeUpdateAvailable >>>> ${value}`);
			emits('close-modal');
		}
	},
	{ immediate: true }
);
</script>

<template>
	<div id="update-available-modal">
		<ModalCommonContent>
			<template #icon>
				<PiHcCheck_circle
					v-if="
						updateStatus === FlexibleUpdateInstallStatus.INSTALLED ||
						updateStatus === FlexibleUpdateInstallStatus.DOWNLOADED
					"
					color="states-success"
					size="3.375rem"
				/>
				<PiHcWarning
					v-else-if="updateStatus === FlexibleUpdateInstallStatus.FAILED"
					color="states-critical"
					size="3.375rem"
				/>
				<img :src="update_available" v-else alt="update_available">
			</template>
			<template #title>
				<PrimeText color="white" weight="600">
					{{ titleText }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText weight="400" size="sm">
					{{ descriptionText }}
				</PrimeText>
			</template>
			<template #actions>
				<PrimeButton
					v-if="buttonText && buttonAction"
					:label="buttonText"
					@click="buttonAction"
				/>
				<PrimeProgressbar
					v-if="updateStatus === FlexibleUpdateInstallStatus.DOWNLOADING"
					:value="progress"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#update-available-modal {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0.875rem;
	background: var(--grey-50);
	display: flex;
	flex-direction: column;
	justify-content: center;

	:deep(.modal-common-content) {
		.content-icon {
			display: flex;
			width: 100%;
			height:auto;
			margin-bottom: 0.75rem;
			justify-content: center;

			img {
				width: 200px;
				height: 200px;
			}
		}

		.content-description {
			border-bottom: none;
		}

		.content-actions {
			margin-top: 0;
		}

		.p-progress {
			border-radius: 3.125rem;
			background: var(--p-grey-300);
		}

		.p-progress-bar {
			border-radius: 3.125rem;
			background: linear-gradient(270deg, #FF6B34 0%, rgba(255, 107, 52, 0.00) 100%) !important;
		}
	}
}
</style>
