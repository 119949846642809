<script lang="ts" setup>
import type { ClaimedReward } from '@/types';
import { AMOUNT, COINS_USED, DATE } from '@/locales/constants';
import RewardImage from '@/components/common/RewardImage.vue';
import ClaimRewardDetails from '@/views/history/views/rewards/components/ClaimRewardDetails.vue';
import { localizePoints, toDate, transformCurrency } from '@/utils/helpers';
import ClaimStatus from '@/views/history/views/rewards/components/ClaimStatus.vue';
import type { ClaimStatuses } from '@/enums';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import {
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

defineProps<{
	item: ClaimedReward;
}>();

const { localizationCookie } = useAppCookies();
const localization = localizationCookie || 'en-US';
</script>

<template>
	<div class="claim-item-container">
		<div class="claim-item-content">
			<div class="claim-item-top">
				<div class="partner-info">
					<RewardImage :src="item.reward_image_url" :alt="item.reward_name" />
					<PrimeText weight="400" tag="p" color="inherit">{{ item.reward_name }}</PrimeText>
				</div>
				<ClaimStatus :status="item.status as ClaimStatuses" />
			</div>
			<PrimeDivider top-gap="0.75rem" bottom-gap="0.75rem" color="grey-300"/>
			<div class="claim-item-bottom">
				<ClaimRewardDetails
					:title="$t(AMOUNT)"
					:data="transformCurrency(item.money_value, item.currency)"
				/>
				<ClaimRewardDetails
					:title="$t(COINS_USED)"
					:data="localizePoints(item.coin_value)"
				/>
				<ClaimRewardDetails
					:title="$t(DATE)"
					:data="toDate(item.date, localization)"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.claim-item-container {
	display: flex;
	flex-direction: column;
	padding: 0.75rem;
	border: 1px solid var(--p-grey-300);
	margin-bottom: 0.75rem;
	border-radius:  0.75rem;
	background: var(--p-grey-500);
	box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);
}

.claim-item-content {
	.claim-item-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 0.875rem;

		@include breakpoint(mobile) {
			font-size: 1rem;
		}
	}

	.claim-item-bottom {
		display: grid;
		grid-template-columns: 1fr 2fr 2fr;
		gap: 0.875rem;
		font-size: 0.75rem;

		@include breakpoint(mobile) {
			font-size: 0.875rem;
			grid-template-columns: 2fr 2fr 2fr;
		}

		&:last-child {
			align-items: flex-end;
		}
	}
}

.partner-info {
	display: flex;
	align-items: center;
	color: white;

	& p {
		margin-left: 0.5rem;
	}
}
</style>
