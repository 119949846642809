<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { storeToRefs } from 'pinia';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiHcChevron_right } from '@primeinsightsgroupllc/prime-icons';
import { PENDING_POINTS } from '@/locales/constants';
import { useUserStore } from '@/stores/user';

defineEmits(['open']);

const userStore = useUserStore();
const { offerwallsPoints } = storeToRefs(userStore);

onBeforeMount(() => {
	userStore.fetchOfferwallPending();
});
</script>

<template>
	<div class="offerwalls-description__pending">
		<PrimeText tag="p" size="sm" weight="400" color="grey-700">
			{{ $t(PENDING_POINTS) }}:
		</PrimeText>
		<button class="offerwalls-description__button" @click="$emit('open')">
			<PrimeText size="base" weight="600" color="inherit">
				{{ offerwallsPoints }}
			</PrimeText>
			<PiHcChevron_right size="1rem" color="inherit" />
		</button>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins';

.offerwalls-description__pending {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.offerwalls-description__button {
	display: flex;
	align-items: center;
	border-radius: 0.5rem;
	background: transparent;
	color: white;
	border: 1px solid var(--p-grey-100);
	cursor: pointer;
	height: 35px;
	padding: 0.875rem 0.75rem;
	justify-content: center;
	gap: 0.5rem;
}
</style>
