import { useRoute, useRouter } from 'vue-router';
import { useComponentName } from '@/utils/composables/useComponentName';
import { useAuthStore } from '@/stores/auth';
import { SocialProviders } from '@/enums';
import { sendErrorInfo } from '@/utils/errorCatching';

export const useAuthSocialView = () => {
	const route = useRoute();
	const router = useRouter();
	const componentName = useComponentName();

	const authStore = useAuthStore();

	const initSocialAuth = async () => {
		try {
			if (route.query) {
				const callbackUrl =
					route.query.provider === SocialProviders.APPLE
						? {
							...route.query,
							...(route.hash && {
								code: route.hash.replace('#code=', ''),
							}),
						}
						: route.query;

				await authStore.checkSocial({ callbackUrl });
			} else {
				await router.replace({
					name: 'app-login',
				});
			}
		} catch (error) {
			sendErrorInfo(error, componentName);
		}
	}

	return {
		initSocialAuth
	}
};
