<script setup lang="ts">
import OfferwallsTabs from '@/views/offerwalls/views/partners/components/OfferwallsTabs.vue';
import OfferwallsPartnersList from '@/views/offerwalls/views/partners/components/OfferwallsPartnersList.vue';
import PageCard from '@/components/PageCard.vue';
import { computed, onMounted, ref } from 'vue';
import { UNLOCK_OFFERWALLS_MODAL } from '@/constants/modals';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { OfferwallsStatuses } from '@/enums';
import OffersInternalList from '@/views/offerwalls/views/partners/OffersInternalList.vue';

const tabNames = ref(['Offers', 'Offerwalls']);
const currentTab = ref('Offerwalls');

const modalStorage = useModalStorage();
const { offerwallsMeta, isOfferwallsAvailable } = storeToRefs(useUserStore());

const isOfferwallsLocked = computed(() => {
	return offerwallsMeta.value?.status === OfferwallsStatuses.LOCKED;
});

onMounted(() => {
	if (isOfferwallsLocked.value) {
		modalStorage.pushModal({
			name: UNLOCK_OFFERWALLS_MODAL,
			options: {
				useDefaultClose: false,
			},
		});
	}
});
</script>

<template>
	<main class="offers">
		<PageCard v-if="isOfferwallsAvailable && !isOfferwallsLocked">
			<OfferwallsTabs v-model="currentTab" :tabs="tabNames" />
			<OfferwallsPartnersList v-if="currentTab === 'Offerwalls'" />
			<OffersInternalList v-else />
		</PageCard>
	</main>
</template>

<style lang="scss" scoped>
.offers {
	width: 100%;
	padding-bottom: calc(env(safe-area-inset-bottom) + 5rem);
	overflow-y: auto;
}
</style>
