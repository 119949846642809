<script setup lang="ts">
import {
	LEVELS_DESCRIPTION_TITLE,
	NEW_LEVELS,
	SOUNDS_GREAT,
} from '@/locales/constants';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const userStore = useUserStore();

const { tiersData, currentTier, levelActual } = storeToRefs(userStore);
</script>

<template>
	<div class="levels-description-modal">
		<ModalCommonContent>
			<template #header>{{ $t(NEW_LEVELS) }}</template>
			<template #title>
				{{ $t(LEVELS_DESCRIPTION_TITLE) }}
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(SOUNDS_GREAT)"
					@click="$emit('close-modal')"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.current-level__wrapper {
	margin-top: 0.5rem;
	margin-bottom: 0.875rem;

	display: flex;
	gap: 0.25rem;
	align-items: center;
	justify-content: center;

	& .current-level__circle {
		margin: 0.3125rem;
	}
}

.tiers-list {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	width: 100%;
	overflow-y: scroll;
}
</style>
