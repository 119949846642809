<script setup lang="ts">
import OfferwallsPartnerItem from '@/views/offerwalls/views/partners/components/OfferwallsPartnerItem.vue';
import { onBeforeMount, ref } from 'vue';
import { getOfferWallsList } from '@/api';
import type { OfferwallPartner } from '@/types';
import {
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const isLoading = ref(false);
const partners = ref<OfferwallPartner[]>([]);

onBeforeMount(async () => {
	isLoading.value = true;
	partners.value = await getOfferWallsList();
	isLoading.value = false;
});
</script>

<template>
	<div
		class="offerwalls-partners__container"
		:class="{ 'container-full': isLoading || !partners.length }"
	>
		<PrimeLoaderSpinner v-if="isLoading" />
		<template v-else>
			<div v-if="partners.length" class="offerwalls-partners__list">
				<OfferwallsPartnerItem
					v-for="item in partners"
					:key="item.id"
					:item="item"
				/>
			</div>
			<div v-else>
				<PrimeText tag="p" weight="600" size="3xl">
					No partners found
				</PrimeText>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.offerwalls-partners__container {
	&.container-full {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
}

.offerwalls-partners__list {
	margin: 0 auto;
	display: grid;
	align-content: center;
	justify-content: center;
	grid-template-columns: repeat(2, minmax(auto, 1fr));
	max-width: 100%;
	gap: 0.75rem;

	@include breakpoint(mobileonly) {
		grid-template-columns: repeat(2, minmax(168px, 1fr));
	}

	@include breakpoint(tablet) {
		gap: 1.5rem;
		grid-template-columns: repeat(3, minmax(200px, 1fr));
	}
}
</style>
