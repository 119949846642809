<script setup lang="ts">
import {CLAIM_REWARD} from '@/locales/constants';
import { useConfirmClaimView } from '@/views/confirm-claim/composables';
import LayoutSubpage from "@/components/LayoutSubpage.vue";
import claim_reward_animation from "@/assets/lottie/claim_reward_animation.json";
import {Vue3Lottie} from "vue3-lottie";

const { isClaimAvailable, restOfTheBalanceText, component } =
	useConfirmClaimView();
</script>

<template>
	<main class="confirm-claim">
		<LayoutSubpage :title="$t(CLAIM_REWARD)">
			<Vue3Lottie
				:animationData="claim_reward_animation"
				:height="200"
				:width="200"
				:loop="false"
			/>
			<section class="container">
				<div class="wrapper">
					<component :is="component" :is-claim-available="isClaimAvailable" />
				</div>
			</section>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.confirm-claim {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 1rem 1rem;
}

.container {
	width: 100%;
	margin: 0 auto;
	height: calc(100% - 17rem);

	@include breakpoint(tablet) {
		max-width: 550px;
	}
}

.wrapper {
	display: flex;
	height: 100%;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}
</style>
