<script setup lang="ts">
import {
	ACH_FULL_NAME_CONFIRM,
	ACH_FULL_NAME_CONFIRM_PLACEHOLDER,
	CHANGE_NAME,
	CONFIRM_ACH_FULL_NAME_MESSAGE,
	CONFIRM_FULL_NAME,
	FULL_NAME,
} from '@/locales/constants';
import { PrimeInput, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { useRewardsStore } from '@/stores/rewards';
import { ref, watch } from 'vue';
import { useValidationRules } from '@/utils/composables/useValidationRules';
import useVuelidate from '@vuelidate/core';
import { useValidationUtils } from '@/utils/composables/useValidationUtils';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { CONFIRM_CLAIM_DATA_MODAL } from '@/constants/modals';
import ConfirmWrapper from '@/views/confirm-claim/components/ConfirmWrapper.vue';

export type CheckFullName = { fullName: string };

const userStore = useUserStore();
const { lastUsedAchFullName } = storeToRefs(userStore);

const rewardsStore = useRewardsStore();

const isLoading = ref(false);

const form = ref<CheckFullName>({
	fullName: lastUsedAchFullName.value,
} as CheckFullName);
const { fullName } = useValidationRules();
const validation = useVuelidate({ fullName }, form, { $autoDirty: false });

const {
	reset,
	validate,
	isValidationError,
	getValidationFieldStatus,
	getValidationFieldText,
} = useValidationUtils<CheckFullName>(validation);

const handleConfirmClaim = async () => {
	await validate();
	if (isValidationError.value) return;

	try {
		isLoading.value = true;
		const { askConfirm } = await rewardsStore.claim({
			revolut_counterparty_name: form.value.fullName,
		});
		if (askConfirm) {
			await useModalStorage().pushModal({
				name: CONFIRM_CLAIM_DATA_MODAL,
				options: {
					userData: form.value.fullName,
					dataType: FULL_NAME,
					header: CONFIRM_FULL_NAME,
					message: CONFIRM_ACH_FULL_NAME_MESSAGE,
					cancelLabel: CHANGE_NAME,
					confirmParams: {
						revolut_counterparty_name: form.value.fullName,
						force_revolut_counterparty_name: '1',
					},
				},
			});
		}
		reset();
	} finally {
		isLoading.value = false;
	}
};

watch(() => form.value.fullName, reset);
</script>

<template>
	<ConfirmWrapper
		:disabled="!form.fullName.length || isValidationError"
		:loading="isLoading"
		@confirm="handleConfirmClaim"
	>
		<template #claim-data>
			<PrimeText weight="500" size="sm">
				{{ $t(ACH_FULL_NAME_CONFIRM) }}
			</PrimeText>
			<PrimeInput
				v-model="form.fullName"
				:placeholder="$t(ACH_FULL_NAME_CONFIRM_PLACEHOLDER)"
				:invalid="!!getValidationFieldStatus('fullName')"
				:error-text="getValidationFieldText('fullName')?.toString()"
				type="email"
			/>
		</template>
	</ConfirmWrapper>
</template>
