<script setup lang="ts">
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { computed } from 'vue';
import {
	REVOLUT_ANNOUNCEMENT_DESCRIPTION,
	REVOLUT_ANNOUNCEMENT_HEADER,
	REVOLUT_ANNOUNCEMENT_HOW,
	REVOLUT_ANNOUNCEMENT_STEP_1,
	REVOLUT_ANNOUNCEMENT_STEP_2,
	REVOLUT_ANNOUNCEMENT_STEP_3,
	REVOLUT_ANNOUNCEMENT_TITLE,
} from '@/locales/constants';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const signupSteps: Record<number, string> = {
	1: REVOLUT_ANNOUNCEMENT_STEP_1,
	2: REVOLUT_ANNOUNCEMENT_STEP_2,
	3: REVOLUT_ANNOUNCEMENT_STEP_3,
};

defineEmits<{
	'close-modal': [];
}>();

const { userId } = storeToRefs(useUserStore());

const revolutSignupUrl = computed(
	() => `${import.meta.env.VITE_REVOLUT_URL}${userId.value}`
);

const revolutImageUrl = computed(
	() => `${import.meta.env.VITE_CDN_DOMAIN}/images/revolut_primary.svg`
);
</script>

<template>
	<div id="revolut-announcement-modal">
		<ModalCommonContent>
			<template #header>{{ $t(REVOLUT_ANNOUNCEMENT_HEADER) }}</template>
			<template #icon>
				<img :src="revolutImageUrl" alt="Revolut logo" />
			</template>
			<template #title>
				<PrimeText weight="600">
					{{ $t(REVOLUT_ANNOUNCEMENT_TITLE) }} 🚀
				</PrimeText>
			</template>
			<template #description>
				<PrimeText
					weight="500"
					size="sm"
					color="grey-700"
					v-html="$t(REVOLUT_ANNOUNCEMENT_DESCRIPTION)"
				/>
			</template>
			<template #message>
				<div class="revolut-signup">
					<PrimeText weight="500" size="sm" color="grey-700">
						{{ $t(REVOLUT_ANNOUNCEMENT_HOW) }}
					</PrimeText>
					<ol class="signup_steps__list">
						<li v-for="i in 3" :key="i" class="signup_step">
							<PrimeText weight="500" size="sm">
								{{ $t(signupSteps[i]) }}
							</PrimeText>
						</li>
					</ol>
				</div>
			</template>
			<template #actions>
				<a :href="revolutSignupUrl" target="_blank">
					<PrimeButton
						class="revolut-signup__button"
						full-width
						:label="'Join Revolut'"
						@click="$emit('close-modal')"
					/>
				</a>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss">
#revolut-announcement-modal {
	& .content-icon {
		height: auto;
		width: 6.25rem;
	}

	& .content-title {
		margin-bottom: 0;
	}

	& .content-description .revolut-bold {
		color: var(--text-default);
		font-weight: 600;
	}

	& .content-message {
		align-self: flex-start;
		max-width: none;
	}

	& .revolut-signup {
		color: var(--text-default);
		text-align: left;

		& ol {
			margin-top: 0.5rem;
			padding: 0 1rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			width: 100%;
		}

		& li {
			padding: 0 0.25rem;
		}
	}

	& .revolut-signup__button {
		background: #1a1c1f;
	}
}
</style>
