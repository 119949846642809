<script lang="ts" setup>
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useAuthSocialView } from '@/views/auth-social/composables';
import { onMounted } from "vue";

const {initSocialAuth} = useAuthSocialView();

onMounted(() => {
	initSocialAuth()
})
</script>

<template>
	<div class="auth-social-page">
		<div class="page-content">
			<PrimeLoaderSpinner />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.auth-social-page {
	display: flex;
	flex-direction: column;
	max-width: 73.125rem;
	width: 100%;
	margin: 0 1.1875rem;
	justify-content: center;

	& .page-content {
		flex: 1 0 auto;
		display: flex;
		align-items: center;
	}
}
</style>
