<script setup lang="ts">
import { type SubCategoryContent } from '@/views/surveys/components/user-dashboard/rewards/sub-categories';
import RewardSubCategory from '@/views/surveys/components/user-dashboard/rewards/RewardSubCategory.vue';

defineProps<{
	subCategories: Record<string, SubCategoryContent>;
}>();

const selectedSubCategory = defineModel<string>('selectedSubCategory', {
	required: true,
});

const sortByCategoryId = (
	[indexA]: [string, SubCategoryContent],
	[indexB]: [string, SubCategoryContent]
) => +indexA - +indexB;
</script>

<template>
	<div class="sub-categories__container">
		<RewardSubCategory
			v-for="[index, content] in Object.entries(subCategories).sort(
				sortByCategoryId
			)"
			:id="index"
			:key="index"
			v-model="selectedSubCategory"
			:icon="content.icon"
			:label="content.label"
			:selected="selectedSubCategory === index"
		/>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.sub-categories__container {
	display: flex;
	gap: 0.5rem;
	overflow-x: scroll;
	flex-wrap: nowrap;
	margin-bottom: 0.5rem;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	@include breakpoint(mobile) {
		overflow-x: visible;
		flex-wrap: wrap;
	}
}
</style>
