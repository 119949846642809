<script setup lang="ts">
import { notify } from '@/components/common/notifications';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	REF_SHARE_BUTTON,
} from '@/locales/constants';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiHcCopy } from '@primeinsightsgroupllc/prime-icons';

const { isMobile } = useScreenSize();
const userStore = useUserStore();
const { refLink } = storeToRefs(userStore);

const copyToClipboard = () => {
	navigator.clipboard.writeText(refLink.value);
	notify({
		body: refLink.value,
	});
};
</script>

<template>
	<section class="referrals-share">
		<PrimeText
			tag="div"
			size="sm"
			color="inherit"
			class="referrals-share--link"
		>
			{{ refLink }}
		</PrimeText>
		<div class="referrals-share--copy-button" @click="copyToClipboard">
			<PrimeText color="inherit">{{ $t(REF_SHARE_BUTTON) }}</PrimeText>
			<PiHcCopy />
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals-share {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	gap: 0.875rem;
	text-align: center;
	&--copy-button {
		background-color: white;
		border-radius: 0.5rem;
		border: 1px solid #252525;
		cursor: pointer;
		display: flex;
		height: 2.125rem;
		padding: 0.375rem 0.875rem 0.375rem 0.75rem;
		justify-content: center;
		align-items: center;
		width: max-content;
		color: #000;
		gap: 0.25rem;
	}
}
</style>
