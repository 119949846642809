import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import type { Component, ComputedRef, Ref } from 'vue';
import { computed, onBeforeMount, ref } from 'vue';
import type { ProfileStats } from '@/types';
import { getProfile } from '@/api';
import { ProfileNavigationTypes } from '@/enums';
import {
	PiHcPoints,
	PiHcStreak,
	PiHcSurveys,
	PiHcGift,
} from '@primeinsightsgroupllc/prime-icons';
import {
	HIGHEST_RANK,
	LEADERBOARD_LOCKED,
	LEADERBOARD_UNLOCK_TARGET,
	LEADERBOARD_UNLOCKED_MESSAGE,
	NONE,
	PROFILE_LEADERBOARD_RECORD,
	PROFILE_LONGEST_STREAK,
	PROFILE_LONGEST_STREAK_DAYS,
	PROFILE_POINTS_EARNED,
	PROFILE_POINTS_RATING,
	PROFILE_REWARDS_REDEEMED,
	PROFILE_SURVEYS_COMPLETED,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { DAILY_STREAK_MODAL } from '@/constants/modals';
import { USER_ROUTE_NAME } from '@/constants/routes';

type ProfileNavigationItem = {
	type: ProfileNavigationTypes;
	icon: Component;
	color?: string;
	gradient?: boolean;
	title: string;
	details: string;
	route?: { name: string; params?: Record<string, string> };
	handler?: () => void;
	visible: boolean;
};

export const useProfileView = (): {
	profileConfig: ComputedRef<ProfileNavigationItem[]>;
	isProfileStatsLoaded: ComputedRef<boolean>;
	isLoading: Ref<boolean>;
} => {
	const { leaderboard, username } = storeToRefs(useUserStore());
	const { t } = useI18n();

	const profileStats = ref<ProfileStats | null>(null);
	const isLoading = ref(true);

	const modalStorage = useModalStorage();

	const openDayStreakModal = () => {
		modalStorage.pushModal({ name: DAILY_STREAK_MODAL });
	};

	onBeforeMount(async () => {
		const data = await getProfile();
		if (data) {
			profileStats.value = data?.stats;
			isLoading.value = false;
		}
	});

	const isProfileStatsLoaded = computed(() => !!profileStats.value);

	const leaderboardData = computed<{ title: string; details: string }>(() => {
		const surveysLeft =
			leaderboard.value.total_points_required_to_unlock -
			leaderboard.value.successful_earned_point_toward_leaderboard;

		const lockedLeaderboardDetails =
			surveysLeft > 0
				? t(LEADERBOARD_UNLOCK_TARGET, {
						value: surveysLeft,
					})
				: !username.value
					? t(LEADERBOARD_UNLOCKED_MESSAGE)
					: '';

		return leaderboard.value.unlocked
			? profileStats.value?.leaderboard.record
				? {
						title: profileStats.value.leaderboard.record.toString() || '',
						details: t(HIGHEST_RANK),
					}
				: {
						title: t(NONE),
						details: t(PROFILE_LEADERBOARD_RECORD),
					}
			: {
					title: t(LEADERBOARD_LOCKED),
					details: lockedLeaderboardDetails,
				};
	});

	const profileConfig = computed<ProfileNavigationItem[]>(() => [
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiHcPoints,
			gradient: false,
			title: localizePoints(profileStats.value?.points?.total || 0),
			details: profileStats.value?.points?.rating
				? t(PROFILE_POINTS_RATING, { value: profileStats.value.points.rating })
				: t(PROFILE_POINTS_EARNED),
			route: { name: USER_ROUTE_NAME.TRANSACTIONS_HISTORY },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiHcSurveys,
			gradient: false,
			title: String(profileStats.value?.surveys?.completed),
			details: t(PROFILE_SURVEYS_COMPLETED),
			route: { name: USER_ROUTE_NAME.RECENT_ACTIVITY },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiHcGift,
			gradient: false,
			title: localizePoints(profileStats.value?.reward?.total || 0),
			details: t(PROFILE_REWARDS_REDEEMED),
			route: { name: USER_ROUTE_NAME.REWARD_HISTORY },
			visible: true,
		},
		{
			type: ProfileNavigationTypes.DEFAULT,
			icon: PiHcStreak,
			gradient: false,
			title: t(PROFILE_LONGEST_STREAK_DAYS, {
				value: profileStats.value?.streaks?.longest,
			}),
			details: t(PROFILE_LONGEST_STREAK),
			handler: () => openDayStreakModal(),
			visible: true,
		},
	]);

	return {
		profileConfig,
		isProfileStatsLoaded,
		isLoading,
	};
};
