<script lang="ts" setup>
import {
	GREAT_NEWS,
	WEEKLY_PRIZE,
	LEADERBOARD_WEEKLY_NOTICE,
	SOUNDS_GREAT,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PiTrophyDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const emits = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emits('close-modal');
};
</script>

<template>
	<div class="leaderboard-weekly-modal">
		<ModalCommonContent>
			<template #header>{{ $t(GREAT_NEWS) }}</template>
			<template #icon>
				<PiTrophyDuotoneThin color="base-primary" size="100%" />
			</template>
			<template #description>
				<PrimeText tag="div">
					<span v-html="$t(LEADERBOARD_WEEKLY_NOTICE)" />
				</PrimeText>
			</template>
			<template #message>
				<RouterLink to="leaderboard" @click="handleCloseModal">
					<PrimeText color="primary" size="sm" weight="600" class="link-text">
						{{ $t(WEEKLY_PRIZE) }}
					</PrimeText>
				</RouterLink>
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(SOUNDS_GREAT)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.leaderboard-weekly-modal {
	:deep(.modal-common-content) {
		.content-title {
			margin-bottom: 0.75rem;
		}

		.content-icon {
			margin-bottom: 0.75rem;
		}

		.content-description {
			margin-bottom: 1.375rem;
			padding-bottom: 1.375rem;

			span > span {
				font-weight: 700;
				font-size: 1.375rem;
				line-height: 1;
			}
		}

		.content-message {
			.link-text {
				&:hover {
					text-decoration: underline;
				}
			}
		}

		.content-actions {
			margin-top: 1.375rem;
		}
	}
}
</style>
