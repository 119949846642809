<script setup lang="ts">
import { getCountdown, localizePoints } from '@/utils/helpers';
import {
	COINS,
	LEADERBOARD_CLOSE_IN,
	LEADERBOARD_ENDED,
	LEADERBOARD_START_IN,
	LEADERBOARD_WEEKLY,
	PROFILE_POINTS_EARNED,
	RATING_DESCRIPTION_WEEKLY,
	TOTAL_PRIZES,
} from '@/locales/constants';
import PrizePool from '@/views/leaderboard/components/headers/PrizePool.vue';
import type { LeaderboardInfo } from '@/types';
import { onMounted, ref } from 'vue';
import { getCurrentLocale } from '@/i18n';
import {
	PrimeDivider,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import color_icon_mock from '@/assets/money-coin-green.svg';

defineProps<{
	info: LeaderboardInfo | undefined;
	nextStart: string;
	nextName: string;
	isActive: boolean;
}>();

const { isMobile } = useScreenSize();
const { username } = storeToRefs(useUserStore());

const dateLocale = ref<any>();

onMounted(() => {
	import('date-fns/locale').then((module) => {
		dateLocale.value = module[getCurrentLocale()];
	});
});
</script>

<template>
	<section class="leaderboard-header">
		<div class="leaderboard-header__default">
			<PrimeText tag="p" color="inherit" weight="500" size="2xl">
				{{ info?.name || $t(LEADERBOARD_WEEKLY) }}
			</PrimeText>
			<div class="leaderboard-header--description">
				<span>
					<PrizePool :info="info!" />
				</span>
				<PrimeText
					tag="p"
					color="grey-700"
					size="sm"
					weight="400"
					class="leaderboard-header--rating"
				>
					{{ info?.leaderboard_description || $t(RATING_DESCRIPTION_WEEKLY) }}
				</PrimeText>
			</div>
		</div>
		<div class="leaderboard-header--statistics">
			<div v-if="!isActive" class="leaderboard-header--statistics-item">
				<PrimeText tag="div" color="grey-700" weight="400" size="sm">
					{{ $t(LEADERBOARD_ENDED) }}
				</PrimeText>
			</div>

			<div
				v-if="isActive && getCountdown(info?.end || '', dateLocale)"
				class="leaderboard-header--statistics-item"
			>
				<PrimeText color="grey-700" weight="400" :size="isMobile ? 'xs' : 'sm'">
					{{ $t(LEADERBOARD_CLOSE_IN, { name: info?.name }) }}:
				</PrimeText>
				<PrimeText color="inherit" weight="400" :size="isMobile ? 'xs' : 'sm'">
					{{ getCountdown(info?.end || '', dateLocale) }}
				</PrimeText>
			</div>
			<div
				v-else-if="
					!isActive &&
					nextStart &&
					nextName &&
					getCountdown(nextStart, dateLocale)
				"
				class="leaderboard-header--statistics-item"
			>
				<PrimeText color="grey-700" weight="400" :size="isMobile ? 'xs' : 'sm'">
					{{ $t(LEADERBOARD_START_IN, { name: nextName }) }}:
				</PrimeText>
				<PrimeText weight="400" :size="isMobile ? 'xs' : 'sm'" color="inherit">
					{{ getCountdown(nextStart, dateLocale) }}
				</PrimeText>
			</div>
			<PrimeDivider top-gap="0.75rem" bottom-gap="0.75rem" color="grey-400" />
			<div class="leaderboard-header--statistics-item">
				<div>
					<div>
						<PrimeText v-if="info?.current_user_rank" size="base" weight="500"
							>{{ info?.current_user_rank }}.
						</PrimeText>
						<PrimeText size="base" weight="500" color="inherit">{{
							username
						}}</PrimeText>
					</div>
					<div>
						<PrimeText size="base" weight="500"
							>{{ $t(PROFILE_POINTS_EARNED) }}:&nbsp;</PrimeText
						>
						<PrimeText
							v-if="info?.current_user_earnings"
							size="base"
							weight="500"
							color="inherit"
						>
							{{ info.current_user_earnings }}
						</PrimeText>
					</div>
				</div>
				<div class="leaderboard-header--statistics-points">
					<img :src="color_icon_mock" alt="" />
					<div>
						<PrimeText tag="div" size="lg" weight="600" color="inherit">
							{{ localizePoints(info?.current_user_prize || 0) }}
							{{ $t(COINS) }}
						</PrimeText>
						<PrimeText size="sm" weight="400">
							{{ $t(TOTAL_PRIZES) }}
						</PrimeText>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.leaderboard-header {
	margin-bottom: 1.375rem;
	@include breakpoint(tablet) {
		margin-bottom: 2.25rem;
	}

	&__default {
		color: white;
		display: block;
		margin-bottom: 1rem;

		@include breakpoint(tablet) {
			margin-bottom: 1.5rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}

	&--rating {
		margin-top: 0.25rem;
	}

	&--description {
		margin-top: 1.25rem;
		max-width: 347px;

		@include breakpoint(tablet) {
			margin-top: 0;
		}
	}

	&--statistics {
		padding: 0.875rem 1rem;
		border-radius: 1.5rem;
		border: 1px solid var(--p-grey-300);
		background: var(--base-primary-03);

		&-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			color: white;
		}

		&-points {
			display: flex;

			img {
				margin-right: 0.5rem;
			}
		}
	}
}
</style>
