<script setup lang="ts">
import {
	REFERRALS_ANNOUNCEMENT_MODAL_BUTTON,
	REFERRALS_ANNOUNCEMENT_MODAL_DESCRIPTION,
	REFERRALS_ANNOUNCEMENT_MODAL_HEADER,
	REFERRALS_ANNOUNCEMENT_MODAL_TITLE,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { PiMegaphoneDuotoneThin } from '@primeinsightsgroupllc/prime-icons';
import { useRouter } from 'vue-router';

const router = useRouter();

const emits = defineEmits<{
	'close-modal': [];
}>();

const handleClick = () => {
	router.push('/referrals');
	emits('close-modal');
};
</script>

<template>
	<div id="referrals-announcement-modal">
		<ModalCommonContent>
			<template #header>{{ $t(REFERRALS_ANNOUNCEMENT_MODAL_HEADER) }}</template>
			<template #icon>
				<PiMegaphoneDuotoneThin color="base-primary" />
			</template>
			<template #title>
				{{ $t(REFERRALS_ANNOUNCEMENT_MODAL_TITLE) }} 🚀
			</template>
			<template #message>
				<PrimeText
					tag="p"
					size="sm"
					weight="500"
					color="grey-700"
					v-html="$t(REFERRALS_ANNOUNCEMENT_MODAL_DESCRIPTION)"
				/>
			</template>
			<template #actions>
				<PrimeButton
					full-width
					type="gradient"
					:label="$t(REFERRALS_ANNOUNCEMENT_MODAL_BUTTON)"
					@click="handleClick"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
#referrals-announcement-modal {
	:deep(.modal-common-content) {
		& .content-title,
		& .content-message {
			max-width: none;
		}
	}
}
</style>
