<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import MainHeader from '@/components/common/header/MainHeader.vue';

const route = useRoute();

const isLoginPage = computed(() => {
	return route.name === 'app-login' || route.name === 'home';
});

const authStepsPages = computed(() => {
	return (
		route.name && route.meta.withoutHeader
	);
});
</script>

<template>
	<MainHeader v-if="!isLoginPage && !authStepsPages" />
</template>
