<script lang="ts" setup>
import prizeDraw from '@/assets/prize-draw.png';
import {
	PRIZE_DRAW,
	PRIZE_DRAW_DESCRIPTION,
	WIN_UP_POINTS,
	GUARANTEED_PRIZE,
	GOTCHA,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { localizePoints } from '@/utils/helpers';
import {
	PiCheckSeal,
	PiCoins,
	PiGiftDuotoneThin,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const { maxPrize } = storeToRefs(useUserStore());

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const handleCloseModal = () => {
	emit('close-modal');
};
</script>

<template>
	<div class="draw-prize-modal">
		<ModalCommonContent>
			<template #header>{{ $t(PRIZE_DRAW) }}</template>
			<template #icon>
				<PiGiftDuotoneThin size="100%" color="base-secondary" />
			</template>
			<template #title>{{ $t(PRIZE_DRAW_DESCRIPTION) }}</template>
		</ModalCommonContent>
		<ul class="draw-prize-modal-list">
			<PrimeText tag="li" size="sm" weight="500" class="draw-prize-modal-item">
				<PiCoins size="1.375rem" />
				{{ $t(WIN_UP_POINTS, { amount: localizePoints(maxPrize) }) }}
			</PrimeText>
			<PrimeText tag="li" size="sm" weight="500" class="draw-prize-modal-item">
				<PiCheckSeal size="1.375rem" />
				{{ $t(GUARANTEED_PRIZE) }}
			</PrimeText>
		</ul>
		<img
			class="draw-prize-modal-image"
			:src="prizeDraw"
			:alt="$t(PRIZE_DRAW)"
		/>
		<ModalCommonContent>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(GOTCHA)"
					@click="handleCloseModal"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>

<style lang="scss" scoped>
.draw-prize-modal {
	:deep(.content-title) {
		margin-bottom: 0.875rem;
		max-width: 100%;
	}
}

.draw-prize-modal-list {
	margin: 0 0 1.375rem;
}

.draw-prize-modal-item {
	display: flex;
	align-items: center;
	gap: 0.25rem;
	margin-bottom: 0.25rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.draw-prize-modal-image {
	display: inline-block;
	vertical-align: top;
	width: 100%;
}
</style>
