<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useRouter } from 'vue-router';
import { computed } from 'vue';
import { getLocalTime, getMonthTerm } from '@/utils/date';
import { useDev } from '@/utils/composables/useDev';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import {DAILY_STREAK_MODAL} from '@/constants/modals';
import money_coin_yellow from '../../../assets/money-coin-yellow.svg';
import { PrimeProgressbar, PrimeText } from "@primeinsightsgroupllc-ui/prime-ui-kit";
import {DAY_STREAK, LEVEL, POINTS, NEXT_LVL_REWARD} from "@/locales/constants";
import {localizePoints} from "@/utils/helpers";
import UserBalance from "@/components/common/header/UserBalance.vue";
import {useScreenSize} from "@/utils/composables/useScreenSize";

const {
	username,
	email,
	countryCode,
	countryName,
	timezone,
	createdAt,
	isBonusDayUnlocked,
	bonusDayBonus,
	levelActual,
	levelNext,
	levelReward,
	coinsEarned,
	coinsTarget,
	collectedCoins,
	streaks,
} = storeToRefs(useUserStore());

const { isDev } = useDev();
const { isMobile } = useScreenSize();
const router = useRouter();
const modalStorage = useModalStorage();

const userCreatedAtMonth = computed((): string => {
	const date = new Date(createdAt.value);

	return getMonthTerm(date.getMonth());
});

const userCreatedAtYear = computed((): number => {
	return new Date(createdAt.value).getFullYear();
});

const localTime = computed<string>((): string => {
	if (timezone.value) {
		const { hours, minutes } = getLocalTime(timezone.value);
		return `${hours}:${minutes}`;
	}
	return '';
});

const { pushModal } = useModalStorage();

const openDayStreakModal = () => {
	modalStorage.pushModal({ name: DAILY_STREAK_MODAL });
}
</script>

<template>
	<header class="profile-widget">
		<div class="profile-widget-info">
			<PrimeText size="xs" weight="400" color="grey-700" transform="capitalize">
				{{ $t(NEXT_LVL_REWARD) }}:
			</PrimeText>
			<PrimeText size="xs" weight="400" color="inherit">
				{{ levelReward }} {{ $t(POINTS) }}
			</PrimeText>
		</div>
		<div class="profile-widget-progress">
			<div class="profile-widget-stats">
				<div class="profile-widget-level">
					<PrimeText size="xs" weight="400">
						{{ levelActual }} {{ $t(LEVEL) }}
					</PrimeText>
				</div>
				<PrimeText size="xs" weight="400">
					{{ localizePoints(coinsEarned) }}/{{ localizePoints(coinsTarget) }} {{ $t(POINTS) }}
				</PrimeText>
			</div>
			<PrimeProgressbar value="10"/>
		</div>
		<div class="profile-widget-rewards">
			<UserBalance />
			<div class="profile-widget-points" @click="openDayStreakModal">
				<img :src="money_coin_yellow" alt="">
				<PrimeText :size="isMobile ? 'base' : 'lg'" weight="600" color="inherit">
					{{ streaks }}
				</PrimeText>
				<PrimeText size="sm" weight="400">
					{{ $t(DAY_STREAK) }}
				</PrimeText>
			</div>
		</div>
		<slot name="action"></slot>
	</header>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';
.profile-widget {
	border: 1px solid var(--grey-200);
	padding: 1rem;
	margin-bottom: 0.75rem;
	border-radius: 1.5rem;
	width: 100%;
	background: var(--base-primary-03);
	color: white;

	&-info {
		border-radius: 50px;
		background: var(--grey-cool-400-inputs-bg, #24262B);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.25rem 0;
		gap: 0.25rem;
	}

	&-stats {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}

	&-progress {
		margin-bottom: 0.75rem;
		margin-top: 0.75rem;
		width: 100%;

		.p-progress {
			border-radius: 3.125rem;
			background: var(--p-grey-300);
		}

		.p-progress-bar {
			border-radius: 3.125rem;
			background: linear-gradient(270deg, #FF6B34 0%, rgba(255, 107, 52, 0.00) 100%) !important;
		}
	}

	&-rewards {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.75rem;

		@include breakpoint(mobile) {
			justify-content: space-evenly;
		}
	}

	&-points {
		user-select: none;
		display: flex;
		align-items: center;
		color: white;
		gap: 0.25rem;
		border-radius: 8px;
		border: 1px solid transparent;
		transition: all .1s linear;
		position: relative;
		padding: 0;
		margin-right: 0.5rem;
		white-space: nowrap;

		img {
			margin-right: 0;

			@include breakpoint(mobileonly) {
				margin-right: 0.625rem;
			}
		}

		@include breakpoint(mobileonly) {
			margin-right: 1.3125rem;
			padding: 6px;
		}

		&:hover, &.active {
			border: 1px solid #686868;
		}
	}
}
</style>
