<script setup lang="ts">
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	BONUS_WARNING_MESSAGE,
	CONGRATULATIONS,
	UNABLE_CLAIM,
	WELCOME_BONUS,
	YOU_CAN_CLAIM,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

defineProps<{
	isClaimAvailable: boolean;
}>();

const { minWelcomeBonusClaimBalance } = storeToRefs(useUserStore());
</script>

<template>
	<PrimeText tag="h2" weight="600" size="xl" color="inherit" class="title">
		{{ isClaimAvailable ? $t(CONGRATULATIONS) : $t(UNABLE_CLAIM) }}
	</PrimeText>
	<PrimeText
		v-if="isClaimAvailable"
		tag="p"
		weight="500"
		size="sm"
		color="grey-700"
		class="text subtitle"
	>
		{{ $t(YOU_CAN_CLAIM) }}
	</PrimeText>
	<PrimeText v-else tag="p" class="text bonus-notifications">
		<PrimeText tag="span" weight="500" size="sm">
			{{
				$t(BONUS_WARNING_MESSAGE, {
					coins: minWelcomeBonusClaimBalance
						? localizePoints(minWelcomeBonusClaimBalance)
						: '',
					bonus: $t(WELCOME_BONUS),
				})
			}}
		</PrimeText>
	</PrimeText>
</template>

<style scoped lang="scss">
.title {
	margin: 0.25rem 0;
}

.text {
	text-align: center;

	&.subtitle {
		margin-bottom: 1.375rem;
	}

	&.bonus-notifications {
		margin-bottom: 1.375rem;
	}
}
</style>
