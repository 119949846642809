<script setup lang="ts">
import { BONUS_INFORMATION_MESSAGE } from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { LeaderboardInfo } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

defineProps<{
	info: LeaderboardInfo;
}>();

const { currency } = storeToRefs(useUserStore());
</script>

<template>
	<div class="prize-pool" v-if="info?.name">
		<PrimeText tag="div" size="base" weight="500" color="primary" class="prize-pool--label">
			{{ info?.name }}:&nbsp;
		</PrimeText>
		<PrimeText tag="div" size="base" weight="500" color="primary" class="prize-pool--label">
			{{
				$t(BONUS_INFORMATION_MESSAGE, {
					coins: localizePoints(info?.prize_pool_coins || 0),
					money: transformCurrency(
						info?.prize_pool_in_user_currency || 0,
						currency || '',
						2
					),
				})
			}}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prize-pool {
	max-width: 22rem;
	white-space: nowrap;

	@include breakpoint(tablet) {
		max-width: 100%;
		margin-top: 1.5rem;
		display: flex;
	}
}
</style>
