<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import UserDashboard from '@/views/surveys/components/user-dashboard/UserDashboard.vue';
import SurveysFallback from '@/views/surveys/components/SurveysFallback.vue';
import {
	PrimeButton,
	PrimeLoaderSpinner,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useAuthStore } from '@/stores/auth';
import { useSurveysView } from '@/views/surveys/composables';
import ProfileHeader from '@/views/profile/components/ProfileHeader.vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { PiGearSixDuotone } from '@primeinsightsgroupllc/prime-icons';
import { REWARDS_MODAL } from '@/constants/modals';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { CLAIM_REWARD } from '@/locales/constants';

const authStore = useAuthStore();
const { isAuthorized } = storeToRefs(authStore);
const { isTablet } = useScreenSize();
const userStore = useUserStore();
const { integrationScriptLoading } = useSurveysView();
const modalStorage = useModalStorage();

const openRewardsModal = () => {
	modalStorage.pushModal({
		name: REWARDS_MODAL,
		options: { useDefaultClose: false, position: 'bottom' },
	});
};
</script>

<template>
	<main v-if="!userStore.error" class="surveys__container">
		<!--		<section v-if="isAuthorized" class="rewards-section">-->
		<!--			<UserDashboard />-->
		<!--		</section>-->
		<div v-if="isTablet" class="surveys__header">
			<ProfileHeader>
				<template #action>
					<PrimeButton
						rounded="rounded"
						variant="primary"
						size="small"
						:label="$t(CLAIM_REWARD)"
						full-width
						@click="openRewardsModal"
					>
					</PrimeButton>
				</template>
			</ProfileHeader>
		</div>
		<PrimeLoaderSpinner v-if="integrationScriptLoading" />
		<div
			v-if="isAuthorized"
			id="prime-survey"
			:class="{ 'prime-survey-application': !integrationScriptLoading }"
		/>
	</main>
	<SurveysFallback v-else />
	<RouterView />
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.surveys__container {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: var(--white);
}

.surveys__header {
	padding: 0.675rem;
}

.prime-survey-application {
	flex: 1 1 auto;
}

.rewards-section {
	width: 100%;
	padding: 0.875rem 1.125rem 0.375rem;
	@include breakpoint(mobile) {
		padding: 1.5rem 1.5rem 0.375rem;
	}
}
</style>
