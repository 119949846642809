<script setup lang="ts">
import { useRouter } from 'vue-router';
import {GO_BACK, OPEN_BOX, PRIZE_DRAW} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import PrizeDrawHeader from '@/views/prize-draw/components/PrizeDrawHeader.vue';
import PrizeDrawBox from '@/views/prize-draw/components/PrizeDrawBox.vue';
import { usePrizeDrawView } from '@/views/prize-draw/composables';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { StreakPrizes } from '@/enums';
import LayoutSubpage from "@/components/LayoutSubpage.vue";

const router = useRouter();
const {
	showWinMessage,
	boxes,
	hiddenBoxes,
	selectedBoxPosition,
	disabledBoxes,
	openBox,
	isLoading,
	prize,
	handleSelectBox,
	boxOpened,
} = usePrizeDrawView();

const { prizes } = storeToRefs(useUserStore());
</script>

<template>
	<main class="prize-draw">
		<LayoutSubpage :title="$t(PRIZE_DRAW)">
			<PrizeDrawHeader
				:show-win-message="showWinMessage"
				:type="prize.is_code"
				:prize="
				prize.is_code === StreakPrizes.BONUS
					? `${prize.percentage}%`
					: prize.coin_value
			"
			/>
			<div class="prize-draw-container">
				<section class="prize-draw--fields-wrapper">
					<div class="prize-draw--fields">
						<PrizeDrawBox
							v-for="({ id, coin_value, is_code, percentage }, index) in boxes"
							:key="id"
							:hidden="hiddenBoxes && selectedBoxPosition !== index"
							:disabled="
						(disabledBoxes && selectedBoxPosition !== index) ||
						boxOpened ||
						!prizes
					"
							:selected="selectedBoxPosition === index"
							:content="
						disabledBoxes
							? is_code === StreakPrizes.BONUS
								? `${percentage}%`
								: localizePoints(coin_value || 0)
							: '?'
					"
							@click="handleSelectBox(index)"
						/>
					</div>
				</section>
				<div class="buttons-wrapper">
					<PrimeButton
						v-if="prizes && !showWinMessage"
						full-width
						:label="$t(OPEN_BOX)"
						:disabled="selectedBoxPosition === undefined"
						:loading="isLoading"
						@click="openBox"
					/>
					<PrimeButton
						v-else
						full-width
						:label="$t(GO_BACK)"
						@click="router.push({ name: 'surveys' })"
					/>
				</div>
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prize-draw-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 9rem);
}

.prize-draw {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 0 1rem 1rem;

	@include breakpoint(mobile) {
		max-width: 100%;
	}
}

.buttons-wrapper {
	max-width: 37.5rem;
	margin: 0 auto;
	width: 100%;
	align-self: center;

	@include breakpoint(tablet) {
		margin-bottom: 0;
	}
}

.prize-draw--fields-wrapper {
	flex-grow: 1;
	align-self: center;
	max-width: 100%;
	width: 100%;

	@include breakpoint(mobile) {
		max-width: 100%;
	}
}

.prize-draw--fields {
	grid-gap: 0.625rem;
	padding: 0.625rem 0;
	display: grid;
	grid-template-columns: auto auto auto auto;
	align-self: stretch;

	@include breakpoint(tablet) {
		grid-gap: 0.75rem;
		grid-template-columns: auto auto auto auto auto auto;
		padding: 1.5rem 0;
	}
}
</style>
