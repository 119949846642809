<script lang="ts" setup>
import { ref, watch } from 'vue';
import fallbackImage from '../../assets/icons/gift-duotone-thin.svg';

const { src, transparent = false } = defineProps<{
	src: string | null;
	alt: string;
	transparent?: boolean;
}>();

const logoUrl = ref(fallbackImage);

watch(
	() => src,
	(value) => {
		if (value) {
			logoUrl.value = value;
		}
	},
	{ immediate: true }
);
</script>

<template>
	<img
		class="reward-icon"
		:class="{ transparent }"
		:src="logoUrl"
		:alt="alt"
		@error="logoUrl = fallbackImage"
	/>
</template>

<style lang="scss" scoped>
.reward-icon {
	width: 2.75rem;
	height: 1.75rem;
	flex: 0 0 auto;
	object-fit: contain;
	background-color: white;
	border-radius: 0.5rem;

	&.transparent {
		background-color: transparent;
	}
}
</style>
