<script setup lang="ts">
import { CONTINUE, EMAIL, SIGN_UP, SIGN_UP_SOCIAL } from '@/locales/constants';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import AuthPageContent from '@/components/auth/AuthPageContent.vue';
import AcceptPolicy from '@/components/auth/AcceptPolicy.vue';
import { useSignupSocialView } from '@/views/signup-social/composables';
import SocialProviderIcon from '@/components/auth/SocialProviderIcon.vue';
import {
	PrimeButton,
	PrimeInput,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';

const {
	handleSubmitForm,
	userSocialAuthData,
	form,
	isLoading,
	getValidationFieldStatus,
	getValidationFieldText,
} = useSignupSocialView();
</script>

<template>
	<AuthPageContent :title="$t(SIGN_UP)">
		<form class="social-register-form" @submit.prevent="handleSubmitForm">
			<div v-if="userSocialAuthData" class="form-title">
				<div class="social-label">
					<SocialProviderIcon :name="userSocialAuthData?.social_auth_provider" />
					<PrimeText size="sm" weight="400" color="inherit">
						{{ $t(SIGN_UP_SOCIAL[userSocialAuthData?.social_auth_provider]) }}
					</PrimeText>
				</div>
			</div>
			<PrimeInput
				v-model="form.email"
				:disabled="!!userSocialAuthData?.email"
				:placeholder="$t(EMAIL)"
				:invalid="!!getValidationFieldStatus('email')"
				:error-text="getValidationFieldText('email')?.toString()"
				autocomplete="username"
				type="email"
			/>

			<AcceptPolicy ref="acceptPolicyEl" />

			<PrimeButton
				role="submit"
				class="submit-register-button"
				full-width
				:label="$t(CONTINUE)"
			/>
		</form>
	</AuthPageContent>
	<PrimeLoader v-if="isLoading" />
</template>

<style lang="scss" scoped>
.social-register-form {
	display: flex;
	flex-direction: column;

	& .form-title {
		display: flex;
		gap: 0.375rem;
		align-items: center;
		margin-bottom: 0.75rem;

		.social-label {
			display: flex;
			padding: 0.875rem 0.75rem;
			justify-content: center;
			align-items: center;
			gap: 0.75rem;
			width: 100%;
			margin-bottom: 0.65rem;
			align-self: stretch;
			border-radius: 1.5rem;
			border: 1px solid rgba(255, 255, 255, 0.07);
			background-color: var(--base-primary-03);
			color: white;
		}
	}

	& .submit-register-button {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
</style>
