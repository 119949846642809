<script setup lang="ts">
import { type Component, onBeforeMount, shallowRef } from 'vue';
import { getReferralStatistic } from '@/api';
import { ref } from 'vue';
import {
	STATISTICS,
	REF_STATS_EARNED_COINS,
	REF_STATS_REGISTERED_FRIENDS,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import {
	PiHcPoints,
	PiHcGroup,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';

type ReferralStatistic = {
	icon: Component;
	value: number | string;
	text: string;
};

const { isMobile } = useScreenSize();

const stats = ref<Record<string, ReferralStatistic>>({
	earned_coins: {
		icon: shallowRef(PiHcPoints),
		value: 0,
		text: REF_STATS_EARNED_COINS,
	},
	registered_friends: {
		icon: shallowRef(PiHcGroup),
		value: 0,
		text: REF_STATS_REGISTERED_FRIENDS,
	},
});

onBeforeMount(async () => {
	const { earned_coins, registered_friends, cashed_out_friends } =
		await getReferralStatistic();

	stats.value.earned_coins.value = localizePoints(earned_coins || 0);
	stats.value.registered_friends.value = registered_friends || 0;
	stats.value.cashed_out_friends.value = cashed_out_friends || 0;
});
</script>

<template>
	<section class="referrals-stats">
		<div class="referrals-stats--items">
			<div v-for="stat in stats" :key="stat.text" class="referrals-stats--item">
				<component
					:is="stat.icon"
					color="inherit"
					size="1.875rem"
					class="referrals-stats--item-icon"
				/>
				<div class="referrals-stats--item-content">
					<PrimeText
						tag="p"
						size="lg"
						weight="600"
						color="inherit"
						class="referrals-stats--item-value"
					>
						{{ stat.value }}
					</PrimeText>
					<PrimeText tag="p" color="grey-700" size="xs">
						{{ $t(stat.text) }}
					</PrimeText>
				</div>
			</div>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.referrals-stats {

	&--items {
		display: flex;
		align-items: center;
		gap: 1.125rem;
	}

	&--item {
		height: 4.625rem;
		display: flex;
		flex-direction: row;
		flex: 1;
		padding: 0.75rem 0.5rem;
		align-items: center;
		gap: 0.875rem;
		color: white;
		border-radius: 0.75rem;
		border: 1px solid #303133;
		background: #28292B;
		box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);
	}
}
</style>
