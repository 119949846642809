<script setup lang="ts">
import { PROCEED } from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import PrimeLoader from '@/components/common/PrimeLoader.vue';
import { ref } from 'vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useRewardsStore } from '@/stores/rewards';

type ModalOptions = {
	userData: string;
	dataType: string;
	header: string;
	message: string;
	cancelLabel: string;
	confirmParams: Record<string, string>;
};

const { options } = defineProps<{
	options: ModalOptions;
}>();

const emits = defineEmits<{
	'close-modal': [];
}>();

const loading = ref(false);

const rewardsStore = useRewardsStore();

const handleProceed = async () => {
	try {
		loading.value = true;
		await rewardsStore.claim(options.confirmParams);
		emits('close-modal');
	} finally {
		loading.value = false;
	}
};
</script>

<template>
	<div class="confirm-paypal-email-modal">
		<ModalCommonContent>
			<template #header>{{ $t(options.header) }}</template>
			<template #message>{{ $t(options.dataType) }}</template>
			<template #default>
				<div class="modal-content">
					<PrimeText tag="div" weight="600" size="sm" class="paypal-email">
						{{ options.userData }}
					</PrimeText>
					<PrimeText tag="div" weight="500">
						{{ $t(options.message) }}
					</PrimeText>
				</div>
			</template>
			<template #actions>
				<div class="actions-wrapper">
					<PrimeButton
						type="outline"
						variant="grey"
						full-width
						:label="$t(options.cancelLabel)"
						@click="$emit('close-modal')"
					/>
					<PrimeButton
						type="gradient"
						full-width
						:label="$t(PROCEED)"
						@click="handleProceed"
					/>
				</div>
			</template>
		</ModalCommonContent>
		<PrimeLoader v-if="loading" />
	</div>
</template>

<style lang="scss" scoped>
.confirm-paypal-email-modal {
	:deep(.modal-content) {
		.paypal-email {
			margin: 0.25rem 0 1.125rem;
			word-break: break-all;
		}
	}

	:deep(.actions-wrapper) {
		& .p-btn--outline {
			margin-bottom: 0.75rem;
		}
	}
}
</style>
