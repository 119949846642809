<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import {
	onMounted,
	onUnmounted,
	ref,
	watch,
	onBeforeUnmount,
	nextTick,
	computed,
} from 'vue';
import { useClickOutside } from '@/utils/composables/useClickOutside';
import { PiClose } from '@primeinsightsgroupllc/prime-icons';
import { Platforms } from '@/enums';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { MessageChannels } from '@/enums';

const props = withDefaults(
	defineProps<{
		show: boolean;
		useDefaultClose?: boolean;
		position?: 'bottom' | null;
		data?: any;
		dataTestId?: string;
		channel?: string;
	}>(),
	{
		useDefaultClose: true,
		position: null,
		data: null,
		dataTestId: '',
		channel: '',
	}
);

const emit = defineEmits<{
	(e: 'close-modal'): void;
}>();

const close = () => {
	emit('close-modal');
};

const modalContainerRef = ref();
const isModalDisplay = ref(false);
const timeout = ref<any>(null);
const { platform } = useMobileApp();

const closeButtonId = computed(() =>
	props.channel === MessageChannels.ANNOUNCEMENT
		? 'close-announcement-button'
		: 'close-modal-button'
);

const handleKeyup = (event: KeyboardEvent) => {
	if (event.key === 'Escape' && props.useDefaultClose) {
		close();
	}
};

const handleFocusOut = (event: Event) => {
	if (platform.value !== Platforms.WEB_MOBILE) return;

	if ('tagName' in event.target && event.target.tagName === 'INPUT') {
		nextTick().then(() => {
			window.scrollTo(0, 0);
		});
	}
};

watch(
	() => props.show,
	(value) => {
		if (!value && props.position === 'bottom') {
			// Set timeout for transition duration on close
			timeout.value = setTimeout(() => {
				isModalDisplay.value = value;
				clearTimeout(timeout.value);
			}, 550);
		} else {
			isModalDisplay.value = value;
		}
	},
	{ immediate: true }
);

onMounted(() => {
	document.addEventListener('keyup', handleKeyup);
	useClickOutside(modalContainerRef, close);
});

onBeforeUnmount(() => {
	clearTimeout(timeout.value);
});

onUnmounted(() => {
	document.removeEventListener('keyup', handleKeyup);
});
</script>

<template>
	<div v-if="isModalDisplay" class="modal-mask" @focusout="handleFocusOut">
		<div
			ref="modalContainerRef"
			class="modal-container"
			:class="[$attrs.class, position, { show }]"
			:data-test-id="dataTestId"
		>
			<button
				v-if="useDefaultClose"
				class="modal-close-button"
				:data-test-id="closeButtonId"
				@click.stop="$emit('close-modal')"
			>
				<PiClose color="inherit" />
			</button>
			<div class="modal-content">
				<slot />
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';

.modal-mask {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(32, 34, 35, 0.7);
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;

	@supports (inset: 0) {
		inset: 0;
	}

	@supports not (inset: 0) {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.modal-container {
	color: white;
	position: relative;
	padding: 1.25rem 0.75rem 1.5rem;
	max-width: 30rem;
	width: 95vw;
	border-radius: 1.5rem;
	border: 1px solid rgba(255, 255, 255, 0.1);
	background: linear-gradient(180deg, #1a1a1a 0%, #131313 100%);
	box-shadow:
		0 1px 2px 0 rgba(255, 255, 255, 0.02) inset,
		0 2px 4px 0 rgba(255, 255, 255, 0.08) inset;
	@include breakpoint(mobile) {
		padding: 1.5rem;
	}

	&.bottom {
		align-self: flex-end;
		padding-left: 1rem;
		padding-right: 1rem;
		width: 100vw;
		border-radius: 1.5rem 1.5rem 0 0;
		transform: translateY(100%);
		animation: modal-slide-down 0.45s;

		@include breakpoint(mobile) {
			padding: 1.5rem;
		}

		&.show {
			animation: modal-slide-up 0.45s forwards;
		}
	}
}

.modal-close-button {
	top: 1.125rem;
	right: 0.875rem;
	position: absolute;
	cursor: pointer;
	border: none;
	z-index: 10;
	color: white;
	width: 2.25rem;
	height: 2.25rem;
	border-radius: 3.125rem;
	background: linear-gradient(180deg, #1a1a1a 0%, #131313 100%);
	box-shadow:
		0 1px 2px 0 rgba(255, 255, 255, 0.02) inset,
		0 2px 4px 0 rgba(255, 255, 255, 0.08) inset,
		0 7px 4px 0 rgba(0, 0, 0, 0.15);

	@include breakpoint(mobile) {
		top: 1.375rem;
	}
}

@keyframes modal-slide-up {
	0% {
		transform: translateY(100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes modal-slide-down {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(100%);
	}
}
</style>
