import { defineStore, storeToRefs } from 'pinia';
import type {
	ClaimedReward,
	ErrorResponse,
	Rewards,
	RewardsMeta,
} from '@/types';
import {
	claimReward,
	getRewards,
	getRewardsHistory,
	selectReward,
} from '@/api';
import type { AxiosError } from 'axios';
import {
	ClaimErrorStatuses,
	ClaimStatuses,
	RewardCategories,
	RewardsSubCategories,
	TrackingEvents,
} from '@/enums';
import { sortRewards } from '@/utils/helpers';
import { sendErrorInfo } from '@/utils/errorCatching';
import { notify } from '@/components/common/notifications';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import {
	defaultSubCategories,
	filterRewardsBySubCategory,
	getRewardsSubCategoriesContent,
} from '@/views/surveys/components/user-dashboard/rewards/sub-categories';
import { useAppStore } from '@/stores/app';

interface RewardsState {
	rewards: Rewards | null;
	claimedRewards: ClaimedReward[] | null;
	isClaimInitialized: boolean;
	claimStatus: string | null;
	loading: boolean;
	initLoading: boolean;
	error: ErrorResponse | null;
	meta: RewardsMeta;
	selectedSubCategories: Record<string, string>;
	categoriesWithSubCategories: Record<RewardCategories, boolean>;
}

export const useRewardsStore = defineStore('rewards', {
	state: (): RewardsState => ({
		rewards: null,
		claimedRewards: null,
		isClaimInitialized: false,
		claimStatus: null,
		loading: false,
		initLoading: false,
		error: null,
		meta: {} as RewardsMeta,
		selectedSubCategories: defaultSubCategories,
		categoriesWithSubCategories: {} as Record<RewardCategories, boolean>,
	}),

	getters: {
		rewardByCategories: (state) => state.rewards,
		isInitialLoading: (state) => state.initLoading,
		isLoading: (state) => state.loading,
		rewardsError: (state) => state.error?.message || '',
		rewardsMeta: (state) => state.meta,
		rewardsBySubCategory: (state) =>
			state.rewards
				? filterRewardsBySubCategory(
						state.rewards,
						state.selectedSubCategories,
						state.categoriesWithSubCategories
					)
				: null,

		availableSubCategoriesContent: (state) =>
			state?.rewards
				? getRewardsSubCategoriesContent(
						state?.rewards,
						state.meta?.sub_categories,
						state.categoriesWithSubCategories
					)
				: null,
	},

	actions: {
		async fetchRewards(): Promise<void> {
			try {
				this.initLoading = true;
				this.resetSelectedSubCategories();
				const { rewards, meta } = await getRewards();
				this.meta = meta;
				this.rewards = sortRewards(rewards);
				this.setCategoriesWithSubCategories();
			} catch (error) {
				this.error = (error as AxiosError).response?.data as ErrorResponse;
				sendErrorInfo(error);
			} finally {
				this.initLoading = false;
			}
		},

		async selectReward(optionId: number): Promise<number | undefined> {
			try {
				this.loading = true;
				return await selectReward(optionId);
			} catch (_error) {
				const error = _error as AxiosError;
				this.error = error.response?.data as ErrorResponse;
				sendErrorInfo(error);
				return error.response?.status;
			} finally {
				this.loading = false;
			}
		},

		async fetchRewardsHistory(): Promise<void> {
			try {
				this.loading = true;
				this.claimedRewards = await getRewardsHistory();
			} catch (error) {
				this.error = (error as AxiosError).response?.data as ErrorResponse;
				sendErrorInfo(error);
			} finally {
				this.loading = false;
			}
		},

		setClaimInitialized(value: boolean) {
			this.isClaimInitialized = value;
		},

		setClaimStatus(value: string | null) {
			this.claimStatus = value;
		},

		async claim(
			params?: Record<string, string>
		): Promise<{ askConfirm: boolean; message: string; status: number }> {
			try {
				this.loading = true;

				const appStore = useAppStore();
				const { userSelectedReward } = storeToRefs(useUserStore());

				const { message, status } = await claimReward(params);

				this.setClaimStatus(
					status === 200 ? ClaimStatuses.SUCCESS : ClaimStatuses.FAILED
				);

				if (this.claimStatus === ClaimStatuses.SUCCESS) {
					await appStore.trackEvent(TrackingEvents.CLAIM_REQUESTED, {
						amount: userSelectedReward.value?.coin_value.toString() || '',
					});

					const userStore = useUserStore();

					await userStore.fetchUserData();

					notify({ body: message });

					await router.push({ name: 'surveys' });
				}

				return { askConfirm: false, message, status };
			} catch (error) {
				sendErrorInfo(error);
				const { response } = error as AxiosError;
				const { status, data } = response!;
				const { message, errors } = data as ErrorResponse;

				const appStore = useAppStore();
				const { userSelectedReward } = storeToRefs(useUserStore());

				if (
					[
						ClaimErrorStatuses.NEW_CLAIM_DATA_USED,
						ClaimErrorStatuses.NOT_VALID_DATA_USED,
					].includes(status)
				) {
					return { askConfirm: true, message, status };
				} else if (status === ClaimErrorStatuses.CONFIRM_CLAIM_VIA_EMAIL) {
					this.setClaimStatus(ClaimStatuses.USER_CONFIRMATION);
					await appStore.trackEvent(TrackingEvents.CLAIM_REQUESTED, {
						amount: userSelectedReward.value?.coin_value.toString() || '',
					});
					await router.push({ name: 'surveys' });
				} else {
					this.setClaimStatus(ClaimStatuses.FAILED);
					notify({
						body: errors ? Object.values(errors || []).join(' ') : message,
					});
				}
				return { askConfirm: false, message, status };
			} finally {
				this.loading = false;
				this.setClaimInitialized(false);
			}
		},

		setSelectedSubCategories(
			categoryName: string,
			selectedSubCategory: string
		) {
			this.selectedSubCategories = {
				...this.selectedSubCategories,
				[categoryName]: selectedSubCategory,
			};
		},

		resetSelectedSubCategories() {
			this.selectedSubCategories = defaultSubCategories;
		},

		setPopularAsDefaultSubCategory() {
			(
				Object.entries(this.categoriesWithSubCategories) as [
					RewardCategories,
					boolean,
				][]
			).forEach(([key, value]) => {
				if (value && this.rewards) {
					if (this.rewards[key].some((item) => item.is_popular)) {
						this.selectedSubCategories[key] = RewardsSubCategories.POPULAR;
					}
				}
			});
		},

		setCategoriesWithSubCategories() {
			const categories = Object.keys(this.rewards || {}) as RewardCategories[];
			categories.forEach((category) => {
				this.categoriesWithSubCategories[category] = !!this.rewards![
					category
				].find((item) => item.is_popular || item.sub_category_id);
			});

			this.setPopularAsDefaultSubCategory();
		},
	},
});
