<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import OverlayPanel from 'primevue/overlaypanel';
import {
	PrimeButton,
	PrimeProgressbar,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	CURRENT_STREAK,
	DRAW_PRIZE,
	SUNDAY,
	MONDAY,
	TUESDAY,
	WEDNESDAY,
	THURSDAY,
	FRIDAY,
	SATURDAY,
	DAY_STREAK,
	GUARANTEED_PRIZE,
	WIN_UP_TP_POINTS,
	EARN_POINTS_DAILY,
	POINTS,
	PROGRESS_LABEL,
} from '@/locales/constants';
import {
	PiHcCheckmark,
	PiHcCheck_circle,
	PiHcTrophy,
} from '@primeinsightsgroupllc/prime-icons';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import money_box_calendar_logo from '../../../assets/money_box_calendar_mock.svg';
import { localizePoints } from '@/utils/helpers';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const {
	streakDayStart,
	weekLenght,
	prizes,
	streaks,
	completedDays,
	dailyStreakGoal,
	dailyStreakProgress
} = storeToRefs(useUserStore());

const reorderedDays = computed(() => {
	const weekDays = [
		{ id: 0, name: t(SUNDAY) },
		{ id: 1, name: t(MONDAY) },
		{ id: 2, name: t(TUESDAY) },
		{ id: 3, name: t(WEDNESDAY) },
		{ id: 4, name: t(THURSDAY) },
		{ id: 5, name: t(FRIDAY) },
		{ id: 6, name: t(SATURDAY) },
	];
	const actualWeekDaysStart = weekDays.slice(streakDayStart.value);
	const actualWeekDaysEnd = weekDays.slice(0, streakDayStart.value);

	return [...actualWeekDaysStart, ...actualWeekDaysEnd];
});

const userStreakInfo = ref<InstanceType<typeof OverlayPanel> | null>(null);
const progress = computed(
	() => (Number(dailyStreakProgress.value) / Number(dailyStreakGoal.value)) * 100
);

const toggleUserStreakInfo = (event: Event) => {
	userStreakInfo.value?.toggle(event);
};

const onPrizeDraw = (event: Event) => {
	toggleUserStreakInfo(event);
	router.push({ name: 'prize-draw' });
};

watch(route, async () => {
	if (userStreakInfo.value) {
		userStreakInfo.value?.hide();
	}
});
</script>

<template>
	<ModalCommonContent id="user-congrats-streak-modal">
		<template #header>{{ $t(DAY_STREAK) }}</template>
		<template #message>
			<div class="user-streak">
				<PrimeText tag="div" size="sm" weight="400" color="grey-700">
					{{ $t(EARN_POINTS_DAILY, { value: localizePoints(1000), day: 7 }) }}
				</PrimeText>
				<div class="user-streak--progressbar" v-if="dailyStreakGoal">
					<div class="user-streak--progressbar-stat">
						<PrimeText size="sm" weight="400">
							{{ $t(PROGRESS_LABEL) }}:
						</PrimeText>
						<PrimeText size="sm" weight="400">
							{{ localizePoints(dailyStreakProgress) }}/{{ localizePoints(dailyStreakGoal) }} {{ $t(POINTS) }}
						</PrimeText>
					</div>
					<PrimeProgressbar :value="progress"/>
				</div>
				<div class="user-streak--info">
					<div>
						<div>
							<PrimeText tag="div" size="xl" weight="500" color="primary">
								{{ $t(CURRENT_STREAK) }}: {{ streaks }}
							</PrimeText>
						</div>
					</div>
					<div v-if="weekLenght" class="streak-info--progress">
						<div
							v-for="(day, index) in reorderedDays"
							:key="day.id"
							class="streak-info--progress-day"
						>
							<PrimeText class="streak-info--progress-day-name" weight="500">
								{{ day.name }}
							</PrimeText>
							<div
								class="streak-info--progress-day-icon"
								:class="{
									set: index <= completedDays - 1,
									completed:
										day.id === reorderedDays[weekLenght - 1].id &&
										completedDays === weekLenght,
								}"
							>
								<PiHcCheckmark
									v-if="index !== weekLenght - 1"
									size="14"
									color="white"
								/>
								<PiHcTrophy v-else size="20" color="white" />
							</div>
						</div>
					</div>
					<img :src="money_box_calendar_logo" alt="" />
					<div class="streak-info--rules">
						<PiHcTrophy size="20" color="inherit" />
						<PrimeText tag="div" size="sm" weight="400" color="grey-700">
							{{ $t(WIN_UP_TP_POINTS, { value: localizePoints(10000) }) }}
						</PrimeText>
					</div>
					<div class="streak-info--rules">
						<PiHcCheck_circle size="20" color="inherit" />
						<PrimeText tag="div" size="sm" weight="400" color="grey-700">
							{{ $t(GUARANTEED_PRIZE) }}
						</PrimeText>
					</div>
				</div>
			</div>
		</template>
		<template #actions>
			<PrimeButton
				:label="$t(DRAW_PRIZE)"
				:disabled="prizes === 0"
				@click="onPrizeDraw"
			>
				<template #icon> ({{ prizes }}) </template>
			</PrimeButton>
		</template>
	</ModalCommonContent>
</template>

<style lang="scss">
@import '@/styles/mixins.scss';
.user-streak {
	padding-top: 0;
	@media (min-height: 668px) and (min-width: 374px) {
		padding-top: 1.5rem;
	}
	&--progressbar {
		margin: 1rem 0;
		&-stat {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 0.25rem;
		}
		.p-progress {
			border-radius: 3.125rem;
			background: var(--p-grey-300) !important;
		}

		.p-progress-bar {
			border-radius: 3.125rem;
			background: linear-gradient(270deg, #FF6B34 0%, rgba(255, 107, 52, 0.00) 100%) !important;
		}
	}
	&--info {
		.streak-info {
			&--rules {
				margin-top: 0.75rem;
				padding: 0.5rem;
				border-radius: 0.75rem;
				border: 1px solid var(--p-grey-300);
				color: var(--p-grey-700);
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 0.5rem;
				text-align: left;
			}
			&--progress {
				max-width: calc(100% - 1.5rem);
				display: flex;
				justify-content: space-between;
				margin: 0.75rem auto;

				@include breakpoint(tablet) {
					max-width: 20.625rem;
				}

				&-day {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					&-name {
						font-size: 0.625rem;
					}

					&-icon {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: var(--grey-400);
						color: white;
						width: 1.5rem;
						height: 1.5rem;
						border-radius: 50%;

						&.set {
							background-color: var(--base-primary);
						}

						&.completed {
							border-color: #806dff !important;
							background-color: #806dff;
						}
					}

					&:nth-child(7) .streak-info--progress-day-icon {
						border: 1px solid var(--grey-500);
					}
				}
			}
		}
	}

	img {
		display: none;

		@media (min-height: 650px) and (min-width: 374px) {
			width: 120px;
			height: 120px;
			display: inline;
		}

		@media (min-height: 750px) and (min-width: 374px) {
			width: 180px;
			height: 180px;
		}
	}
}
</style>
