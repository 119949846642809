<script lang="ts" setup>
import type { IconColors } from '@/types';
import { useRouter } from 'vue-router';
import { PiCaretRight } from '@primeinsightsgroupllc/prime-icons';
import type { Component } from 'vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();
const { isMobile } = useScreenSize();

const emits = defineEmits<{
	(e: 'click'): void;
}>();

const props = withDefaults(
	defineProps<{
		icon: Component;
		gradient?: boolean;
		iconColor?: IconColors;
		title: string;
		details?: string;
		handler?: () => void;
		route?: object | null;
		disabled?: boolean;
	}>(),
	{
		iconColor: 'inherit',
		details: '',
		route: null,
		gradient: false,
		disabled: false,
	}
);

const goTo = () => {
	if (props.route) {
		router.push(props.route);
	} else if (props.handler) {
		props.handler()
	}

	emits('click');
};
</script>

<template>
	<div
		class="prime-navigation"
		@click.stop="goTo"
	>
		<div class="prime-navigation-content">
			<div class="prime-navigation-icon">
				<component :is="icon" v-if="icon" :color="iconColor" />
			</div>
			<div class="prime-navigation-text">
				<PrimeText
					tag="h5"
					weight="700"
					color="inherit"
					:size="isMobile ? 'sm' : 'base'"
				>
					{{ title }}
				</PrimeText>
			</div>
			<PrimeText
				v-if="details"
				tag="p"
				weight="500"
				:size="isMobile ? 'xs' : 'sm'"
			>
				{{ details }}
			</PrimeText>
		</div>
		<div v-if="route || handler" class="prime-navigation-link">
			<PiCaretRight size="1.25rem" color="inherit"/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prime-navigation {
	position: relative;
	transition: all 0.1s;
	flex: 0 0 50%;
	max-width: calc(50% - 0.25rem);
	width: 50%;
	padding: 0.5rem;
	color: white;
	cursor: pointer;

	border-radius: 0.75rem;
	border: 1px solid var(--p-grey-300);
	background: var(--p-grey-500);
	box-shadow: var(--p-shadow-base);

	&.hover {
		cursor: pointer;
	}

	@include breakpoint(tablet) {
		width: 325px;
		padding: 0.875rem;
		flex: 0 0 33.333333%;
		max-width: calc(33.333333% - 1.75rem);
	}
}

.prime-navigation-icon {
	margin-bottom: 0.875rem;
}

.prime-navigation-text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: white;
}

.prime-navigation-link {
	position: absolute;
	right: 0.5rem;
	bottom: 0.5rem;
	color: white;
	cursor: pointer;

	@include breakpoint(tablet) {
		right: 0.875rem;
		bottom: 0.875rem;
	}

	& svg {
		flex-shrink: 0;
	}
}
</style>
