import { computed } from 'vue';
import {
	SURVEYS,
	LEADERBOARD,
	ACCOUNT,
	OFFERWALLS_TITLE,
} from '@/locales/constants';
import { useI18n } from 'vue-i18n';
import type { NavItem } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	PiHcSurveys,
	PiHcLeaderboardfilled,
	PiHcOffersfilled,
	PiHcAccount,
} from '@primeinsightsgroupllc/prime-icons';
import { USER_ROUTE_NAME } from '@/constants/routes';

export const useNavItems = () => {
	const { t } = useI18n();
	const { isLeaderboardActive, isOfferwallsAvailable } =
		storeToRefs(useUserStore());

	const items = computed<Array<NavItem>>(() => [
		{
			icon: PiHcSurveys,
			label: t(SURVEYS),
			to: USER_ROUTE_NAME.SURVEYS,
			isActive: true,
			id: USER_ROUTE_NAME.SURVEYS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiHcLeaderboardfilled,
			label: t(LEADERBOARD),
			to: USER_ROUTE_NAME.LEADERBOARD,
			isActive: Boolean(isLeaderboardActive.value),
			id: USER_ROUTE_NAME.LEADERBOARD,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiHcOffersfilled,
			label: t(OFFERWALLS_TITLE),
			to: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			isActive: Boolean(isOfferwallsAvailable.value),
			id: USER_ROUTE_NAME.OFFERWALLS_PARTNERS,
			placed: ['desktop', 'mobile'],
		},
		{
			icon: PiHcAccount,
			label: t(ACCOUNT),
			to: USER_ROUTE_NAME.PROFILE,
			isActive: true,
			id: USER_ROUTE_NAME.PROFILE,
			placed: ['desktop', 'mobile'],
		},
	]);

	const filterItems = (
		items: NavItem[],
		type: 'mobile' | 'desktop'
	): NavItem[] => {
		return items.filter((item) => item.placed.includes(type) && item.isActive);
	};

	const desktopItems = computed(() => filterItems(items.value, 'desktop'));
	const mobileItems = computed(() => filterItems(items.value, 'mobile'));

	return {
		desktopItems,
		mobileItems,
		items,
	};
};
