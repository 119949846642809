<script lang="ts" setup>
import { Echo } from '@/utils/echo';
import { onBeforeMount, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const router = useRouter();
const route = useRoute();

const authStore = useAuthStore();

onBeforeMount(async () => {
	const echo = Echo.getInstance();
	echo.removeConnections();
});

onMounted(async () => {
	if (route.query.url) {
		await authStore.verifyEmail(route.query.url.toString());
	} else {
		await router.replace({ name: 'app-login' });
	}
});
</script>

<template>
	<div class="login-page">
		<div class="page-content">
			<PrimeLoaderSpinner />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.login-page {
	display: flex;
	flex-direction: column;
	max-width: 73.125rem;
	width: 100%;
	margin: 0 1.1875rem;
	justify-content: center;
}

.page-content {
	flex: 1 0 auto;
	display: flex;
	align-items: center;
}
</style>
