<script lang="ts" setup>
import {CLAIM_REWARD, RECEIVE_REWARD_VIA_EMAIL} from '@/locales/constants';
import {PrimeButton, PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import ConfirmClaimTop from "@/views/confirm-claim/components/ConfirmClaimTop.vue";
import RewardDetails from "@/views/confirm-claim/components/RewardDetails.vue";
import {useConfirmClaimView} from "@/views/confirm-claim/composables";
import {REWARD_SENT_MODAL} from "@/constants/modals";
import {useModalStorage} from "@/utils/composables/useModalStorage";

const { isClaimAvailable, restOfTheBalanceText, component } =
	useConfirmClaimView();
const modalStorage = useModalStorage();

defineProps<{
	disabled: boolean;
	loading: boolean;
}>();

const emit = defineEmits<{
	confirm: [];
}>();

const onClaimConfirm = () => {
	emit('confirm')
}
</script>

<template>
	<div class="claim-data">
		<ConfirmClaimTop :is-claim-available="isClaimAvailable" />
		<RewardDetails v-if="isClaimAvailable"/>

		<div v-if="$slots['claim-data'] && isClaimAvailable" class="claim-data-wrapper">
			<slot name="claim-data" />
		</div>
	</div>

	<div class="claim-data-footer">
		<PrimeText
			v-if="isClaimAvailable"
			tag="p"
			size="xs"
			color="grey-700"
			weight="500"
			class="text email-details"
		>
			{{ $t(RECEIVE_REWARD_VIA_EMAIL) }}
		</PrimeText>

		<PrimeButton
			full-width
			:label="$t(CLAIM_REWARD)"
			:disabled="disabled"
			:loading="loading"
			@click="onClaimConfirm"
		/>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins';

.claim-data {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 1.5rem;
	padding: 1rem;
	background: var(--p-grey-500);
	color: white;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0.5px 1.5px 0px rgba(0, 0, 0, 0.19);
}

.claim-data-footer {
	display: flex;
	flex-direction: column;
	gap: 0.875rem;
	width: 100%;
	align-items: center;

	@include breakpoint(tablet) {
		max-width: 350px;
	}
}

.claim-data-wrapper {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	margin-bottom: 1.5rem;
	width: 100%;
}
</style>
