<script lang="ts" setup>
import type { RouteRecordName } from 'vue-router';
import { onBeforeMount, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import PageCard from '@/components/PageCard.vue';
import { PiCaretLeft } from '@primeinsightsgroupllc/prime-icons';
import { PrimeButton, PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const authStore = useAuthStore();
const router = useRouter();

const {
	backRoute,
	title = '',
	backButtonVisible = true,
} = defineProps<{
	title?: string;
	fullWidth?: boolean;
	backButtonVisible?: boolean;
	backRoute?: RouteRecordName;
}>();

const goBack = async () => {
	if (backRoute) {
		await router.push({ name: backRoute });
	} else if (window.history.state.back) {
		router.go(-1);
	} else {
		await router.push({ name: 'surveys' });
	}
};

onBeforeMount(() => {
	authStore.setTopNavigationVisibility(false);
});
onBeforeUnmount(() => {
	authStore.setTopNavigationVisibility(true);
});
</script>
<template>
	<PageCard :full-width="!!fullWidth" class="subpage">
		<header v-if="title" class="subpage__header">
			<PrimeButton
				v-if="backButtonVisible"
				rounded="rounded"
				variant="primary"
				type="outline"
				class="subpage__header--back"
				@click="goBack"
			>
				<template #icon><PiCaretLeft /></template>
			</PrimeButton>
			<PrimeText
				size="xl"
				weight="400"
				:class="{
					'subpage__header--text': backButtonVisible,
				}"
			>
				{{ title }}
			</PrimeText>
		</header>
		<slot />
	</PageCard>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.subpage {
	overflow-x: hidden;
	&__header {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1.125;
		padding: 0.5rem 1rem 1.5rem;
		margin: 0 -1rem 1rem;

		@include breakpoint(mobile) {
			padding: 0 1.375rem 1.5rem;
			margin-left: -1.375rem;
			margin-right: -1.375rem;
		}

		&--text {
			color: white !important;
			margin: 0 3.125rem;
			text-align: center;
		}
	}
}

.p-btn.subpage__header--back {
	position: absolute;
	left: 1rem;
	padding: 0;
	width: 2.5rem;
	height: 2.75rem;

	@include breakpoint(mobile) {
		left: 1.375rem;
		width: 3rem;
		height: 3rem;
	}
}
</style>
