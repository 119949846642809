<script lang="ts" setup>
import API from '@/api';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { CONTINUE_WITH_SOCIAL } from '@/locales/constants';
import SocialProviderIcon from '@/components/auth/SocialProviderIcon.vue';
import { PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { provider, nonPrefix = false } = defineProps<{
	provider: string;
	nonPrefix?: boolean;
}>();

const { t } = useI18n();
const { isMobileApp } = useMobileApp();
const isLoading = ref(false);

const emits = defineEmits<{
	(e: 'loading', value: boolean): void;
	(e: 'auth', value: string): void;
}>();

const getProviderRedirect = async () => {
	if (isLoading.value) return;

	if (isMobileApp) {
		emits('auth', provider);
	} else {
		try {
			isLoading.value = true;
			emits('loading', true);
			const response = await API.get<{ url: string }>(
				`auth/social/redirect?redirect_to_app=1&provider=${provider}`,
				{ authRequired: false }
			);
			window.location.href = response?.data.url;
		} finally {
			isLoading.value = false;
			emits('loading', false);
		}
	}
};

const buttonLabel = computed((): string => {
	return !nonPrefix
		? `${t(CONTINUE_WITH_SOCIAL)} <span>${provider}</span>`
		: '<template/>';
});
</script>

<template>
	<PrimeButton
		variant="grey"
		type="outline"
		:loading="isLoading"
		:disabled="isLoading"
		:label="buttonLabel"
		:class="{ 'social__btn-inline': nonPrefix }"
		@click="getProviderRedirect"
	>
		<template #iconLeft>
			<SocialProviderIcon :name="provider" />
		</template>
	</PrimeButton>
</template>

<style scoped lang="scss">
button.p-btn {
	width: 100%;
	display: flex;
	padding: 1.375rem 0.75rem;
	justify-content: center;
	align-items: center;
	gap: 0.5rem;
	align-self: stretch;
	color: white;
	font-feature-settings:
		'clig' off,
		'liga' off;
	font-size: 0.85rem;
	font-style: normal;
	font-weight: 400 !important;
	line-height: normal;
	border-radius: 150px !important;
	background: linear-gradient(180deg, #1a1a1a 0%, #131313 100%) !important;
	box-shadow:
		0px 1px 2px 0px rgba(255, 255, 255, 0.02) inset,
		0px 2px 4px 0px rgba(255, 255, 255, 0.08) inset,
		0px 7px 4px 0px rgba(0, 0, 0, 0.15) !important;
	&.social__btn-inline {
		min-width: 0;
		flex: 1;

		:deep(.p-btn-icon--left) {
			margin-right: 0;
		}

		:deep(.p-btn-label) {
			display: none;
		}
	}
}
</style>
