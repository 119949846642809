<script setup lang="ts">
import {
	LEADERBOARD_TABLE_TITLE,
	LEADERBOARD_TABLE_DESCRIPTION,
	USER,
	EARNED,
	PRIZE,
} from '@/locales/constants';
import LeaderboardUserRow from '@/views/leaderboard/components/LeaderboardUserRow.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type {LeaderboardUser} from "@/types";
import PrimeInfiniteList from "@/components/common/PrimeInfiniteList.vue";

const { leaderboard, username } = storeToRefs(useUserStore());
</script>

<template>
	<section class="leaderboard-users"
					 v-if="username && leaderboard.unlocked">
		<PrimeText
			tag="h1"
			size="xl"
			weight="700"
			color="inherit"
			class="leaderboard-users--header"
		>
			{{ $t(LEADERBOARD_TABLE_TITLE) }}
		</PrimeText>
		<PrimeText
			tag="p"
			size="sm"
			color="grey-700"
			class="leaderboard-users--description"
		>
			{{ $t(LEADERBOARD_TABLE_DESCRIPTION) }}
		</PrimeText>
		<div
			class="leaderboard-users--table"
		>
			<div class="leaderboard-table__header-row">
				<PrimeText
					size="sm"
					weight="400"
					color="grey-700"
					class="header-element description"
				>
					{{ $t(USER) }}
				</PrimeText>
				<PrimeText
					size="sm"
					weight="400"
					color="grey-700"
					class="header-element prize"
				>
					{{ $t(EARNED) }}
				</PrimeText>
				<PrimeText
					size="sm"
					weight="400"
					color="grey-700"
					class="header-element prize"
					>{{ $t(PRIZE) }}
				</PrimeText>
			</div>
			<PrimeInfiniteList
				:show-list-end="false"
				:page-offset="50"
				api-url="/api/leaderboard/users"
			>
				<template #list-item="{ item: user }: { item: LeaderboardUser }">
					<LeaderboardUserRow :user="user" />
				</template>
			</PrimeInfiniteList>
		</div>
	</section>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.leaderboard-users {
	color: white;
}

.leaderboard-users--header {
	margin-bottom: 0.25rem;
}
.leaderboard-users--description {
	margin-bottom: 0.875rem;

	@include breakpoint(tablet) {
		margin-bottom: 1.5rem;
	}
}

.leaderboard-users--table {
	width: 100%;
}

.leaderboard-table__header-row {
	width: 100%;
	display: grid;
	padding: 0.5rem;
	margin-bottom: 0.375rem;
	grid-template-columns: 4fr 2fr 2fr;

	@include breakpoint(tablet) {
		grid-template-columns: 4fr 2fr 2fr 2fr;
	}

	.prize {
		text-align: center;
	}
}
</style>
