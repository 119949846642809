import API from '@/api';
import type {
	AuthPayload,
	ChangePasswordForm,
	CheckEmailResponse,
	LoginResponse,
	MessageResponse,
	RegisterPayload,
	ResetPasswordPayload,
	SelectedLocale,
	SocialAuthCallback,
	SocialCallbackResponse,
	StatusResponse,
	UserLoginResponse,
	VerifyEmailResponse,
} from '@/types';
import type { AxiosResponse } from 'axios';

export const authRegister = async (
	payload: RegisterPayload
): Promise<StatusResponse> => {
	const { status } = await API.post('/auth/register', payload, {
		authRequired: false,
	});
	return { status };
};

export const authResendVerificationEmail = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post(
		'/auth/resend-verification-email',
		{ email },
		{
			authRequired: false,
		}
	);
};

export const authLogin = async (
	payload: AuthPayload
): Promise<LoginResponse> => {
	const { data } = await API.post<LoginResponse>('/auth/login', payload, {
		authRequired: false,
	});
	return data;
};

export const authVerifyCode = async (
	code: string,
	email: string
): Promise<LoginResponse> => {
	const { data } = await API.post<LoginResponse>(
		'/auth/verify-email-code',
		{ email, code },
		{
			authRequired: false,
		}
	);
	return data;
};

export const authResetPassword = async (
	payload: ResetPasswordPayload
): Promise<LoginResponse & StatusResponse> => {
	const { data, status } = await API.post<LoginResponse>(
		'/auth/reset-password',
		payload,
		{
			authRequired: false,
		}
	);
	return {
		...data,
		status,
	};
};

export const authForgotPassword = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post(
		'/auth/forgot-password',
		{ email },
		{
			authRequired: false,
		}
	);
};

export const authVerifyEmail = async (
	apiUrl: string
): Promise<VerifyEmailResponse & StatusResponse> => {
	const { data, status } = await API.get<VerifyEmailResponse>(apiUrl);
	return {
		...data,
		status,
	};
};

export const authLoginWithUrl = async <T>(
	loginUrl: string
): Promise<{ data: T } & StatusResponse> => {
	const { data, status } = await API.get<T>(loginUrl, {
		authRequired: false,
	});
	return {
		data,
		status,
	};
};

export const authSendOneTimeToken = async (
	email: string
): Promise<AxiosResponse> => {
	return await API.post(
		'/auth/send-one-time-login-link',
		{ email },
		{
			authRequired: false,
		}
	);
};

export const authSocialCallback = async (
	params: SocialAuthCallback
): Promise<{
	data: SocialCallbackResponse | LoginResponse;
	status: number;
}> => {
	const { data, status } = params.callbackUrl
		? await API.get<SocialCallbackResponse>(`auth/social/callback`, {
				params: params.callbackUrl,
				authRequired: false,
			})
		: await API.post<LoginResponse>('auth/social/token', params, {
				authRequired: false,
			});

	return { data, status };
};

export const authSocialLoginToExisting = async (
	signedUrl: string,
	authData: SocialCallbackResponse,
	password: string
): Promise<{ data: LoginResponse; status: number }> => {
	const { data, status } = await API.post<LoginResponse>(
		signedUrl,
		{
			...authData,
			password,
		},
		{
			authRequired: false,
		}
	);

	return { data, status };
};

export const authSocialRegisterWithSignature = async (
	signedUrl: string,
	authData: SocialCallbackResponse & SelectedLocale
): Promise<{ data: UserLoginResponse; status: number }> => {
	const { data, status } = await API.post<UserLoginResponse>(
		signedUrl,
		{ ...authData },
		{
			authRequired: false,
		}
	);

	return {
		data,
		status,
	};
};

export const authSocialRegister = async (
	data: SocialCallbackResponse & SelectedLocale,
	email: string
): Promise<StatusResponse> => {
	const { status } = await API.post(
		'/auth/register',
		{
			...data,
			email,
		},
		{
			authRequired: false,
		}
	);

	return { status };
};

export const authCheckEmail = async (
	email: string
): Promise<{ data: CheckEmailResponse }> => {
	const { data } = await API.post<CheckEmailResponse>(
		'/auth/check',
		{
			email,
		},
		{ authRequired: false }
	);

	return { data };
};

export const authSetPassword = async (
	password: string
): Promise<MessageResponse & StatusResponse> => {
	const { status, data } = await API.put<MessageResponse>(
		'/auth/set-password',
		{ password },
		{
			authRequired: false,
		}
	);

	return {
		...data,
		status,
	};
};

export const authChangeEmail = async ({
	email,
	password,
}: AuthPayload): Promise<StatusResponse> => {
	const { status } = await API.post<MessageResponse>(
		'/auth/change-email',
		{
			to_email: email,
			password: password,
		},
		{
			authRequired: false,
		}
	);

	return { status };
};

export const authChangePassword = async (
	payload: ChangePasswordForm
): Promise<MessageResponse & StatusResponse> => {
	const { data, status } = await API.put<MessageResponse>(
		'/auth/change-password',
		payload,
		{
			authRequired: false,
		}
	);

	return {
		...data,
		status,
	};
};
