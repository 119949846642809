<script lang="ts" setup>
import {
	OPEN_BOX,
	SELECT_PRIZE_BOX,
	YOU_WON_BONUS,
	YOU_WON_COINS,
} from '@/locales/constants';
import { localizePoints } from '@/utils/helpers';
import { PiGift } from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import type { StreakPrizes as PrizeType } from '@/enums';
import { StreakPrizes } from '@/enums';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { type, prize } = defineProps<{
	showWinMessage: boolean;
	prize: number;
	type: PrizeType;
}>();

const { isMobile } = useScreenSize();
const { t } = useI18n();

const headerText = computed(() => {
	const text = type === StreakPrizes.COINS ? YOU_WON_COINS : YOU_WON_BONUS;
	const amount = type === StreakPrizes.COINS ? localizePoints(prize) : prize;

	return t(text, { amount });
});
</script>

<template>
	<div v-if="showWinMessage" class="prize-draw--win-message">
		<div class="win-message-text">
			<PrimeText
				color="inherit"
				weight="500"
				:size="isMobile ? 'sm' : 'base'"
				v-html="headerText"
			/>
		</div>
		<PiGift size="1.75rem" color="inherit" />
	</div>
	<template v-else>
		<div class="prize-draw--title">
			<PrimeText tag="h1" weight="700" size="xl" color="inherit">
				{{ $t(OPEN_BOX) }}
			</PrimeText>
		</div>
		<PrimeText
			tag="p"
			color="grey-700"
			size="sm"
			class="prize-draw--description"
		>
			{{ $t(SELECT_PRIZE_BOX) }}
		</PrimeText>
	</template>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins';

.prize-draw--title {
	color: white;
	padding: 1rem 0 0.5rem;
}

.prize-draw--description {
	padding-bottom: 0.125rem;
}

.prize-draw--win-message {
	width: 100%;
	display: flex;
	align-self: center;
	align-items: center;
	justify-content: center;
	gap: 0.875rem;
	color: var(--p-white);
	border-radius: 12px;
	border: 2px solid var(--p-primary-base);
	background: #274133;
	box-shadow: 0px 1px 3px 0px rgba(63, 63, 68, 0.15), 0px 0px 0px 1px rgba(63, 63, 68, 0.05);

	@include breakpoint(mobile) {
		padding: 1.125rem 0;
	}

	svg {
		align-self: center;

		@include breakpoint(mobile) {
			width: 2.25rem;
			height: 2.25rem;
		}
	}

	.win-message-text {
		display: flex;
		padding-inline-start: 0.75rem;
	}
}
</style>
