<script lang="ts" setup>
import { ClaimStatuses } from '@/enums';
import {
	CONFIRM_VIA_EMAIL,
	DONE,
	ERROR,
	PROCESSING,
	REFUNDED,
} from '@/locales/constants';
import type { Component } from 'vue';
import { computed } from 'vue';
import type { IconColors } from '@/types';
import {
	PiCheckCircleDuotone,
	PiClockCountdownDuotone,
	PiQuestionCircleDuotone,
	PiWarningCircleDuotone,
} from '@primeinsightsgroupllc/prime-icons';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';

type StatusContent = {
	text: string;
	icon: Component;
	className: string;
};

type StatusesContent = Record<ClaimStatuses, StatusContent>;

const props = defineProps<{
	status: ClaimStatuses;
}>();

const CLAIM_STATUS_CONTENT: StatusesContent = {
	[ClaimStatuses.SUCCESS]: {
		text: DONE,
		icon: PiCheckCircleDuotone,
		className: 'claim-status--success',
	},
	[ClaimStatuses.REFUND]: {
		text: REFUNDED,
		icon: PiWarningCircleDuotone,
		className: 'claim-status--refund',
	},
	[ClaimStatuses.FAILED]: {
		text: ERROR,
		icon: PiWarningCircleDuotone,
		className: 'claim-status--failed',
	},
	[ClaimStatuses.PROCESSING]: {
		text: PROCESSING,
		icon: PiQuestionCircleDuotone,
		className: 'claim-status--processing',
	},
	[ClaimStatuses.USER_CONFIRMATION]: {
		text: CONFIRM_VIA_EMAIL,
		icon: PiClockCountdownDuotone,
		className: 'claim-status--success',
	},
};

const statusContent = computed(() => CLAIM_STATUS_CONTENT[props.status]);
</script>

<template>
	<div class="claim-status" :class="statusContent.className">
		<PrimeText weight="500" size="sm" color="inherit" class="status-text">
			{{ $t(statusContent.text) }}
		</PrimeText>
		<component
			:is="statusContent.icon"
			color="inherit"
			size="1.25rem"
		/>
	</div>
</template>

<style lang="scss" scoped>
.claim-status {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.5rem;
	justify-content: center;
	gap: 0.25rem;
	border-radius: 50px;

	&--success {
		color: #B9FF66;
		background: rgba(59, 186, 106, 0.10);
	}

	&--processing {
		color: #FFE236;
		background: rgba(255, 226, 54, 0.10);
	}

	&--failed {
		background: rgba(255, 100, 100, 0.10);
		color: #FF6464;
	}

	&--refund {
		background: rgba(128, 109, 255, 0.10);
		color: #806DFF;
	}
}
</style>
