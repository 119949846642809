<script lang="ts" setup>
import {
	MOBILE_APP_HERE_MODAL_TITLE,
	MOBILE_APP_HERE_TITLE,
	MOBILE_APP_HERE_TEXT,
	MOBILE_APP_HERE_MESSAGE_1,
	MOBILE_APP_HERE_MESSAGE_2,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import mobilePhone from '@/assets/mobile-phone.svg';
import appStore from '@/assets/app-store.svg';
import googlePlay from '@/assets/google-play.svg';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
const { VITE_APP_STORE_DOWNLOAD_URL, VITE_GOOGLE_PLAY_DOWNLOAD_URL } =
	import.meta.env;

const platforms = [
	{
		name: 'app-store',
		iconSrc: appStore,
		url: VITE_APP_STORE_DOWNLOAD_URL,
		iconWidth: '111',
	},
	{
		name: 'google-play',
		iconSrc: googlePlay,
		url: VITE_GOOGLE_PLAY_DOWNLOAD_URL,
		iconWidth: '129',
	},
];
</script>

<template>
	<div class="mobile-app-here-modal">
		<ModalCommonContent>
			<template #header>{{ $t(MOBILE_APP_HERE_MODAL_TITLE) }}</template>
			<template #title>
				{{ $t(MOBILE_APP_HERE_TITLE) }}
			</template>
			<template #message>
				{{ $t(MOBILE_APP_HERE_TEXT) }}
			</template>
		</ModalCommonContent>
		<div class="mobile-app-here-content">
			<div class="mobile-app-here-image">
				<img :src="mobilePhone" :alt="$t(MOBILE_APP_HERE_MODAL_TITLE)" />
			</div>
			<PrimeText
				size="sm"
				color="grey-700"
				weight="500"
				class="mobile-app-here-description"
			>
				{{ $t(MOBILE_APP_HERE_MESSAGE_1) }} <br />
				{{ $t(MOBILE_APP_HERE_MESSAGE_2) }}
			</PrimeText>
			<div class="download-app">
				<a
					v-for="platform in platforms"
					:key="platform.name"
					class="download-app__button"
					:href="platform.url"
					target="_blank"
					rel="noopener noreferrer"
				>
					<img
						:src="platform.iconSrc"
						:alt="platform.name"
						:width="platform.iconWidth"
						height="30"
					/>
				</a>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.mobile-app-here-modal {
	:deep(.modal-common-content) {
		.content-title {
			font-weight: 600;
		}
		.content-message {
			color: var(--grey-700);
		}
	}
}

.mobile-app-here-content {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.mobile-app-here-image {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	text-align: center;
}

.mobile-app-here-description {
	margin-bottom: 0.75rem;
	max-width: 85%;
	text-align: center;
}

.download-app {
	display: flex;
	justify-content: center;
	gap: 0.9375rem;

	&__button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0.375rem;
		border-radius: 0.3125rem;
		border: 1px solid var(--grey-400);
		background-color: #000;
	}
}
</style>
