<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed, onBeforeMount, ref, watch } from 'vue';
import type { LeaderboardInfo } from '@/types';
import { useRouter } from 'vue-router';
import { getLeaderboardMeta } from '@/api';
import ScoredHeader from './headers/ScoredHeader.vue';
import { LeaderboardTypes } from '@/enums';
import { useAppNotificationListener } from '@/utils/composables/app/useAppNotificationListener';
import { useModalStorage } from '@/utils/composables/useModalStorage';

const emits = defineEmits<{
	loading: [value: boolean];
}>();

const { leaderboard, username } = storeToRefs(useUserStore());

const info = ref<LeaderboardInfo>();
const nextStart = ref('');
const nextName = ref('');
const isActive = ref(false);

const router = useRouter();
const userStore = useUserStore();
const { isLeaderboardActive } = storeToRefs(userStore);
const { showLeaderboardModal } = useAppNotificationListener();

const isEnoughPointsForLeaderboard = computed(
	() => leaderboard.value.has_enough_points
);

const fetchLeaderboardData = async () => {
	emits('loading', true);

	await userStore.fetchUserData();

	if (isLeaderboardActive.value) {
		const data = await getLeaderboardMeta();
		const leaderboardType =
			(data.active && 'active') || (data.last && 'last') || undefined;

		isActive.value = Boolean(data?.active);

		if (leaderboardType) {
			info.value = {
				...data[leaderboardType],
				leaderboard_description: data?.leaderboard_description,
			};
		}
		if (data.next) {
			nextStart.value = data.next.start;
			nextName.value = data.next.name;
		}
	} else {
		await router.replace('/surveys');
	}
	emits('loading', false);
};

const checkLeaderboardStatus = () => {
	if (!username.value && isEnoughPointsForLeaderboard.value) {
		showLeaderboardModal({
			type: LeaderboardTypes.UNLOCKED,
			useDefaultClose: false,
		});
	}

	if (!isEnoughPointsForLeaderboard.value && !leaderboard.value.unlocked) {
		showLeaderboardModal({
			type: LeaderboardTypes.LOCKED,
			info: info.value,
			useDefaultClose: false,
		});
	}
};

onBeforeMount(async () => {
	await fetchLeaderboardData();
	checkLeaderboardStatus();
});

watch(isEnoughPointsForLeaderboard, (v) => {
	if (v) useModalStorage().shiftModal();
	checkLeaderboardStatus();
});

watch(username, async (v) => {
	if (v) await fetchLeaderboardData();
});
</script>

<template>
	<header class="leaderboard-header">
		<!-- SCORED user has 10 completed surveys AND has username-->
		<ScoredHeader
			v-if="username && leaderboard.unlocked"
			:info="info!"
			:is-active="isActive"
			:next-start="nextStart"
			:next-name="nextName"
		/>
	</header>
</template>
