<script lang="ts" setup>
import { onMounted, ref, watch, computed } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import AppMobileNav from '@/components/AppMobileNav.vue';
import NotificationsContainer from '@/components/common/notifications/NotificationsContainer.vue';
import ModalWindow from '@/components/ModalWindow.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import ErrorBoundary from '@/components/ErrorBoundary.vue';
import { PrimeLoaderSpinner } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { getInitialUserLocalization } from '@/utils/helpers';
import { handleUserLocale } from '@/i18n';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useAppCookies } from '@/utils/composables/useAppCookies';
import NoConnection from '@/components/NoConnection.vue';
import { useMobileApp } from '@/utils/composables/useMobileApp';
import { useAppRerender } from '@/utils/composables/useAppRerender';
import { useBlockScroll } from '@/utils/composables/useBlockScroll';
import { useCapacitorPlugins } from '@/utils/composables/useCapacitorPlugins';
import { providePrimeSurveys } from '@/utils/composables/useIntegrationScriptRender';
import { providePrimeIcons } from '@primeinsightsgroupllc/prime-icons';
import '@primeinsightsgroupllc-ui/prime-ui-kit/dist/main.css';
import { providePrimeTheme } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import { useTranslateDetection } from '@/utils/composables/useTranslateDetection';
import { useAppPusherEventListener } from '@/utils/composables/app/useAppPusherEventListener';
import { useAppNotificationListener } from '@/utils/composables/app/useAppNotificationListener';
import { useAppInit } from '@/utils/composables/app/useAppInit';
import { useAppAnnouncementsListener } from '@/utils/composables/app/useAppAnnouncementsListener';
import { providePrimeChat } from '@/utils/composables/useIntegrationChatRender';
import PrimeChat from '@/components/PrimeChat.vue';
import { useUserStore } from '@/stores/user';
import { initHotjar } from '@/utils/hotjar';
import { onUnmounted } from 'vue';
import {
	THEME_DARK_APP,
	THEME_ICONS_DARK_APP,
	THEME_PALETTE,
} from '@/constants/theme';
import AppSidebar from '@/components/AppSidebar.vue';
import AppHeader from '@/components/AppHeader.vue';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import { useAppStore } from '@/stores/app';
import { useDev } from '@/utils/composables/useDev';

const { setThemeColors } = providePrimeTheme();
const themeContainer = ref<HTMLElement | undefined>(undefined);

setThemeColors(THEME_DARK_APP, themeContainer.value, THEME_PALETTE);

const { setIconsColors } = providePrimeIcons();

setIconsColors(THEME_ICONS_DARK_APP);

providePrimeSurveys();
providePrimeChat();
useBlockScroll();
useTranslateDetection();

const { localizationCookie } = useAppCookies();
const route = useRoute();
const modalStorage = useModalStorage();
const authStore = useAuthStore();
const { isAuthorized, isTopNavVisible } = storeToRefs(authStore);
const { isMobileApp } = useMobileApp();
const { isMobile } = useScreenSize();
const { appKey } = useAppRerender();
const { isNetworkConnected, initCapacitor, deinitCapacitor } =
	useCapacitorPlugins();
const { isInitialDataLoading } = storeToRefs(useUserStore());
const appStore = useAppStore();
const { isDev } = useDev();

useAppInit();
useAppPusherEventListener();
useAppNotificationListener();
useAppAnnouncementsListener();

const isMobileNavShown = ref(false);
const localizationLoading = ref(false);

onMounted(async () => {
	await initCapacitor();
	let localization = localizationCookie;

	if (!localization) {
		localizationLoading.value = true;
		try {
			localization = await getInitialUserLocalization();
		} catch (e) {
			sendErrorInfo(e);
		} finally {
			localizationLoading.value = false;
		}
	}

	await handleUserLocale(localization);
});

watch([isAuthorized, route], (data) => {
	const [isAuthorized, route] = data;
	const isVisible = Boolean(isAuthorized && !route.meta.hideMobileNav);

	if (isMobileNavShown.value !== isVisible) {
		isMobileNavShown.value = isVisible;
	}
});

const isAppLoginPage = computed(
	() => route?.name === 'app-login' || route?.name === 'home'
);

onMounted(() => {
	// Close active modal if page reload or tab close
	if (!isMobileApp) {
		initHotjar();
		window.addEventListener('beforeunload', modalStorage.closeAllModals);
	}
});

onUnmounted(() => {
	deinitCapacitor();
	appStore.clearLog();
});

// Close all modals if route change
watch(
	() => route.name,
	(newRoute, prevRoute) => {
		if (newRoute !== prevRoute) {
			modalStorage.shiftModal();
		}
	}
);

const contentPadding = computed(() => {
	return route.name && route.meta.isPublic && route.meta.withoutHeader
		? 'env(safe-area-inset-top)'
		: 'calc(5rem + env(safe-area-inset-top))';
});

const sidebarPadding = computed(() => {
	return route.name && (route.meta.isPublic || route.meta.withoutHeader)
		? '0'
		: '16.875rem';
});

const isChatAvailable = computed(() => {
	if (import.meta.env.VITE_PC_SCOPE === 'dev') {
		return isDev.value;
	} else {
		return import.meta.env.VITE_PC_SCOPE === 'all';
	}
});
</script>

<template>
	<div ref="themeContainer">
		<ErrorBoundary>
			<div class="app-layout">
				<template v-if="isNetworkConnected">
					<PrimeLoaderSpinner
						v-if="localizationLoading || isInitialDataLoading"
					/>
					<template v-else>
						<AppHeader />
						<div
							:key="appKey"
							class="app-content"
							:class="{
								'header-visible': !isTopNavVisible,
								'app-login-content': isAppLoginPage,
							}"
						>
							<AppSidebar v-if="!isAppLoginPage && !isMobile" />
							<RouterView />
						</div>
						<AppMobileNav v-if="isMobileNavShown" />
						<NotificationsContainer />
						<!-- Modals -->
					</template>
					<ModalWindow />
					<!--TODO: Refactor to reinit resize observer as exposed method -->
					<PrimeChat v-if="isChatAvailable && isAuthorized" :key="appKey" />
				</template>
				<div v-else class="app-content">
					<NoConnection />
				</div>
			</div>
		</ErrorBoundary>
	</div>
</template>

<style scoped lang="scss">
@import '@/styles/mixins.scss';

.app-layout {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	min-height: 100svh;
	overflow: auto;
	position: relative;

	@include breakpoint(tablet) {
		min-height: 100vh;
	}

	& .app-content {
		width: 100%;
		display: flex;
		justify-content: center;
		flex: 1 0 auto;
		overflow: auto;
		padding-top: env(safe-area-inset-top);
		padding-left: 0;

		@include breakpoint(tablet) {
			padding-top: calc(5rem + env(safe-area-inset-top));
			padding-left: v-bind(sidebarPadding);
		}

		&.app-login-content {
			padding-top: 0;
		}

		&.background {
			@include breakpoint(mobile) {
				background: linear-gradient(
					180deg,
					#f3f6fc 0%,
					rgba(243, 246, 252, 0) 100%
				);
			}
		}

		&.header-visible {
			padding-top: env(safe-area-inset-top);

			@include breakpoint(mobile) {
				padding-top: v-bind(contentPadding);
			}
		}
	}

	:deep(.p-skeleton) {
		background-color: var(--grey-200);
	}
}
</style>
