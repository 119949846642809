<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { notify } from '@/components/common/notifications';
import API from '@/api';
import type { AxiosError } from 'axios';
import type { ErrorResponse } from '@/types';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import {
	SET_USERNAME,
	CHANGE_USERNAME,
	CHANGE_TIMEZONE,
	ACCOUNT_SETTINGS,
	CHANGE_EMAIL,
	CHANGE_PASSWORD,
	EMAIL_PREFERENCES,
	DELETE_ACCOUNT,
	LANGUAGE, DANGER_ZONE, SETTINGS_LABEL,
} from '@/locales/constants';
import LayoutSubpage from '@/components/LayoutSubpage.vue';
import SettingsItem from './components/SettingsItem.vue';
import {
	DELETE_ACCOUNT_MODAL,
	CHANGE_EMAIL_MODAL,
	CHANGE_PASSWORD_MODAL,
	CHANGE_USERNAME_MODAL,
	CHANGE_TIMEZONE_MODAL,
	CHANGE_LANGUAGE_MODAL,
} from '@/constants/modals';
import { sendErrorInfo } from '@/utils/errorCatching';
import { useComponentName } from '@/utils/composables/useComponentName';
import {
	PiHcAlternate_email,
	PiHcChevron_right,
	PiHcTime,
	PiHcMail,
	PiHcTranslate,
	PiHcLock,
	PiHcDelete,
	PiHcAccount,
} from '@primeinsightsgroupllc/prime-icons';
import {PrimeSkeleton, PrimeText} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {useModalStorage} from "@/utils/composables/useModalStorage";

const componentName = useComponentName();
const { username, userLocales, loading } = storeToRefs(useUserStore());
const route = useRoute();
const router = useRouter();
const { pushModal } = useModalStorage();

onMounted(async () => {
	if (route.name === 'accept-email-transfer' && route.query.url) {
		try {
			const { status, data } = await API.get(route.query.url.toString());
			if (status === 200) {
				notify({ body: data.message });
			}
		} catch (error) {
			const { message } = (error as AxiosError).response?.data as ErrorResponse;
			notify({ body: message });
			sendErrorInfo(error, componentName);
		} finally {
			await router.replace({ name: 'settings' });
		}
	}
});
</script>

<template>
	<main class="settings">
		<LayoutSubpage :title="$t(ACCOUNT_SETTINGS)">
			<PrimeText size="lg" weight="600" color="white">
				<div class="settings-label">
					{{$t(SETTINGS_LABEL)}}
				</div>
			</PrimeText>
			<template v-if="loading">
				<PrimeSkeleton
					v-for="i in 6"
					:key="i"
					height="3.5rem"
					class="settings-skeleton"
				/>
			</template>
			<div v-else class="settings__items">
				<SettingsItem
					:title="username ? $t(CHANGE_USERNAME) : $t(SET_USERNAME)"
					:modal="{ name: CHANGE_USERNAME_MODAL }"
					:start-icon="PiHcAccount"
					:end-icon="PiHcChevron_right"
				/>
				<SettingsItem
					:title="$t(CHANGE_TIMEZONE)"
					:modal="{ name: CHANGE_TIMEZONE_MODAL }"
					:start-icon="PiHcTime"
					:end-icon="PiHcChevron_right"
				/>
				<SettingsItem
					:title="$t(CHANGE_EMAIL)"
					:modal="{ name: CHANGE_EMAIL_MODAL }"
					:start-icon="PiHcMail"
					:end-icon="PiHcChevron_right"
				/>
				<SettingsItem
					:title="$t(EMAIL_PREFERENCES)"
					:start-icon="PiHcAlternate_email"
					:end-icon="PiHcChevron_right"
					:click-handler="() => router.push({ name: 'email-subscriptions' })"
				/>
				<SettingsItem
					v-if="userLocales?.length > 1"
					:title="$t(LANGUAGE)"
					:modal="{ name: CHANGE_LANGUAGE_MODAL }"
					:start-icon="PiHcTranslate"
					:end-icon="PiHcChevron_right"
				/>
				<SettingsItem
					:title="$t(CHANGE_PASSWORD)"
					:modal="{ name: CHANGE_PASSWORD_MODAL }"
					:start-icon="PiHcLock"
					:end-icon="PiHcChevron_right"
				/>
				<PrimeText size="lg" weight="600" color="white">
					<div class="settings-label">
						{{$t(DANGER_ZONE)}}
					</div>
				</PrimeText>
				<div class="settings-delete" @click="pushModal({ name: DELETE_ACCOUNT_MODAL })">
					<PiHcDelete color="inherit"></PiHcDelete>
					<PrimeText tag="p" size="sm" weight="500" class="item-title">
						{{ $t(DELETE_ACCOUNT) }}
					</PrimeText>
				</div>
<!--				<SettingsItem-->
<!--					:title="$t(DELETE_ACCOUNT)"-->
<!--					:modal="{ name: DELETE_ACCOUNT_MODAL }"-->
<!--					:start-icon="PiHcDelete"-->
<!--					start-icon-color="states-critical"-->
<!--					text-color="states-critical"-->
<!--				/>-->
			</div>
		</LayoutSubpage>
	</main>
</template>

<style lang="scss" scoped>
.settings {
	width: 100%;

	&-label {
		margin-top: 2.25rem;
		margin-bottom: 1rem;
	}

	&-delete {
		cursor: pointer;
		display: flex;
		padding: 0.625rem 0.5rem;
		margin-bottom: 0.875rem;
		border-radius: 0.5rem;
		align-items: center;
		transition: all 0.1s;
		gap: 0.5rem;
		color: var(--p-text-default);

		&:hover {
			background-color: var(--base-primary-03);
		}
	}
}

.settings-skeleton {
	margin-bottom: 0.875rem;
}
</style>
