<script lang="ts" setup>
import {
	SORRY_MODAL_HEADER,
	SORRY_MODAL_TITLE,
	SORRY_MODAL_DESCRIPTION,
	SORRY_MODAL_ACTION,
} from '@/locales/constants';
import ModalCommonContent from '@/components/common/ModalCommonContent.vue';
import { PrimeText, PrimeButton } from '@primeinsightsgroupllc-ui/prime-ui-kit';

const emits = defineEmits<{
	close: [];
}>();

const handleClick = () => {
	window.location = import.meta.env.VITE_APP_STORE_DOWNLOAD_URL;
	emits('close');
};
</script>

<template>
	<div class="ios-app-modal">
		<ModalCommonContent>
			<template #header>{{ $t(SORRY_MODAL_HEADER) }}</template>
			<template #title>
				<PrimeText tag="div" weight="500">
					{{ $t(SORRY_MODAL_TITLE) }}
				</PrimeText>
			</template>
			<template #description>
				<PrimeText tag="div" size="sm" v-html="$t(SORRY_MODAL_DESCRIPTION)" />
			</template>
			<template #actions>
				<PrimeButton
					type="gradient"
					full-width
					:label="$t(SORRY_MODAL_ACTION)"
					@click="handleClick"
				/>
			</template>
		</ModalCommonContent>
	</div>
</template>
