<script setup lang="ts">
import { COINS, SAVE_DISCOUNT } from '@/locales/constants';
import { localizePoints, transformCurrency } from '@/utils/helpers';
import { PrimeText } from '@primeinsightsgroupllc-ui/prime-ui-kit';
import type { RewardOption } from '@/types';
import { computed } from 'vue';

const { accentColor = 'base-primary' } = defineProps<{
	option: RewardOption;
	selected: boolean;
	accentColor?: 'base-primary' | 'text-default';
}>();

defineEmits<{
	click: [];
}>();

const optionColor = computed(() => `var(--${accentColor})`);
</script>

<template>
	<div class="reward-option" :class="{ selected }" @click="$emit('click')">
		<div v-if="option.discount_percent" class="discount-tag">
			<PrimeText size="xs" weight="500" color="inherit">
				{{
					$t(SAVE_DISCOUNT, {
						percentage: `${option.discount_percent}%`,
					})
				}}
			</PrimeText>
		</div>
		<PrimeText size="lg" weight="500" class="option-money">
			{{ transformCurrency(option.money_value, option.currency) }}
		</PrimeText>
		<template v-if="option.discount_percent">
			<PrimeText
				weight="500"
				color="grey-500"
				size="sm"
				class="option-coins without-discount"
			>
				{{ `${localizePoints(option.coin_value)} ${$t(COINS)}` }}
			</PrimeText>
			<PrimeText weight="500" color="grey-700" size="sm" class="option-coins">
				{{
					`${localizePoints(option?.coin_value_discounted || 0)} ${$t(COINS)}`
				}}
			</PrimeText>
		</template>
		<PrimeText
			v-else
			weight="500"
			color="grey-700"
			size="sm"
			class="option-coins"
		>
			{{ `${localizePoints(option.coin_value)} ${$t(COINS)}` }}
		</PrimeText>
	</div>
</template>

<style lang="scss" scoped>
.reward-option {
	--option-color: v-bind(optionColor);

	padding: 1.5rem;
	background-color: var(--white);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--p-grey-500);
	box-shadow: var(--card-shadow);
	border-radius: 0.5rem;
	cursor: pointer;
	transition: border-color 0.3s;
	position: relative;

	.discount-tag {
		position: absolute;
		top: 0.3125rem;
		left: 0.3125rem;
		padding: 0 0.25rem;
		background-color: var(--base-secondary-04);
		border-radius: 0.3125rem;
		color: var(--base-secondary);
	}

	.option-money {
		margin: 0.125rem 0;
		line-height: 1;
	}

	.option-coins {
		line-height: 1.2;
		text-align: center;
		letter-spacing: -0.25px;

		&.without-discount {
			text-decoration: line-through;
		}
	}

	&:hover {
		border: 1px solid var(--option-color);
	}

	&.selected {
		padding: 1.4375rem;
		color: var(--option-color);
		border: 2px solid var(--option-color);

		.discount-tag {
			top: 0.25rem;
			left: 0.25rem;
		}

		& .option-coins:not(.without-discount) {
			color: var(--option-color);
		}

		& .option-money {
			color: var(--option-color);
		}
	}
}
</style>
