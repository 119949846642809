import { useAuthStore } from '@/stores/auth';
import { AUTH_ROUTE_NAME, USER_ROUTE_NAME } from '@/constants/routes';
import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router';
import { cidStorage } from '@/utils/local-storage';
import { useRewardsStore } from '@/stores/rewards';

export const checkUserEmailHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const forgotPasswordHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.resetPasswordSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSignUpCompleteHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.authUserEmail && !authStore.registrationEmailSent) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkExistingSocialsHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.socials.length) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkSocialAuthHook = (
	_to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const authStore = useAuthStore();
	if (!authStore.userSocialAuthData) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkResetPasswordHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	if (!to.query.email && !to.query.token) {
		next({ name: AUTH_ROUTE_NAME.APP_LOGIN });
	} else {
		next();
	}
};

export const checkContextIdHook = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext
) => {
	const cid = to.query.cid;
	if (cid) {
		cidStorage.value = cid.toString();
		delete to.query.cid;
		delete to.query.tx_id;
		next({ path: to.path, query: to.query });
	} else {
		next();
	}
};

export const checkInitializedClaim = () => {
	const rewardsStore = useRewardsStore();
	if (!rewardsStore.isClaimInitialized) {
		return { name: USER_ROUTE_NAME.SURVEYS };
	}
};
