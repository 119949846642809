import { useUserStore } from '@/stores/user';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { getDrawPrizes } from '@/api';
import { storeToRefs } from 'pinia';
import { StreakPrizes } from '@/enums';
import type { Prize } from '@/types';
import { useModalStorage } from '@/utils/composables/useModalStorage';
import { PRIZE_BONUS_MODAL } from '@/constants/modals';

const AMOUNT_OF_BOXES = 24;

const initialBox: Prize = {
	id: undefined,
	coin_value: 0,
	selected: false,
	percentage: 0,
	is_code: StreakPrizes.COINS,
};

let initialBoxes: Prize[] = [];

const initializeBoxes = (amount: number) => {
	initialBoxes = [];
	for (let i = 0; i < amount; i++) {
		initialBoxes.push({
			...initialBox,
			id: i,
		});
	}
};

export const usePrizeDrawView = (): {
	showWinMessage: Ref<boolean>;
	boxes: Ref<Prize[]>;
	prize: Ref<Prize>;
	hiddenBoxes: Ref<boolean>;
	selectedBoxPosition: Ref<number | undefined>;
	disabledBoxes: Ref<boolean>;
	openBox: (position: number | undefined) => Promise<void>;
	isLoading: Ref<boolean>;
	handleSelectBox: (index: number) => void;
	boxOpened: Ref<boolean>;
} => {
	initializeBoxes(AMOUNT_OF_BOXES);

	const modalStore = useModalStorage();
	const userStore = useUserStore();
	const { prizes } = storeToRefs(useUserStore());

	const prize = ref<Prize>(initialBox);
	const showWinMessage = ref<boolean>(false);
	const selectedBoxPosition = ref<number | undefined>(undefined);
	const boxes = ref<Prize[]>(initialBoxes);
	const disabledBoxes = ref<boolean>(false);
	const hiddenBoxes = ref<boolean>(false);
	const isLoading = ref<boolean>(false);
	const boxOpened = ref<boolean>(false);

	const handleSelectBox = (index: number) => {
		if (boxOpened.value) return;
		selectedBoxPosition.value = prizes.value ? index : undefined;
	};

	const openBox = async () => {
		if (selectedBoxPosition.value === undefined) return;
		try {
			boxOpened.value = true;
			isLoading.value = true;
			hiddenBoxes.value = true;

			const data = await getDrawPrizes(selectedBoxPosition.value);

			await userStore.fetchUserData();
			await new Promise((resolve) => setTimeout(resolve, 1000));

			disabledBoxes.value = true;
			prize.value = data.prizes.find((item: Prize) => item?.selected);
			showWinMessage.value = true;
			boxes.value = [...data.prizes];

			if (prize.value.is_code === StreakPrizes.BONUS) {
				await modalStore.pushModal({
					name: PRIZE_BONUS_MODAL,
				});
			}
		} finally {
			isLoading.value = false;
			hiddenBoxes.value = false;
		}
	};

	return {
		showWinMessage,
		boxes,
		hiddenBoxes,
		selectedBoxPosition,
		disabledBoxes,
		openBox,
		isLoading,
		prize,
		handleSelectBox,
		boxOpened,
	};
};
