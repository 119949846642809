<script setup lang="ts">
import PageCard from '@/components/PageCard.vue';
import PrimeNavigation from '@/views/profile/components/PrimeNavigation.vue';
import ProfileHeader from '@/views/profile/components/ProfileHeader.vue';
import { useProfileView } from '@/views/profile/composables';
import {
	PrimeButton,
	PrimeLoaderSpinner,
	PrimeText,
} from '@primeinsightsgroupllc-ui/prime-ui-kit';
import {
	PiHcChevron_right,
	PiHcHelp,
	PiHcSettings,
	PiHcLogout,
} from '@primeinsightsgroupllc/prime-icons';
import { useRouter } from 'vue-router';
import {
	ACCOUNT,
	HELP,
	INVITE_FRIEND,
	REF_PAGE_TITLE,
	SETTINGS_LABEL,
	SETTINGS,
} from '@/locales/constants';
import SettingsItem from '@/views/settings/components/SettingsItem.vue';
import e_wallet_logo from '../../assets/e-wallet.svg';
import { useScreenSize } from '@/utils/composables/useScreenSize';
import AppMeta from '@/components/AppMeta.vue';
import { useDev } from '@/utils/composables/useDev';

const { profileConfig, isProfileStatsLoaded, isLoading } = useProfileView();
const { isMobile } = useScreenSize();
const router = useRouter();
const { isDev } = useDev();
</script>

<template>
	<main class="profile" :class="{ 'page-flex': isLoading }">
		<PageCard>
			<PrimeLoaderSpinner v-if="isLoading" />
			<template v-else>
				<div class="profile-header">
					<PrimeText tag="h5" color="inherit" weight="500" size="2xl">
						{{ $t(ACCOUNT) }}
					</PrimeText>
					<span class="profile-header-btns">
						<PrimeButton
							v-if="!isMobile"
							rounded="rounded"
							variant="primary"
							type="outline"
							size="small"
							:label="$t(SETTINGS_LABEL)"
							@click="router.push({ name: 'settings' })"
						>
							<template #icon><PiHcSettings /></template>
						</PrimeButton>
						<PiHcLogout color="white" @click="router.replace('logout')" />
					</span>
				</div>
				<ProfileHeader v-if="isMobile">
					<template #action>
						<PrimeButton
							rounded="rounded"
							variant="primary"
							type="outline"
							size="small"
							transform="capitalize"
							:label="$t(SETTINGS)"
							full-width
							@click="router.push({ name: 'settings' })"
						>
							<template #iconLeft><PiHcSettings /></template>
						</PrimeButton>
					</template>
				</ProfileHeader>

				<AppMeta v-if="isDev" />

				<section v-if="isProfileStatsLoaded" class="profile-cards-wrapper">
					<PrimeNavigation
						v-for="item in profileConfig"
						:key="item.title"
						:icon="item.icon"
						:gradient="item.gradient"
						:title="item.title"
						:details="item.details"
						v-bind="
							item.route
								? { route: item.route }
								: item.handler
									? { handler: item.handler }
									: {}
						"
					/>
				</section>
				<div class="profile-refer" @click="router.push({ name: 'referrals' })">
					<img :src="e_wallet_logo" alt="" />
					<div>
						<PrimeText tag="h5" color="inherit" weight="500" size="lg">
							{{ $t(REF_PAGE_TITLE) }}
						</PrimeText>
						<PrimeText tag="p" weight="500" size="xs">
							{{ $t(INVITE_FRIEND) }}
						</PrimeText>
					</div>
				</div>
				<SettingsItem
					:title="$t(HELP)"
					:start-icon="PiHcHelp"
					:end-icon="PiHcChevron_right"
					:click-handler="() => router.push({ name: 'support' })"
				/>
			</template>
		</PageCard>
	</main>
</template>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

.profile {
	padding-bottom: calc(env(safe-area-inset-bottom) + 5rem);
	overflow-y: auto;

	&.page-flex .page-card {
		display: flex;
	}

	:deep(.p-loader-spinner) {
		height: 100%;
	}

	&-cards-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		margin-bottom: 1rem;

		@include breakpoint(tablet) {
			gap: 0.875rem;
			margin-bottom: 3.5rem;
		}
	}

	&-refer {
		padding: 0.75rem 0.5rem;
		border: 1px solid var(--p-grey-300);
		cursor: pointer;
		border-radius: 0.5rem;
		gap: 0.75rem;
		display: flex;
		align-items: center;
		color: white;
		margin-bottom: 0.5rem;

		@include breakpoint(tablet) {
			margin-bottom: 1.125rem;
		}
	}

	&-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: calc(100% - 0.5rem);
		margin-bottom: 1rem;
		color: white;

		@include breakpoint(tablet) {
			width: calc(100% - 3.5rem);
			margin-bottom: 1.5rem;
		}

		&-btns {
			height: 34px;
			display: flex;
			align-items: center;
			cursor: pointer;
			gap: 1.5rem;
		}
	}
}

.profile--statistics {
	display: flex;
	gap: 0.875rem;
	flex-wrap: wrap;
}
</style>
