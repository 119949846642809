<script setup lang="ts" generic="Item">
import { type Ref, ref, watch, onMounted } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import { LOADING_MORE, NO_MORE_ENTRIES } from '@/locales/constants';
import { getPaginatedData } from '@/api';

const { apiUrl, pageOffset = 10 } = defineProps<{
	apiUrl: string;
	showListEnd?: boolean;
	pageOffset?: number;
}>();

const items = ref<Item[]>([]) as Ref<Item[]>;
const isNextPageAvailable = ref(true);
const loading = ref(false);
const page = ref(1);
const offset = ref(pageOffset);

const fetchTransactions = async (nextPage: number | 1) => {
	if (loading.value) return;

	try {
		loading.value = true;
		if (page.value !== nextPage) {
			page.value = nextPage;
		}
		const data = await getPaginatedData<Item>(apiUrl, offset.value, page.value);
		items.value = [...(items.value || []), ...data.data];
		isNextPageAvailable.value = !!data.links.next;
	} finally {
		loading.value = false;
	}
};

onMounted(async () => {
	await fetchTransactions(page.value);
});

const target = ref(null);
const isVisible = ref(false);

const { pause, resume } = useIntersectionObserver(
	target,
	async ([{ isIntersecting }]) => {
		isVisible.value = isIntersecting;
		if (isIntersecting && isNextPageAvailable.value) {
			await fetchTransactions(page.value + 1);
		}
	}
);

const triggerIntersectionObserver = () => {
	pause();
	resume();
};
watch(() => items.value.length, triggerIntersectionObserver);
</script>

<template>
	<div class="infinite-list">
		<template
			v-if="$slots['skeleton-list-item'] && loading && items.length === 0"
		>
			<div v-for="i in offset" :key="i">
				<slot name="skeleton-list-item" />
			</div>
		</template>
		<template
			v-if="$slots['skeleton-component'] && loading && items.length === 0"
		>
			<slot name="skeleton-component" />
		</template>
		<template v-if="items.length">
			<template v-for="(item, index) in items" :key="index">
				<slot name="list-item" :item="item" :index="index" />
			</template>
		</template>
		<template v-if="!loading && !items.length">
			<slot name="empty-list" />
		</template>
		<div ref="target" class="list-indicator">
			<div v-if="showListEnd && !isNextPageAvailable">
				{{ $t(NO_MORE_ENTRIES) }}
			</div>
			<div v-else-if="loading">
				{{ $t(LOADING_MORE) }}
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.infinite-list {
	width: 100%;
	.list-indicator {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0.75rem;
		font-size: 0.75rem;
		font-weight: 500;
		line-height: 1.4;
	}
}
</style>
